'use strict';

var _ = require('lodash');
var ajax = require('../../ajax');
var findMoreStores = require('../../findMoreStores');
var tooltip = require('../../tooltip');
var util = require('../../util');
var TPromise = require('promise');
var AddressAutocompleteDialog;
var AddressVerificationDialog;
var AddressVerificationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/AddressVerification/AddressVerificationHelper');
var CheckoutConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/CheckoutConstants');
var GoogleTagManagerClient = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var ValidationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var ValidationSettings = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;

var shippingMethods;
var isStoreShippingAddressOnly;
var options = {};
var dataBuffer = {};

var billingAddressFormContainerSelector;
var $billingAddressFormContainer;
var billingAddressFormSelector;
var $billingAddressForm;
var shippingAddressSectionSelector;
var $shippingAddressSection;
var shippingAddressFormContainerSelector;
var $shippingAddressFormContainer;
var shippingAddressFormSelector;
var $shippingAddressForm;
var paymentFormContainerSelector;
var $paymentFormContainer;
var paymentFormSelector;
var $paymentForm;
var $sectionCheckout;
var $shippingMethodList;
var shippingAddressFormValidator;
var billingAddressFormValidator;

function initializeSelectors() {
	billingAddressFormContainerSelector = '.billing-address-form';
	$billingAddressFormContainer = $(billingAddressFormContainerSelector);
	billingAddressFormSelector = '#billingAddressForm';
	$billingAddressForm = $(billingAddressFormSelector);
	shippingAddressSectionSelector = '.form-checkout.shipping-address-section';
	$shippingAddressSection = $(shippingAddressSectionSelector);
	shippingAddressFormContainerSelector = '.shipping-address-form';
	$shippingAddressFormContainer = $(shippingAddressFormContainerSelector);
	shippingAddressFormSelector = '#shippingAddressForm';
	$shippingAddressForm = $(shippingAddressFormSelector);
	paymentFormContainerSelector = '.payment-section';
	$paymentFormContainer = $(paymentFormContainerSelector);
	paymentFormSelector = '#paymentSection';
	$paymentForm = $(paymentFormSelector);
	$sectionCheckout = $('.section-checkout');
	$shippingMethodList = $('.form-checkout.payment-section #shipping-method-list');
}

function initializeEvents() {
	$(document).off('OpcAddressEnhanced.InitializeSelectors').on('OpcAddressEnhanced.InitializeSelectors', function() {
        initializeSelectors();
    });

	$(document).on('click', '.section-checkout .find-more-stores a', function () {
		findMoreStores.initialize({ view: 'checkout', storeQuery: StoreQueryFilters.ShipToStoreEnabled });
	});
	
	$(document).on('click', '.international-checkout', function() {
		location.reload();
	});
	
	$(document).on('change', 'form [id*="_country"]', function () {
		var $this = $(this);
		var $form = $this.closest('form');
		var $stateDropdown = $form.find('[id*="states_state"]');

		renderOptions('#' + $stateDropdown.attr('id'), '#' + $this.attr('id'));

		var $initialState = $form.find('.initial-state');
		var initialStateValue = $initialState.val();
		var $selectedStateOption = $stateDropdown.find('option[value="' + initialStateValue + '"]');
		if ($selectedStateOption.length > 0) {
			$stateDropdown.val(initialStateValue);
			$selectedStateOption.prop('selected', true);
		}

		if ($form.attr('id') == $shippingAddressForm.attr('id') && $this.val()) {
			if ($this.val().toUpperCase() == SiteConstants.CountryCodes.UnitedStates) {
				$('#shipping-international-info').hide();
			} else {
				$('#shipping-international-info').show();
			}
		}
	});

	$(document).on('change', 'form [id*="_postal"]', function () {
		var $this = $(this);
		$this.val($this.val().toUpperCase());
	});

	$(document).on('click', '.shipping-same', function(){
		if($('.shipping-same').is(':checked')){
			$('input[name$="_useAsShippingAddress"]').prop('checked', true);
		} else {
			$('input[name$="_useAsShippingAddress"]').prop('checked', false);
		}
	});

	$(document).on('click', '.add-diff', function(){
		$('input[name$="_useAsShippingAddress"]').prop('checked', false);
	});

	$(document).on('click', billingAddressFormContainerSelector + ' .edit-address-det', function (e) {
		e.preventDefault();
		editBillingAddress();
	});

	$(document).on('click', billingAddressFormContainerSelector + ' .cancel-address-det', function (e) {
		e.preventDefault();

		$('.bill-address-fields').addClass('collapsed').hide();
		$('.billing-select-address').show();

		$('.billing-address-preview').show();
		$('.billing-address-data.form-info').show();

		$billingAddressForm.hide();
		$shippingAddressFormContainer.show();

		$shippingAddressSection.addClass('active').find('.shipping-address-cont').removeClass('hidden');

		if ($shippingAddressSection.attr('panel-mode') != 'view') {
			$shippingAddressForm.show();
		} else {
			$shippingAddressForm.hide();
			$('.form-checkout.payment-section').addClass('active').find('.shipping-payment-cont').removeClass('hidden');
		}
		
		if ($('.shipping-methods-section .shipping-restrictions').length == 0) {
			$('.payment-methods-section').show();
		}
	});

	$(document).on('click', '.shipping-address-section .edit-address-det', function (e) {
		e.preventDefault();
		editShippingAddress();
	});

	$(document).on('click', '.shipping-address-section .add-address-det', function (e) {
		e.preventDefault();

		writeShippingAddressFormToBuffer();

		$('.form-checkout.shipping-address-section').attr('panel-mode', 'add');
		$shippingAddressFormContainer.data('hidden-editable', true).hide();
		$('.shipping-address-data').hide();
		$shippingAddressFormContainer.show();

		$shippingAddressForm.removeClass('add edit').addClass('add');
		$shippingAddressForm.find('input[name="action"]').val('add');

		$billingAddressForm.hide();
		$('.billing-address-data.form-info').css('display', 'block');
		$('.form-checkout.payment-section').removeClass('active').find('.shipping-payment-cont').addClass('hidden');

		var $shippingAddressFormFields = $shippingAddressForm.find('.editaddress-shipping');
		$('.editaddress-shipping .initial-state').val('');

		$('.shipping-select-address').hide();
		$('.editaddress-shipping').removeClass('collapsed');
		$('#dwfrm_singleshipping_addressList').val('');

		$shippingAddressFormFields.find('input:text, input:password, input:file, textarea').val('');
		$shippingAddressFormFields.find('option[default]').prop('selected', true);
		$shippingAddressFormFields.find('input:checkbox, input:radio').removeAttr('checked');

		$('#dwfrm_shippingaddress_country').trigger('change');
	});

	$(document).on('click', '.shipping-address-section .cancel-address-det', function (e) {
		e.preventDefault();

		writeBufferToShippingAddressForm();
   		submitShipping(true);
	});

    $(document).on('click', '.single.shipping-address-type-selector input', function () {
		var shippingAddressType = $(this).val();
        shippingAddressFormValidator.form();
		$('.form-checkout.shipping-address-section').attr('panel-mode', 'blank');
		$('.shipping-address-cont span.error').remove();
		$('.shipping-address-cont input, .shipping-address-cont select').removeClass('error');
		
        if (shippingAddressType == SiteConstants.AddressTypes.Customer) {
        	$shippingAddressForm.find('.formfield input[type="text"], .formfield select').not('[disabled]').not('#dwfrm_shippingaddress_myStoreID').val('');
        	$('.form-checkout.shipping-address-section').attr('shipping-address-type', 'my-address').attr('value', 'false');
        	
        	if ($('#dwfrm_multipleshipping_addressList option').length > 0 || dataBuffer.shippingAddressFields && dataBuffer.shippingAddressFields['dwfrm_shippingaddress_firstName']) {
				writeBufferToShippingAddressForm();
        		
				if ($shippingAddressForm.valid()) {
					$('.shipping-address-data.form-info').html('').append(buildDetailsBlockForForm($shippingAddressForm));
					$('.shipping-address-data').show();
				
					$shippingAddressForm.hide();
					$('.payment-section').addClass('active');
					$('.shipping-payment-cont').removeClass('hidden');
					$('.shipping-address-cont').removeClass('hidden'); 
					$shippingAddressForm.data('shipping-filled', true);
				}
				
				if ($('#dwfrm_multipleshipping_addressList option').length > 0) {
					$('#dwfrm_multipleshipping_addressList').trigger('change');
				}
			} else {
				$shippingAddressForm.find('.formfield option[default]').prop('selected', true);
				$shippingAddressForm.data('shipping-filled', false);
				$('.form-checkout.address-section').attr('panel-mode', 'view');

				$shippingAddressFormContainer.data('hidden-editable', true).hide();
				$('.shipping-address-data.form-info').hide();
				$shippingAddressFormContainer.show();
				$shippingAddressForm.removeClass('add edit').addClass('edit');
				$shippingAddressForm.find('input[name="action"]').val('edit');

				$('.editaddress-shipping').removeClass('collapsed');
				setShipToStore(false);
			}

			setShipToStore(isShipToStoreEnabled());
			$('.shipping-address-cont').removeClass('hidden');

			if (shippingAddressFormValidator.checkForm()) {
				setOPCAddress(shippingAddressFormSelector, 'dwfrm_shippingaddress_saveopcaddress').then(function (data) {
    				handleShippingRestrictions(data);	
					$('.payment-section').addClass('active');
					$('.shipping-payment-cont').removeClass('hidden');

					updateShippingMethodList($shippingAddressForm, true, null);
				});
			} else {
				updateShippingMethodList($shippingAddressForm, true, null);
				$('.payment-section').removeClass('active');
				$('.shipping-payment-cont').addClass('hidden');
			}

			$('.billing-address-data.form-info').css('display', 'block');

            if ($('.multiple.select-address option').length > 0) {
            	$('.multiple.select-address').show();
            } else {
            	$('.multiple.select-address').hide();
            }

        	$('.single.select-address').hide();
        } else if (shippingAddressType == SiteConstants.AddressTypes.Store) {
			$shippingAddressForm.hide();
            $('.editaddress-shipping').addClass('collapsed');
            $('.multiple.select-address').hide();

            if (SitePreferences.ENABLE_SHIP_TO_STORE_SELECTION) {
                $('.single.select-address').show();
			}
			$('#mystores_addressList').trigger('change');
        }
    });

    $(document).on('change', billingAddressFormSelector + ' select[name$="_addressList"]', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');

        if (!selectedAddress) { return; }

        util.fillAddressFields(selectedAddress, $billingAddressForm);
        billingAddressFormValidator.form();
    });

    $(document).on('change', shippingAddressFormSelector + ' select[name$="_addressList"]', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');

        if (!selectedAddress) { return; }

        util.fillAddressFields(selectedAddress, $shippingAddressForm);
        shippingAddressFormValidator.form();
    });

    $(document).on('click', '#submitBilling', function(e){
    	e.preventDefault();
    	submitBilling();
    });

    $(document).on('click', '#submitShipping', function(e) {
    	e.preventDefault();
    	submitShipping();
    });

	$(document).on('click', '.toggle.top, .toggle.bottom', function(e){
    	e.preventDefault();
    	$('#internationalinfo').slideToggle();
	});

	if (options && options.Lob && options.Lob.AddressAutocomplete.Enabled) {
		$(document).off('keyup.AddressAutocomplete').on('keyup.AddressAutocomplete', billingAddressFormSelector + ' input[type="text"], ' + shippingAddressFormSelector + ' input[type="text"]', function (event) {
			var $this = $(this);
			var $form = $this.closest('form');
			var formValidator = $form.validate();

			if (event.keyCode == 8 || event.keyCode == 46) {
				$this.parent().find('.address-autocomplete-dialog').remove();
			} else if (!formValidator.checkForm() && ($this.attr('id').indexOf('_address1') >= 0 || $this.attr('id').indexOf('_address2') >= 0)) {
				var addressAutocompleteHandler = function (addressAutocompleteData) {
					AddressAutocompleteDialog.initialize(options, addressAutocompleteData);
				}
			
				AddressVerificationHelper.AutocompleteAddress($form, $this, options).then(addressAutocompleteHandler);
			} else {
				$this.parent().find('.address-autocomplete-dialog').remove();
			}
		});
		
		$(document).off('SetAddressFromAddressAutocomplete').on('SetAddressFromAddressAutocomplete', function (event, data) {
			AddressVerificationHelper.SetAddressFromAddressVerificationDialog(data.$form, data.Address);
		});
	}

	if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
		$(document).off('SetAddressFromAddressVerification').on('SetAddressFromAddressVerification', function (event, addressData) {
			if (addressData.AddressType == 'billing') {
				AddressVerificationHelper.SetAddressFromAddressVerificationDialog($billingAddressForm, addressData);
				submitBilling(true);
			} else if (addressData.AddressType == 'shipping') {
				AddressVerificationHelper.SetAddressFromAddressVerificationDialog($shippingAddressForm, addressData);
				submitShipping(false, true);
			}
		});
	}

	$(document).on('opc.SetShippingAddress', function (e, address) {
		var templateSettings = {
				interpolate: /{{([\s\S]+?)}}/g
	    	};

		var template = _.template($('#template-address-selector-option').html(), templateSettings);

		address.ID = address.key;
		if (address.isMyStore && !address.myStoreID && address.storeId) {
			address.myStoreID = address.storeId;
		}

		var templateData = _.extend(address, {
			id: address.UUID,
			cssClass: 'find-more-store-option',
			selected: 'selected',
			text: address.labelText
		});

		var $addressList = $('#mystores_addressList');

		var addressIndexInList = null;
		$addressList.find('option').each(function (index, object) {
			if (address.ID == $(object).val()) {
				addressIndexInList = index;
				return false;
			}
		});

	    if (addressIndexInList) {
			$addressList.prop('selectedIndex', addressIndexInList);
		} else {
			dataBuffer['mystores_addressList'] = dataBuffer['mystores_addressList'].filter(function (address) {
				return !address.isTemporary;
			});

			address.isTemporary = true;
			dataBuffer['mystores_addressList'].push(address);
			dataBuffer['store_name_lookup'][address.firstName] = address.fullName;
			
			$addressList.find('.find-more-store-option').remove();
			$addressList.prepend(template(templateData)).prop('selectedIndex', 0);
		}

	    $('#mystores_addressList').trigger('change');
	});

	$(document).on('change', '#dwfrm_multipleshipping_addressList, #mystores_addressList', function (e) {
		var $this = $(this);
		var actionName = 'dwfrm_shippingaddress_saveopcaddress';
		$('.shipping-address-cont span.error').remove();
		$('.shipping-address-cont input, .shipping-address-cont select').removeClass('error');

		var $target = $(e.currentTarget);
        var selectedAddress;
		if ($target && $target.attr('id')) {
			var dataBufferForAddressList = dataBuffer[$target.attr('id')];
			if (dataBufferForAddressList) {
				selectedAddress = dataBufferForAddressList.filter(function (address) {
					return address.UUID == $target.val();
				})[0];
			}
		}

        if (!selectedAddress) {
        	return false;
        }

		var isStoreAddress = false;
		var storeId;
        if (selectedAddress.hasOwnProperty('isMyStore') && selectedAddress.myStoreID) {
			isStoreAddress = selectedAddress.isMyStore;
			storeId = selectedAddress.myStoreID;
        }

		setShipToStore(isStoreAddress);

		if (isStoreAddress) {
			$shippingAddressForm.find('.formfield.myStoreID input').val(storeId);
		} else {
			$shippingAddressForm.find('.formfield input[type="text"], .formfield select').not('[disabled]').not('#dwfrm_shippingaddress_myStoreID').val('');
			util.fillAddressFields(selectedAddress, $shippingAddressForm);
		}
		$this.find('option.placeholder').remove();

		setOPCAddress(shippingAddressFormSelector, actionName).then(function (data) {
			handleShippingRestrictions(data);
			$shippingAddressForm.data('shipping-filled', true);
			$shippingAddressForm.hide();
			$('.shipping-address-data.form-info').html('').append(buildDetailsBlockForAddress(selectedAddress, false, false, 'shippingAddressForm'));
			$('.shipping-address-data').show();

			if (billingAddressFormValidator.valid()) {
				$('.shipping-payment-cont').removeClass('hidden');
				$('.payment-section').addClass('active');
			} else {
				$('.shipping-payment-cont').addClass('hidden');
				$('.payment-section').removeClass('active');
				$('.billing-address-data.form-info').hide();

				$billingAddressFormContainer.show();
				$billingAddressFormContainer.find('input.required.field.error').eq(0).focus();
			}
			
			updateShippingMethodList($shippingAddressForm, true, isStoreAddress);
		}.bind(this));
	});

	$(document).on('change', '#dwfrm_billing_addressList', function() {
    	$('.custom-email-error').remove();
    	var isEmailValid = util.validateEmail($('#dwfrm_billingaddress_email').val());

		if ($(this).val() && billingAddressFormValidator.valid() && isEmailValid) {
			$billingAddressForm.hide();
			$('.billing-address-data').html('').append(buildDetailsBlockForForm($billingAddressForm)).show();
			$('.shipping-address-cont').removeClass('hidden');
			$('.shipping-address-section').addClass('active');
			$('.form-checkout.address-section').attr('panel-mode', 'view').attr('value', 'true');
		} else {
			editBillingAddress();
			$('.shipping-address-cont').addClass('hidden');
			$('.shipping-address-section').removeClass('active');
		}
	});

	$(document).off('click.ShipToStore.Change').on('click.ShipToStore.Change', '[shipping-method] .store-details .change', function (e) {
		var $shippingMethod = $(this).closest('[shipping-method]');
		$shippingMethod.find('.store-details').hide();
		$shippingMethod.find('.store-selector').show();
	});

	$(document).off('change.ShipToStore.Apply').on('change.ShipToStore.Apply', '[shipping-method] .store-selector select', function (e) {
		var $shippingMethod = $(this).closest('[shipping-method]');
		var $selectedStore = $shippingMethod.find('.store-selector option:checked');
		var storeId = $selectedStore.val();
		
		$shippingAddressForm.find('.formfield.myStoreID input').val(storeId);

		setOPCAddress(shippingAddressFormSelector, 'dwfrm_shippingaddress_saveopcaddress').then(function (data) { 
			updateShippingMethodList($shippingAddressForm, true);
		});

		$shippingMethod.find('.store-details').show();
		$shippingMethod.find('.store-selector').hide();
	});

	$(document).off('click.ShipToStore.Cancel').on('click.ShipToStore.Cancel', '[shipping-method] .store-selector .cancel', function (e) {
		var $shippingMethod = $(this).closest('[shipping-method]');
		$shippingMethod.find('.store-details').show();
		$shippingMethod.find('.store-selector').hide();
	});

	$(document).off('click.ShippingMethod.Select').on('click.ShippingMethod.Select', 'input[name*="_shippingMethodID"]', function (event) {
		var $this = $(this);
		var $shippingMethod = $this.closest('[shipping-method]');
		var $shipment = $this.closest('form');

		if ($shippingMethod.attr('status') == 'disabled') {
			event.preventDefault();
		} else {
			selectShippingMethod($shipment.attr('shipment'), $this.val(), $shippingMethod.attr('is-ship-to-store') == 'true', $shippingMethod); 
		}
	});

	$(document).off('focus.AutofillHandler').on('focus.AutofillHandler', 'div.billing-address-form input[type="text"], div.shipping-address-form input[type="text"]', function (event) {
		var $this = $(this);
		var $form = $this.closest('form');
		var autofillCount = $form.find('input:-webkit-autofill').length;

		if (window.innerWidth <= 768) {
			if (autofillCount == 0) {
				$form.attr('auto-filled', false);
			}

			if ($form.attr('auto-filled') != 'true') {
				dataBuffer.AutofillDetectorInterval = setInterval(function() {
					autofillCount = $form.find('input:-webkit-autofill').length;
					if (autofillCount > 0 && $form.attr('auto-filled') != 'true') {
						$form.attr('auto-filled', true);
						$this.attr('inputmode', 'none');
						$this.trigger('blur');
						setTimeout(function() {
							$this.removeAttr('inputmode');
						}, 250);
					}
				}, 250);
			}
		}
	});
}

$(document).off('blur.AutofillHandler').on('blur.AutofillHandler', 'div.billing-address-form input[type="text"], div.shipping-address-form input[type="text"]', function (event) {
	if (dataBuffer && dataBuffer.AutofillDetectorInterval) {
		clearInterval(dataBuffer.AutofillDetectorInterval);
	}
});

function initializeUserInterface() {
	$('#dwfrm_shippingaddress_ID').val('');
	$('.form-checkout.address-section').attr('panel-mode', 'blank');
	$('.form-checkout.shipping-address-section').attr('panel-mode', 'blank').attr('value', 'false');

	var billingAddress = getAddressFromForm($billingAddressForm);
	var shippingAddress = getAddressFromForm($shippingAddressForm);
	var $billingSavedAddrDropdown = $('#dwfrm_billing_addressList');
	var didBillingAddressSuccessHandlerRun = false;
	var didShippingAddressSuccessHandlerRun = false;
	
	$('#dwfrm_billingaddress_country').trigger('change');
	$('#dwfrm_shippingaddress_country').trigger('change');

	$.validator.addMethod('requiredExcludingPunctuation', ValidationHelper.ValidateRequiredExcludingPunctuation, Resources.INVALID_VALUE);
	$.validator.addMethod('postalCode', ValidationHelper.ValidatePostalCode, Resources.INVALID_POSTAL_CODE);
	$.validator.addMethod('postOfficeBoxOnly', ValidationHelper.ValidateAddressForPostOfficeBoxOnly, Resources.INVALID_POST_OFFICE_BOX_ONLY);
	
	billingAddressFormValidator = $billingAddressForm.validate();
	billingAddressFormValidator.settings.ignore = [":not(.required)"];
	billingAddressFormValidator.settings.onfocusout = ValidationSettings.Checkout.onfocusout;
	billingAddressFormValidator.settings.errorPlacement = ValidationSettings.Checkout.errorPlacement;
	$billingAddressForm.find('input[name$="_firstName"]').rules('add', ValidationRules.RequiredExcludingPunctuation);
	$billingAddressForm.find('input[name$="_lastName"]').rules('add', ValidationRules.RequiredExcludingPunctuation);
	$billingAddressForm.find('input[name$="_address1"]').rules('add', ValidationRules.RequiredExcludingPunctuation);
	$billingAddressForm.find('input[name$="_address1"]').rules('add', ValidationRules.ValidateAddressForPostOfficeBoxOnly);
	$billingAddressForm.find('input[name$="_address2"]').rules('add', ValidationRules.ValidateAddressForPostOfficeBoxOnly);
	$billingAddressForm.find('input[name$="_city"]').rules('add', ValidationRules.RequiredExcludingPunctuation);
	$billingAddressForm.find('input[name$="_postal"]').rules('add', ValidationRules.RequiredExcludingPunctuation);

	shippingAddressFormValidator = $shippingAddressForm.validate();
	shippingAddressFormValidator.settings.ignore = [":not(.required)"];
	shippingAddressFormValidator.settings.onfocusout = ValidationSettings.Checkout.onfocusout;
	shippingAddressFormValidator.settings.errorPlacement = ValidationSettings.Checkout.errorPlacement;
	$shippingAddressForm.find('input[name$="_firstName"]').rules('add', ValidationRules.RequiredExcludingPunctuation);
	$shippingAddressForm.find('input[name$="_lastName"]').rules('add', ValidationRules.RequiredExcludingPunctuation);
	$shippingAddressForm.find('input[name$="_address1"]').rules('add', ValidationRules.RequiredExcludingPunctuation);
	$shippingAddressForm.find('input[name$="_address1"]').rules('add', ValidationRules.ValidateAddressForPostOfficeBoxOnly);
	$shippingAddressForm.find('input[name$="_address2"]').rules('add', ValidationRules.ValidateAddressForPostOfficeBoxOnly);
	$shippingAddressForm.find('input[name$="_city"]').rules('add', ValidationRules.RequiredExcludingPunctuation);
	$shippingAddressForm.find('input[name$="_postal"]').rules('add', ValidationRules.RequiredExcludingPunctuation);

	if (!billingAddress.address1 && $billingSavedAddrDropdown.find('option').length && $billingSavedAddrDropdown.val()) {
		$billingSavedAddrDropdown.trigger('change');
	} else {
		if (billingAddressFormValidator.checkForm()) {
			$billingAddressForm.find('input.shipping-same').prop('checked', false);
			$billingAddressForm.find('input.shipping-newsletter-signup').prop('checked', false);
			didBillingAddressSuccessHandlerRun = billingAddressSuccessHandler(null, true);
		} else {
			editBillingAddress();
		}
	}

	renderMyStoresAddressSelector();
	var $shippingSavedAddrDropdown = $('#dwfrm_multipleshipping_addressList');
	if (billingAddressFormValidator.checkForm() && !didBillingAddressSuccessHandlerRun) {
		if (!shippingAddress.address1 && $shippingSavedAddrDropdown.find('option').length && $shippingSavedAddrDropdown.val()) {
			$shippingSavedAddrDropdown.trigger('change');
		} else {
			if (shippingAddressFormValidator.checkForm()) {
				didShippingAddressSuccessHandlerRun = shippingAddressSuccessHandler(true);
				verifyShipping();
			} else {
				editShippingAddress();
			}
		}
	}
	
	$('.form-checkout.shipping-address-section .select-address.multiple').show();
	$('.address-section').addClass('active');
	
	if (didBillingAddressSuccessHandlerRun && shippingAddressFormValidator.checkForm() && window.innerWidth <= 767) {
		setTimeout(function() {
			$([document.documentElement, document.body]).scrollTop($('.form-checkout.payment-section').offset().top - $('.header-wrapper').height() + 20);
		}, 1000);
	}
}

function billingAddressSuccessHandler(responseData, skipUpdateShippingMethodList, isVerified) {
	var $shippingSavedAddrDropdown = $('#dwfrm_multipleshipping_addressList');
	var shippingAddress = getAddressFromForm($shippingAddressForm);

	$billingAddressForm.hide();
	$('.form-checkout.address-section').attr('panel-mode', 'view').attr('value', 'true');
	$('.billing-address-data').html('').append(buildDetailsBlockForForm($billingAddressForm)).show();
	$('.shipping-address-cont').removeClass('hidden');

	if (responseData && responseData.status == 'success' && ($('#isShippingAddressSameAsBillingAddress').prop('checked') || $('[has-store-pickup-shipment-only]').attr('has-store-pickup-shipment-only') == 'true')) {
		populateBillingAddressFormToShippingAddressForm();
	}
	
	if (responseData && responseData.status == 'shippingAddressValidationError') {
		editShippingAddress();
	} else if (SitePreferences.BYPASS_SHIPPING && ($('#isShippingAddressSameAsBillingAddress').prop('checked') || $('[has-store-pickup-shipment-only]').attr('has-store-pickup-shipment-only') == 'true')) {
		$('.shipping-address-data.form-info').html('').append(buildDetailsBlockForForm($shippingAddressForm));
		$('.shipping-address-data').show();

		$shippingAddressForm.hide();
		$shippingAddressForm.data('shipping-filled', true);
		$paymentFormContainer.addClass('active');
		$('.shipping-payment-cont').removeClass('hidden');
		submitShipping(null, isVerified);
	} else if (shippingAddressFormValidator.checkForm()) {
		shippingAddressSuccessHandler(skipUpdateShippingMethodList);
	} else if (!shippingAddress.address1 && $shippingSavedAddrDropdown.find('option').length && $shippingSavedAddrDropdown.val()) {
		$shippingSavedAddrDropdown.trigger('change');
	} else {
		editShippingAddress();
	}

	$('.shipping-address-section').addClass('active').show();

	if (!skipUpdateShippingMethodList) {
		updateShippingMethodList($shippingAddressForm, true);
	}

	if ($shippingAddressForm.data('shipping-filled') == true) {
		$paymentFormContainer.addClass('active').find('.shipping-payment-cont').removeClass('hidden');
		$shippingAddressForm.hide();
		$('.shipping-address-data').show();
	}

	if (window.innerWidth <= 767) {
		$(window).scrollTop($('.shipping-address-section').offset().top - $('.header-wrapper').height());
	}
	
	return true;
}

function shippingAddressSuccessHandler(skipUpdateShippingMethodList, isCancel, isVerified) {
	var selectedAddressID;
	var currentAddressIDs = [];

	$('.select-address.address-selector select option').map(function() { currentAddressIDs.push($(this).val()); });

	if ($shippingAddressForm.hasClass('add') || ($shippingAddressForm.hasClass('edit') && !$shippingAddressForm.find('.set-default-shipping input').prop('checked'))) {
		selectedAddressID = "new";
		$shippingAddressForm.find('input[name$="_ID"]').val('');

		if ($('#dwfrm_multipleshipping_addressList').find('option.placeholder').length == 0) {
			$('#dwfrm_multipleshipping_addressList').prepend('<option class="placeholder">' + Resources.OPC_SELECT_ADDR + '</option>').prop('selectedIndex', 0);
		}
	} else {
		selectedAddressID = $('.select-address.address-selector select').val();
	}

	writeShippingAddressFormToBuffer();
	var panelMode = $shippingAddressSection.attr('panel-mode');
	$shippingAddressForm.removeClass('add edit');
	$('.form-checkout.shipping-address-section').attr('panel-mode', 'view');

	$shippingAddressForm.find('.add-to-address-book input').prop('checked', false);
	$shippingAddressForm.find('.set-default-shipping input').prop('checked', false);
	
	var renderAddressSelectorCallback = function () {
		$shippingAddressForm.data('shipping-filled', true);
		$shippingAddressForm.hide();
		$shippingAddressForm.find('input[name="action"]').val('');

		if (!isCancel && (panelMode == 'add' || panelMode == 'edit')) {
			$('#dwfrm_multipleshipping_addressList').prop('selectedIndex', 0);
		}

		$('.shipping-address-data.form-info').html('').append(buildDetailsBlockForForm($shippingAddressForm));
		$('.shipping-address-data').show();
		if (!isVerified) {
			verifyShipping(isCancel, isVerified);
		}

		if (billingAddressFormValidator.checkForm()) {
			$('.shipping-payment-cont').removeClass('hidden');
    		$('.payment-section').addClass('active');
		} else {
			$('.shipping-payment-cont').addClass('hidden');
			$('.payment-section').removeClass('active');
			$('.billing-address-data.form-info').hide();

			var formBody = $('.billing-address-form');
			formBody.show();
			formBody.find('input.required.field.error').eq(0).focus();
		}

		if (!skipUpdateShippingMethodList) {
			updateShippingMethodList($shippingAddressForm, true, null, true);
		}
	}

	var isAuthenticated = $sectionCheckout.attr('is-authenticated') == "true";
	if (isAuthenticated) {
		renderAddressSelector({
			container: '.select-address.address-selector',
			el: '.select-address.address-selector .field-wrapper',
			id: 'dwfrm_multipleshipping_addressList',
			selected: selectedAddressID,
			currentAddresses: currentAddressIDs,
			callback: renderAddressSelectorCallback
		});
	} else {
		renderAddressSelectorCallback();
	}

	if (window.innerWidth <= 767) {
		$(window).scrollTop($('.payment-section').offset().top - $('.header-wrapper').height());
	}
	
	return true;
}

function submitBilling(isVerified) {
	$('#submitBilling').attr('disabled', true);
	if ($('.billing-address-type-selector input[value=1]').prop('checked') && $('#dwfrm_billing_addressList').val() == '') {
		$('#submitBilling').attr('disabled', false);
		return;
	}

	if ($('#isShippingAddressSameAsBillingAddress').is(':checked') || $('[has-store-pickup-shipment-only]').attr('has-store-pickup-shipment-only') == 'true') {
		$('input[name$="_useAsShippingAddress"]').prop('checked', true);
	} else {
		$('input[name$="_useAsShippingAddress"]').prop('checked', false);
	}

	billingAddressFormValidator.form();
	$('.custom-email-error').remove();

	var isEmailValid = util.validateEmail($('#dwfrm_billingaddress_email').val());
	if (billingAddressFormValidator.valid() && isEmailValid) {
		$billingAddressFormContainer.find('.validation-error-container').hide();
		verifyBilling(null, isVerified);
	} else {
		$billingAddressFormContainer.find('.validation-error-container').show();
		$('#submitBilling').attr('disabled', false);
		if (!isEmailValid && billingAddressFormValidator.valid()) {
			$('#dwfrm_billingaddress_email').addClass('error').parent().append('<span class="error custom-email-error">' + Resources.VALIDATE_EMAIL + '</span>');
		}
		$('.billing-address-type-selector input[value="2"]').trigger('click', [true]);
	}
}

function verifyBilling(isCancel, isVerified) {
	var addressDeliverable = function () {
		setOPCAddress(billingAddressFormSelector, $('#selectOpcAddress').attr('name')).then(function (data) {
			billingAddressSuccessHandler(data, null, true);
			$('#submitBilling').attr('disabled', false);
		});
	}

	var addressNotDeliverable = function (addressVerifyData) {
		AddressVerificationDialog.initialize(options, 'billing', addressVerifyData);
		$('#submitBilling').attr('disabled', false);
	}

	var country = $billingAddressForm.find('[id*="_country"]').val();
	if (!isVerified && options && options.Lob && options.Lob.AddressVerification.Enabled && country.toUpperCase() == 'US') {
		AddressVerificationHelper.VerifyAddress($billingAddressForm, options).then(addressDeliverable, addressNotDeliverable);
	} else {
		addressDeliverable();
	}
}

function submitShipping(isCancel, isVerified) {
	$('#submitShipping').attr('disabled', true);
	$('.shipping-address-cont span.error').remove();
	$('.shipping-address-cont input, .shipping-address-cont select').removeClass('error');

	shippingAddressFormValidator.form();
	if ($shippingAddressForm.valid()) {
		$shippingAddressFormContainer.find('.validation-error-container').hide();
		verifyShipping(isCancel, isVerified);
	} else {
		$shippingAddressFormContainer.find('.validation-error-container').show();
		$('#submitShipping').attr('disabled', false);
	}
}

function verifyShipping(isCancel, isVerified) {
	var addressDeliverable = function () {
		setOPCAddress(shippingAddressFormSelector, $('#submitShipping').attr('name')).then(function (data) {
			var isShippingRestricted = handleShippingRestrictions(data);
			$('#submitShipping').attr('disabled', false);
			if (!isShippingRestricted) {
				shippingAddressSuccessHandler(false, isCancel, true);
				$('.payment-methods-section').show();
			}
		});
	}

	var addressNotDeliverable = function (addressVerifyData) {
		AddressVerificationDialog.initialize(options, 'shipping', addressVerifyData);
		$('#submitShipping').attr('disabled', false);
	}

	var country = $shippingAddressForm.find('[id*="_country"]').val();
	if (!isVerified && options && options.Lob && options.Lob.AddressVerification.Enabled && country.toUpperCase() == 'US') {
		AddressVerificationHelper.VerifyAddress($shippingAddressForm, options).then(addressDeliverable, addressNotDeliverable);
	} else {
		addressDeliverable();
	}
}

function writeBufferToAddressForm(name, $selector) {
	if (dataBuffer[name]) {
		$selector.each(function (index, element) {
			var $field = $(element);
			$field.val(dataBuffer[name][$field.attr('name')] || '');
		});
		
		if (name == 'shippingAddressFields') {
			var $form = $('.shipping-address-form');
			var $countryDropdown = $form.find('[id*="_country"]');
			var $stateDropdown = $form.find('[id*="states_state"]');
			renderOptions('#' + $stateDropdown.attr('id'), '#' + $countryDropdown.attr('id'));
			
			if ($('#dwfrm_shippingaddress_states_state').length > 0 && dataBuffer['shippingAddressFields']['dwfrm_shippingaddress_states_state']) {
				$('#dwfrm_shippingaddress_states_state').val(dataBuffer['shippingAddressFields']['dwfrm_shippingaddress_states_state']);
			}
		}
	}
}

function writeBufferToShippingAddressForm() {
	var $shippingAddressFields = $shippingAddressForm.find('.formfield input[type="text"], .formfield select').not('[disabled]').not('#dwfrm_shippingaddress_myStoreID');
	writeBufferToAddressForm('shippingAddressFields', $shippingAddressFields);
}

function writeAddressFormToBuffer(name, $selector, doClear) {
	$selector.each(function (index, element) {
		var $field = $(element);

		if ($field.val()) {
			if (!dataBuffer[name]) {
				dataBuffer[name] = {};
			}

			dataBuffer[name][$field.attr('name')] = $field.val();
		}
	});

	if (doClear) {
		$selector.val('');
		$selector.find('option[default]').prop('selected', true);
	}
}

function writeShippingAddressFormToBuffer(doClear) {
	var $shippingAddressFields = $shippingAddressForm.find('.formfield input[type="text"], .formfield select').not('[disabled]').not('#dwfrm_shippingaddress_myStoreID');
	writeAddressFormToBuffer('shippingAddressFields', $shippingAddressFields, doClear);
}

/**
 * create address object from form
 * @param $form
 * @returns Address Object
 */
function getAddressFromForm($form) {
	return {
		ID: util.htmlEncode($form.find('input[name$="_ID"]').val()),
		isStore: util.htmlEncode($form.find('input[name$="_isStore"]').prop('checked')),
		firstName: util.htmlEncode($form.find('input[name$="_firstName"]').val()),
		lastName: util.htmlEncode($form.find('input[name$="_lastName"]').val()),
		address1: util.htmlEncode($form.find('input[name$="_address1"]').val()),
		address2: util.htmlEncode($form.find('input[name$="_address2"]').val()),
		address3: util.htmlEncode($form.find('input[name$="_address3"]').val()),
		countryCode: util.htmlEncode($form.find('select[id$="_country"]').val()),
		stateCode: util.htmlEncode($form.find('select[id$="_state"]').val()),
		postalCode: $form.find('input[name$="_postal"]').val() ? util.htmlEncode($form.find('input[name$="_postal"]').val().toUpperCase()) : '',
		city: util.htmlEncode($form.find('input[name$="_city"]').val()),
		phone: util.htmlEncode($form.find('input[name$="_phone"]').val()),
		email: util.htmlEncode($form.find('input[name$="_email"]').val())
	};
}

function populateBillingAddressFormToShippingAddressForm() {
	var billingAddress = getAddressFromForm($billingAddressForm);

	$shippingAddressForm.find('input[name$="_ID"]').val('');
	$shippingAddressForm.find('input[name$="_firstName"]').val(billingAddress.firstName).trigger('change');
	$shippingAddressForm.find('input[name$="_lastName"]').val(billingAddress.lastName).trigger('change');
	$shippingAddressForm.find('input[name$="_address1"]').val(billingAddress.address1).trigger('change');
	$shippingAddressForm.find('input[name$="_address2"]').val(billingAddress.address2).trigger('change');
	$shippingAddressForm.find('input[name$="_address3"]').val(billingAddress.address3).trigger('change');
	$shippingAddressForm.find('input[name$="_postal"]').val(billingAddress.postalCode).trigger('change');
	$shippingAddressForm.find('input[name$="_city"]').val(billingAddress.city).trigger('change');
	$shippingAddressForm.find('select[id$=country]').val(billingAddress.countryCode).trigger('change');
	$shippingAddressForm.find('select[id$=state]').val(billingAddress.stateCode).trigger('change');
	$shippingAddressForm.find('input[name$="_phone"]').val(billingAddress.phone).trigger('change');

    if ($('#dwfrm_multipleshipping_addressList').find('option.placeholder').length == 0) {
    	$('#dwfrm_multipleshipping_addressList').prepend('<option class="placeholder">' + Resources.OPC_SELECT_ADDR + '</option>').prop('selectedIndex', 0);
    }
}

function buildDetailsBlockForForm($form, isCompact, hideNickname) {
	var address = getAddressFromForm($form);
	for (var addressKey in address) {
    	if (address[addressKey] != null && address[addressKey].search(/\^/) != -1) {
    		address[addressKey] = address[addressKey].replace(/\^/gi,"'");
    	}
    }

	return buildDetailsBlockForAddress(address, isCompact, hideNickname, $form.attr('id'));
}

function buildDetailsBlockForAddress(address, isCompact, hideNickname, formId) {
	var shippingAddressType = getShippingAddressType();
    var htmlText = $('<div/>', {"class": "address-details-st"});
    var header = isCompact ? '' : formId == 'billingAddressForm' ? '<h6>Billing Recipient</h6>' : formId == 'shippingAddressForm' ? '<h6>Ship To:</h6>' : '';
    var editButton = (!isCompact && !shippingAddressType || shippingAddressType == SiteConstants.AddressTypes.Customer) ? '<p class="text-right edit-address-container"><a class="edit-address-det" tabindex="0">Edit<span class="visually-hidden">&nbsp;Address Information</span></a></p>' : '';
    var addNewButton = '';
	var addressNickname = '';
	
	if (formId == 'shippingAddressForm') {
		if (address.address1 && !hideNickname) {
			if (address.ID && !address.myStoreID) {
				addressNickname = address.ID;
			} else if (formId == 'shippingAddressForm' && shippingAddressType == SiteConstants.AddressTypes.Customer && dataBuffer['dwfrm_multipleshipping_addressList']) {
				var selectedAddressFromDropdown = dataBuffer['dwfrm_multipleshipping_addressList'].filter(function (addressOption) {
					return addressOption.UUID == $('#dwfrm_multipleshipping_addressList').val();
				});
				
				if (selectedAddressFromDropdown.length > 0 && selectedAddressFromDropdown[0].displayValue && $('[has-warehouse-shipment]').attr('has-warehouse-shipment') == 'true') {
					util.fillAddressFields(selectedAddressFromDropdown[0], $('#shippingAddressForm'));
					writeShippingAddressFormToBuffer();
					addressNickname = selectedAddressFromDropdown[0].displayValue;
				}
			}
			
			if (addressNickname) {
				addressNickname = '<span class="address-nickname">' + addressNickname + '</span>';
			}

			$('.form-checkout.shipping-address-section').attr('panel-mode', 'view').attr('value', 'true');
		}
		
		if (!isCompact && shippingAddressType == SiteConstants.AddressTypes.Customer) {
			addNewButton = '<p class="add-address-container"><a class="add-address-det">+ Add New Address</a></p>';
		}
	}
	
    var htmlcontent = '';
    if (address.address1) {
	    htmlcontent += header;
	    htmlcontent += '<div class="address" tabindex="0"><div>' + (address.myStoreID ? address.fullName : address.firstName) + ' ' + address.lastName + addressNickname + ' </div>';
	    htmlcontent += '<div>' + address.address1 + ' </div>';
	    if (address.address2) {
	    	htmlcontent += '<div>' + address.address2 + ' </div>';
	    }

	    if (address.address3) {
	    	htmlcontent += '<div>' + address.address3 + ' </div>';
	    }

	    htmlcontent += '<div>' + address.city;

	    if (address.stateCode && address.stateCode.toUpperCase() != 'OTHER') {
	    	htmlcontent += ' ' + address.stateCode;
	    }

	    htmlcontent += ' ' + address.postalCode;
		htmlcontent += ' ' + address.countryCode + '</div>';
		if (address.phone) {
			htmlcontent += '<div>' + address.phone + '</div>';
		}

	    if (address.email) {
	    	htmlcontent += '<div class="billing-email-address">' + address.email + '</div>';
		}
		htmlcontent += '</div>';

	    htmlcontent += editButton;
	    htmlcontent += addNewButton;
    }

    htmlText.html(htmlcontent);

    return htmlText;
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL($form, url, extraParams) {
    var params = {
		isStore: $form.find('input[name$="_isStore"]').prop('checked'),
		myStoreID: $form.find('input[name$="_myStoreID"]').val(),
    	address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        address3: $form.find('input[name$="_address3"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };

    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

function updateShippingMethodList($form, doUpdateSummary, isMyStore, doDataLayerPush, $selectedShippingMethod) {
	if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
	
	var shippingMethodListData = {
		selectedPaymentMethod: $paymentForm.find('input[name*="_selectedPaymentMethodID"]').val()
	}

	var $shipToStoreOptionInAddressPanel = $('.single.shipping-address-type-selector');
	if ($shipToStoreOptionInAddressPanel.length > 0) {
		if ($shipToStoreOptionInAddressPanel.find('input:checked').val() == SiteConstants.AddressTypes.Store) {
			shippingMethodListData.ShipToStoreOnly = true;

			if (dataBuffer[CheckoutConstants.User.SelectedStoreShippingMethod]) {
				shippingMethodListData.SelectShippingMethod = dataBuffer[CheckoutConstants.User.SelectedStoreShippingMethod];
			}
		} else if ($shipToStoreOptionInAddressPanel.find('input:checked').val() == SiteConstants.AddressTypes.Customer) {
			shippingMethodListData.ShipToStoreExclude = true;

			if (dataBuffer[CheckoutConstants.User.SelectedCustomerShippingMethod]) {
				shippingMethodListData.SelectShippingMethod = dataBuffer[CheckoutConstants.User.SelectedCustomerShippingMethod];
			}
		}
	}

	if (isMyStore) {
		shippingMethodListData.ShipToStoreOnly = true;
	}

	var shippingMethodListUrl = getShippingMethodURL($form, Urls.shippingMethodsList);
	$shippingMethodList.load(shippingMethodListUrl, shippingMethodListData, function () {
		var selectedStore = $shippingAddressForm.find('.formfield.myStoreID input').val();
		var $shipToStoreShippingMethods;
		if (options && options.AgentStore && options.AgentStore.StoreShippingAddressOnly) {
			$shipToStoreShippingMethods = $('[shipping-method]');
		} else {
			$shipToStoreShippingMethods = $('[shipping-method][is-ship-to-store="true"]');
		}

		if ($shipToStoreShippingMethods.length > 0) {
			var selectedStoreFromShippingMethod = $shipToStoreShippingMethods.find('.store-selector select').val();

			if (selectedStoreFromShippingMethod && selectedStoreFromShippingMethod != selectedStore && $shipToStoreShippingMethods.find('.input-radio').is(':checked')) {
				selectedStore = selectedStoreFromShippingMethod;
				$shippingAddressForm.find('.formfield.myStoreID input').val(selectedStore);
				setOPCAddress(shippingAddressFormSelector, 'dwfrm_shippingaddress_saveopcaddress').then(function (data) { });
			}

			buildShipToStoreDetails($shipToStoreShippingMethods);
		}

		if (selectedStore && dataBuffer && dataBuffer['mystores_addressList']) {
			var selectedStoreInBuffer = dataBuffer['mystores_addressList'].filter(function (address) {
				return address.myStoreID == selectedStore;
			})[0];

			if (selectedStoreInBuffer && selectedStoreInBuffer.UUID != $('#mystores_addressList').val()) {
				$('#mystores_addressList').val(selectedStoreInBuffer.UUID);
				var shippingAddressType = getShippingAddressType();
				if (shippingAddressType == SiteConstants.AddressTypes.Store) {
					$('#mystores_addressList').trigger('change');
					doUpdateSummary = false;
				}
			}
		}
		$shippingMethodList.fadeIn('fast');

		if (doUpdateSummary) {
			updateSummary();
		}
		
		tooltip.init({
			items: '.shipping-method-tooltip',
			tooltipClass: 'shipping-method-tooltip'
		}, $('[shipping-method]'));
		tooltip.init({
			items: '.tooltip.RewardsCredit',
			tooltipClass: 'RewardsCredit'
		}, $('[payment-method="REWARDS_CREDIT"]'));
		tooltip.init({
			items: '.tooltip.LoyaltyCredit',
			tooltipClass: 'LoyaltyCredit'
		}, $('[payment-method="LOYALTY_CREDIT"]'));
		tooltip.init({
			items: '.tooltip'
		}, $('.form-row.cvn'));

		var isStoreAddress = isShipToStoreEnabled();
		setShipToStore(isStoreAddress);

		if (doDataLayerPush) {
			pushDataLayerForShippingMethods($shippingMethodList, $selectedShippingMethod);
		}
	});
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    $(document).trigger('Cart.Update');
}

function getShippingAddressType() {
	var shippingAddressType = $('.single.shipping-address-type-selector input:checked').val();
	if (!shippingAddressType) {
		shippingAddressType = SiteConstants.AddressTypes.Customer;
	}
	return shippingAddressType;
}

function renderMyStoresAddressSelector(parameters) {
	var templateSettings = {
    	interpolate: /{{([\s\S]+?)}}/g
    };

    var templates = [];
	templates['option'] = _.template($('#template-address-selector-option').html(), templateSettings);

	$.ajax({
		method: 'POST',
		url: Urls.getMyStoresAddressList,
		contentType: 'application/json',
		data: JSON.stringify({ storeQuery: StoreQueryFilters.ShipToStoreEnabled }),
		dataType: 'json',
		success: function(data) {
			var html = [];
			html['options'] = '';

			dataBuffer['mystores_addressList'] = data.addresses;
			dataBuffer['store_name_lookup'] = {};
			data.addresses.map(function (element) {
				dataBuffer['store_name_lookup'][element.myStoreID] = element.fullName;
			});

			var isStoreAddressSelected = false;
			for (var index = 0; index < data.addresses.length; index++) {
				var address = data.addresses[index];

				if (address.isSelected) {
					isStoreAddressSelected = true;
				}
				
				var optionData = _.extend(address, {
					id: address.UUID,
					cssClass: '',
					selected: address.isSelected ? 'selected' : '',
					text: address.labelText
				});

				optionData.ID = address.myStoreID;
				html['options'] += templates['option'](optionData);
			}

			$('#mystores_addressList').html(html['options']);

			if (isStoreAddressSelected) {
				setShipToStore(true);
				$('.single.shipping-address-type-selector input[value="' + SiteConstants.AddressTypes.Store + '"]').click();
			}
			
			if (parameters && parameters.callback) {
				parameters.callback();
			}
		}
	});
}

function renderAddressSelector(parameters) {
	var templateSettings = {
    	interpolate: /{{([\s\S]+?)}}/g
    };

    var templates = [];
    templates['selector'] = _.template($('#template-address-selector').html(), templateSettings);
    templates['option'] = _.template($('#template-address-selector-option').html(), templateSettings);

	$.ajax({
		method: 'POST',
		url: Urls.getAddressList,
		contentType: 'application/json',
		data: JSON.stringify({ storeQuery: StoreQueryFilters.ShipToStoreEnabled }),
		dataType: 'json',
		success: function(data) {
			var html = [];
			var hasAddressBookAddresses = false;
			var selectedAddressID = null;

			dataBuffer[parameters.id] = data.addresses;

			var isAddressSelectedInData = false;
			for (var index = 0; index < data.addresses.length; index++) {
				var address = data.addresses[index];
				
				if (address.isSelected) {
					isAddressSelectedInData = true;
					selectedAddressID = address.ID;
					break;
				}
			}
			
			html['options'] = '';
			for (var index = 0; index < data.addresses.length; index++) {
				var address = data.addresses[index];

				if (address.isShippingAddress) {
					var addressId = address.key ? address.key : address.ID;
					var isSelected = false;

					if (!$shippingAddressForm.find('input[name$="_address1"]').val() && $('[has-warehouse-shipment]').attr('has-warehouse-shipment') == 'true') {
						if (!isAddressSelectedInData) {
							if (!selectedAddressID && parameters.currentAddresses && parameters.currentAddresses.length > 0 && parameters.currentAddresses.indexOf(address.UUID) >= 0) {
								isSelected = true;
								selectedAddressID = address.ID;
							} else if (!selectedAddressID && parameters.selected && address.ID == parameters.selected && parameters.selected != "new") {
								isSelected = true;
								selectedAddressID = address.ID;
							} else if (!selectedAddressID && address.ID == $('#dwfrm_multipleshipping_addressList:visible option').val() && parameters.selected != "new") {
								isSelected = true;
								selectedAddressID = address.ID;
							} else if (!selectedAddressID && address.isDefault && parameters.selected != "new") {
								isSelected = true;
								selectedAddressID = address.ID;
							}
						}
					}

					var optionData = _.extend(address, {
						id: address.UUID,
						cssClass: '',
						selected: isSelected || address.isSelected ? 'selected' : '',
						text: '(' + (!address.displayValue ? addressId : address.displayValue) + ') ' + address.labelText
					});

					if (!address.isMyStore) {
						hasAddressBookAddresses = true;
					}

					html['options'] += templates['option'](optionData);
				}
			}

			if (hasAddressBookAddresses) {
				html['selector'] = templates['selector']({
					id: parameters.id,
					options: html['options']
				});

				var $html = $(html['selector']);

				if (isSelected || address.isSelected) {
					$html.find('option.placeholder').remove();
				}

				$(parameters.el).html($html);
				$(parameters.container).show();
			} else {
				$(parameters.container).hide();
			}

			if (parameters && parameters.callback) {
				parameters.callback();
			}
		}
	});	
}

function editBillingAddress() {
	$('.bill-address-fields').removeClass('collapsed').show();
	$('.billing-select-address').hide();
	
	$('.billing-address-preview').hide();
	$('.billing-address-data.form-info').hide();
	
	$billingAddressFormContainer.show();
	$billingAddressForm.show();
	
	$shippingAddressFormContainer.data('hidden-editable', $('.shipping-address-data.form-info').html().length ? true : false).hide();
	
	$('.form-checkout.shipping-address-section').removeClass('active').find('.shipping-address-cont').addClass('hidden');
	$('.form-checkout.payment-section').removeClass('active').find('.shipping-payment-cont').addClass('hidden');
}

function editShippingAddress() {
	writeShippingAddressFormToBuffer();
	openShippingAddressForm();
}

function openShippingAddressForm() {
	$('.form-checkout.shipping-address-section').attr('panel-mode', 'edit');
	$shippingAddressFormContainer.data('hidden-editable', true).hide();
	$('.shipping-address-data').hide();
	$shippingAddressFormContainer.show();
	$shippingAddressForm.removeClass('add edit').addClass('edit');
	$shippingAddressForm.find('input[name="action"]').val('edit');
		
	$billingAddressForm.hide();
	$('.billing-address-data.form-info').css('display', 'block');
	$('.form-checkout.payment-section').removeClass('active').find('.shipping-payment-cont').addClass('hidden');
	
	$('.shipping-select-address').hide();
	$('.editaddress-shipping').removeClass('collapsed');
}

function setShipToStore(isStoreAddress) {
	$sectionCheckout.attr('is-my-store', isStoreAddress);
	$('#dwfrm_shippingaddress_isStore').prop('checked', isStoreAddress);
}

function isShipToStoreEnabled() {
	return $('#dwfrm_shippingaddress_isStore').prop('checked');
}

function buildShipToStoreDetails($shippingMethods) {
	$shippingMethods.each(function(index, element) {
		var $shippingMethod = $(element);
		var $selectedStore = $shippingMethod.find('.store-selector option:checked');
		var storeSelectorCount = $shippingMethod.find('.store-selector option').length;
		var storeData = $selectedStore.data('address');
		if (storeData && storeData.address1) {
			var storeHtml = '<p>' + storeData.name + '</p>' +
							'<p>' + storeData.address1 + '</p>';
			if (storeData.address2) {
				storeHtml += '<p>' + storeData.address2 + '</p>';
			}
			storeHtml += '<p>' + storeData.city + ', ' + storeData.state + ' ' + storeData.postalCode + '</p>';
	
			if (storeSelectorCount > 1 && SitePreferences.ENABLE_SHIP_TO_STORE_SELECTION && !isStoreShippingAddressOnly) {
				storeHtml += '<a class="action change" tabindex="0">Change Store</a>';
			}

			$shippingMethod.find('.store-details').html(storeHtml);
		}
	});
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shipmentID, shippingMethodID, isShipToStore, $selectedShippingMethod) {
    var shippingAddressType = getShippingAddressType();
	if (shippingAddressType == SiteConstants.AddressTypes.Customer) {
		dataBuffer[CheckoutConstants.User.SelectedCustomerShippingMethod] = shippingMethodID;
	} else if (shippingAddressType == SiteConstants.AddressTypes.Store) {
		dataBuffer[CheckoutConstants.User.SelectedStoreShippingMethod] = shippingMethodID;
	}

	if (isShipToStore || isStoreShippingAddressOnly) {
		setShipToStore(true); 
	} else {
		setShipToStore(false);
	}

    // attempt to set shipping method
    var url = getShippingMethodURL($shippingAddressForm, Urls.selectShippingMethodsList, {shipmentID: shipmentID, shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

            $('[name*="_shippingMethodID"]').prop('disabled', false);
            updateShippingMethodList($shippingAddressForm, true, null, true, $selectedShippingMethod);
        }
    });
}

/**
 * @description Make the AJAX request to set address data
 * @param {Element} form The form element that present address object
 * @param {Element} Action name to apply to form
 * @returns {Promise}
 */
var setOPCAddress = function (form, name) {
	var $form = $(form);
	var url = (form == '#billingAddressForm') ? Urls.setOpcAddress : $form.attr('action');
	var sanitizedFormData = {};
	var serializedFormDataArray = $form.serializeArray();
	var hasCountryField = false;
	for (var serializedFormDataKey in serializedFormDataArray) {
		var serializedFormElement = serializedFormDataArray[serializedFormDataKey];
		if (serializedFormElement && serializedFormElement.name && sanitizedFormData[serializedFormElement.name]) {
			sanitizedFormData[serializedFormElement.name] += ',' + serializedFormElement.value;
		} else {
			sanitizedFormData[serializedFormElement.name] = serializedFormElement.value;
		}
		if (serializedFormElement && serializedFormElement.name && serializedFormElement.name.indexOf('_country') < 0) {
			hasCountryField = true;
		}
	}
	if (hasCountryField) {
		var $countryField = $form.find('[name$="_country"]');
		sanitizedFormData[$countryField.attr('name')] = $countryField.val();
	}
	sanitizedFormData[name] = name;

    return new Promise(function (resolve, reject) {
		$.ajax({
			type: 'POST',
			url: util.ajaxUrl(url),
			data: sanitizedFormData,
			success: function(data) {
				resolve(data);
			},
			error: function(data) {
				reject(data);
			}
		});
    });
};

function pushDataLayerForShippingMethods($shippingMethodList, $selectedShippingMethod) {
	var $selectedShippingMethods;
	if ($selectedShippingMethod) {
		$selectedShippingMethods = $selectedShippingMethod;
	} else {
		$selectedShippingMethods = $shippingMethodList.find('input[type="radio"]:checked');
	}
	$selectedShippingMethods.each(function(index, element) {
		var $this = $(this);
		var $shippingMethod = $this.closest('[shipping-method]');
		var dataObject = {
			value: parseFloat($shippingMethod.attr('amount')),
			shipping_tier: $shippingMethod.attr('shipping-method')
		}

		GoogleTagManagerClient.PushDataLayerForDataLayerType('AddShippingInfo', dataObject, { ContainerSelector: '.order-item[shipment="' + $shippingMethod.closest('form').attr('shipment') + '"]' }).catch(function() {});
	});
}

function handleShippingRestrictions(data) {
	$('.shipping-address-cont').find('> .error').remove();
	var hasShippingRestrictions = data.status == 'shippingRestrictionsError';
	if (hasShippingRestrictions) {
		openRestrictionsPopup(data);
	}

	return hasShippingRestrictions;
}

function openRestrictionsPopup(data) {
	var payload = JSON.parse(data.payload);
	var popup = require('../../popup');
	
	var restrictionList = '';
	if (payload.list && payload.list.length > 0) {
		var list = $.map(payload.list, function (el) {
			var restrictionName = el.name ? el.name + ':<br/>' : '';
			
			return '<li>' + restrictionName + el.message + '</li>';
		}).join('');
		
		restrictionList = '<ul class="shipping-restrictions-popup__list">' + list + '</ul>';
	}
	
	popup.init({
		title: payload.title,
		content: payload.message + restrictionList
	}).showPopup();
}

function renderOptions(optionParent, optionsStoreId) {
	var optionsHtml;
	var optionsStoreOptions = $(optionsStoreId + ' option:selected').data('options');
	var statesList;
	
	if (optionsStoreOptions) {
		statesList = JSON.parse(optionsStoreOptions.replace(/\'/g, '\"'));
		
		if (statesList.length > 1) {
			optionsHtml += '<option value="">' + Resources.SelectPlaceholder + '</option>';
		}
		
		statesList.forEach(function (state) {
			optionsHtml += '<option class="select-option" label="' + state.label + '" value="' + state.code + '">' + state.label + '</option>';
		});

		var $optionParent = $(optionParent);
		$optionParent.empty().append(optionsHtml);

		if (statesList.length <= 1) {
			$optionParent.prop('selectedIndex', statesList.length - 1);
			$optionParent.removeClass('required');
			$optionParent.closest('.formfield').hide();
		} else {
			$optionParent.addClass('required');
			$optionParent.closest('.formfield').show();
		}
	}
}

$(document).on('update-shipping', function(e, callback) {
	if ($('.shipping-methods-section').length == 0) { return; }
	updateShippingMethodList($shippingAddressForm);
	if (callback) { callback(); }
});

exports.init = function (optionsParameter) {
	options = optionsParameter || {};
	initializeSelectors();

	if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
		AddressVerificationDialog = require('../../Lob/AddressVerification/AddressVerificationDialog');
	}

	if (options && options.Lob && options.Lob.AddressAutocomplete.Enabled) {
		AddressAutocompleteDialog = require('../../Lob/AddressAutocomplete/AddressAutocompleteDialog');
	}

	isStoreShippingAddressOnly = !!(options && options.AgentStore && options.AgentStore.StoreShippingAddressOnly);
	if (isStoreShippingAddressOnly) {
		setShipToStore(true);
	}
	initializeEvents();
	initializeUserInterface();
};

exports.updateSummary = updateSummary;