'use strict';

var _ = require('lodash');
var sliders = require('./sliders');
var SiteConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

exports.init = function init () {
	slickSlideJumbotron();

	// $('.jumbotron-ajax').ready(function(){
	// 	renderJumbotronSlot();
	// });
	
	var renderJumbotronSlot = function(){
		if (Urls && Urls.getJumbotronSlot) {
			$.ajax({ method: 'POST', url: Urls.getJumbotronSlot, dataType: 'html', success: function (data) {
				var html = '';
				var contentSlot;
				var isJson = false;
				
				var templateSettings = {
			    	interpolate: /{{([\s\S]+?)}}/g
			    };
				
				var template = _.template($('#template-jumbotron').html(), templateSettings);
				
				var dataPayload = data.substr(data.indexOf('{'), data.length);
				
				var endPayloadIndex = dataPayload.length;
				if (dataPayload.indexOf('<!-- dwMarker') > 0) {
					endPayloadIndex = dataPayload.indexOf('<!-- dwMarker');
				}
				dataPayload = dataPayload.substr(0, endPayloadIndex);
								
				try {
					contentSlot = JSON.parse(dataPayload);
					isJson = true;
				} catch (e) {
					console.warn('ContentSlot template not set to JSON');
				}
				
				if (contentSlot) {
					if (contentSlot.content) {
						html = template({ jumbotron: buildContentSlotFromJSON(contentSlot) });
					}
				} else if (!isJson) {
					html = template({ jumbotron: data });
				}
				
				$('.jumbotron-ajax').addClass('active').html(html);
				if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
					slickSlideJumbotron();
				}
				$(document).trigger('app.resize');
			}});
		}
	}

	//fishtag rigging content asset 'cat-global-header'
	//$('.cat-global-header').ready(function(){
	//	renderFishtag();
	//});
	
	function renderFishtag(){
		if (Urls && Urls.getFishtag) {
			$.ajax({ method: 'POST', url: Urls.getFishtag, dataType: 'html', success: function(data){
				var html = '';
				var contentSlot;
				var isJson = false;
				
				try {
					contentSlot = JSON.parse(data.substr(data.indexOf('{'), data.length));
					isJson = true;
				} catch (e) {
					console.warn('ContentSlot template not set to JSON');
				}
				
				if (contentSlot && contentSlot.content) {
					html = buildContentSlotFromJSON(contentSlot);
				} else if (!isJson) {
					html = data;
				}
				
				$('.fishtag-slot').html(html);
                rotateText($('.top-banner-message'), $('.top-banner-message').data('duration'));
			}});
		}
	}
	
	function buildContentSlotFromJSON(contentSlotJSON) {
		var body = '';
		
		if (contentSlotJSON && contentSlotJSON.content) {
			for (var contentSlotItem in contentSlotJSON.content) {
				body += contentSlotJSON.content[contentSlotItem].body;
			}
		
			if (contentSlotJSON.countdownTo) {
				body = '<div class="countdown-wrapper" data-countdown-date="' + contentSlotJSON.countdownTo + '">' + body + '</div>'; 
			}
		}
		
		return body;
	}
	
	// Rotate Text for fishtag
	function rotateText($element, duration) {
		var lengthSlider 			= $element.find('.rotate-element').length;
		var $rotateElement 			= $element.find('.rotate-element');

		setInterval(function() {
			var $rotateElementActive 	= $element.find('.active');
			var $nextElement = $rotateElementActive.next();

			if($nextElement.index() === -1) {
				$nextElement = $element.find('.rotate-element:first-child');

				$nextElement
					.addClass('active')
					.siblings()
					.removeClass('active');
			} else {
				$nextElement
					.addClass('active')
					.siblings()
					.removeClass('active');
			}
		}, duration);
	}

	function slickSlideJumbotron() {
		var slidesMobile = $('.gbcolumns').closest('[data-slides-mobile]').length > 0 ? parseInt($('.gbcolumns').closest('[data-slides-mobile]').attr('data-slides-mobile')) : 2;
		var slidesDesktop = $('.gbcolumns').closest('[data-slides-desktop]').length > 0 ? parseInt($('.gbcolumns').closest('[data-slides-desktop]').attr('data-slides-desktop')) : 4;
	
		sliders.init('.gbcolumns', {dots: false, breakpoints: {0: {slidesToShow: slidesMobile, arrows: true}, 768: {slidesToShow: slidesDesktop}}});
	}
}