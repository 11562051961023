'use strict';

/* ----------------------------------------
 * -- Sliders --
 * 
 * Configuration Options:
 *                loop:  BOOLEAN  Both; If no option given, programmatically set to true.
 *                dots:  BOOLEAN  Both; If no option given, programmatically set to true.
 *          pagination:  OBJECT   Swiper only; If no option given, pagination is omitted from slider settings.
 *              arrows:  BOOLEAN  Both; If no option given, automatically set to true in widget background.
 *      arrowsPosition:  STRING   ['inside' or 'outside'] Swiper only; If no option given, automatically set to the outside.
 *          navigation:  OBJECT   Swiper only; If no option given, navigation is omitted from slider settings.
 *        slidesToShow:  NUMBER   Both; If no option given, programmatically set to 1.
 *      slidesToScroll:  NUMBER   Both; If no option given, programmatically set to 1.
 *            autoplay:  NUMBER   Both; When given, number is programmatically set to Autoplay Speed and Autoplay is set to true. If no option given, autoplay is set to false.
 *         breakpoints:  OBJECT   Both; Properties are looped over to apply various breakpoint settings. If no option given, breakpoints is omitted from slider settings.
 *     
 * Example Code:
 *        sliders.init('.some-class-name', 
 *        {
 *             loop: true, 
 *             dots: true, 
 *             pagination: {el: '.swiper-pagination', clickable: true}, 
 *             arrows: true,
 *             arrowsPosition: 'inside',
 *             navigation: {nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'},
 *             slidesToShow: 6,
 *             slidesToScroll: 1,
 *             autoplay: 3000,
 *             breakpoints: {0: {slidesToShow: 1}, 768: {slidesToShow: 3}}
 *        });
 * 
 * 
 * Additional configuration options exist for both sliders, but additional code changes would be needed to include them. 
 * 
 * Reference slider documentation:
 * 
 * Swiper documentation - https://swiperjs.com/swiper-api
 * Slick documentation - https://kenwheeler.github.io/slick/
 *  
 * ----------------------------------------
 */

var SiteConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

exports.init = init;
exports.initializeCategorySliders = initializeCategorySliders;
exports.initializeRecommendationSlider = initializeRecommendationSlider;

function getUniqueNumber() {
	var dateObject = new Date();
	var uniqueNumber = dateObject.valueOf();
    var suffixNumber = 1 + Math.floor(Math.random() * 100);
    uniqueNumber = uniqueNumber + suffixNumber;
	return uniqueNumber;
}

function convertedSlickBreakpointSize(breakpointSize, breakpointSizeArray) {
    var sortedBreakpointSizes = breakpointSizeArray.sort(function(a, b){return a-b});
    var convertedBreakpointSize;
    
    if (breakpointSize == 0) {
        var mobileMaxSize = sortedBreakpointSizes[sortedBreakpointSizes.indexOf(0) + 1];
        if (mobileMaxSize) {
            convertedBreakpointSize = mobileMaxSize - 1;
        } else {
            convertedBreakpointSize = 767;
        }
    } else if (breakpointSize == 768) {
        var desktopMaxSize = sortedBreakpointSizes[sortedBreakpointSizes.indexOf(768) + 1];
        if (desktopMaxSize) {
            convertedBreakpointSize = desktopMaxSize - 1;
        } else {
            convertedBreakpointSize = 9999;
        }
    } else {
        var breakpointMaxSize = sortedBreakpointSizes[sortedBreakpointSizes.indexOf(breakpointSize) + 1];
        if (breakpointMaxSize) {
            convertedBreakpointSize = breakpointMaxSize - 1;
        } else {
            convertedBreakpointSize = 9999;
        }
    }

    return convertedBreakpointSize;
}

function initSlick(elementClass, settings) {
    if (!elementClass) {
        return;
    }

    var sliderSettings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    if (settings) {
        if ('loop' in settings) {
            sliderSettings.infinite = settings.loop;
        }
        if ('dots' in settings) {
            sliderSettings.dots = settings.dots;
        }
        if ('arrows' in settings) {
            sliderSettings.arrows = settings.arrows;
        }
        if ('slidesToShow' in settings) {
            sliderSettings.slidesToShow = settings.slidesToShow;
        }
        if ('slidesToScroll' in settings) {
            sliderSettings.slidesToScroll = settings.slidesToScroll;
        }
        if ('autoplay' in settings) {
            sliderSettings.autoplay = true;
            sliderSettings.autoplaySpeed = settings.autoplay;
        }
        if ('breakpoints' in settings) {
            sliderSettings.responsive = [];
            var breakpointSizeArray = Object.keys(settings.breakpoints).map(function (x) { 
                return parseInt(x); // Extra precaution of sanitizing data for use in mathematical equations
            });
            for (var breakpointSize in settings.breakpoints) {
                breakpointSize = parseInt(breakpointSize);
                var convertedBreakpointSize = convertedSlickBreakpointSize(breakpointSize, breakpointSizeArray);
                var breakpointSettingsObject = {
                    breakpoint: convertedBreakpointSize,
                    settings: {}
                };
                var breakpointSizeSettings = settings.breakpoints[breakpointSize];
                for (var sizeSetting in breakpointSizeSettings) {
                    if ('loop' == sizeSetting) {
                        breakpointSettingsObject.settings.infinite = breakpointSizeSettings[sizeSetting];
                    }
                    if ('dots' == sizeSetting) {
                        breakpointSettingsObject.settings.dots = breakpointSizeSettings[sizeSetting];
                    }
                    if ('arrows' == sizeSetting) {
                        breakpointSettingsObject.settings.arrows = breakpointSizeSettings[sizeSetting];
                    }
                    if ('slidesToShow' == sizeSetting) {
                        if (convertedBreakpointSize == 9999) {
                            sliderSettings.slidesToShow = breakpointSizeSettings[sizeSetting];
                        } else {
                            breakpointSettingsObject.settings.slidesToShow = breakpointSizeSettings[sizeSetting];
                        }
                    }
                    if ('slidesToScroll' == sizeSetting) {
                        if (convertedBreakpointSize == 9999) {
                            sliderSettings.slidesToScroll = breakpointSizeSettings[sizeSetting];
                        } else {
                            breakpointSettingsObject.settings.slidesToScroll = breakpointSizeSettings[sizeSetting];
                        }
                    }
                    if ('autoplay' == sizeSetting) {
                        if (convertedBreakpointSize == 9999) {
                            sliderSettings.autoplay = true;
                            sliderSettings.autoplaySpeed = breakpointSizeSettings[sizeSetting].autoplay;
                        } else {
                            breakpointSettingsObject.settings.autoplay = true;
                            breakpointSettingsObject.settings.autoplaySpeed = breakpointSizeSettings[sizeSetting];
                        }
                    }
                }
                //if (convertedBreakpointSize != 9999) {
                    sliderSettings.responsive.push(breakpointSettingsObject);
                //}
            }
        }

        $(elementClass).slick(sliderSettings);
    } else {
        $(elementClass).slick();
    }
}

function initSwiper(elementClass, settings, classesForSettings) {
    if (!elementClass) {
        return;
    }

    var sliderSettings = {
        loop: true,
        keyboard: true,
        spaceBetween: 20,
        a11y: {
            enabled: true,
            firstSlideMessage: 'This is the first slide',
            lastSlideMessage: 'This is the last slide'
        }
    };
    if (settings) {
        if ('loop' in settings) {
            sliderSettings.loop = settings.loop;
        }
        if ('pagination' in settings || 'dots' in settings) {
            if (settings.pagination) {
                if ('el' in settings.pagination && settings.pagination.el.length > 0) {
                    sliderSettings.pagination = settings.pagination;
                }
            } else {
                if (settings.dots) {
                    sliderSettings.pagination = {
                        el: '.swiper-pagination',
                        clickable: true
                    }
                }
            }
        } 
        if ('navigation' in settings || 'arrows' in settings) {
            if (settings.navigation) {
                if ('nextEl' in settings.navigation && settings.pagination.nextEl.length > 0) {
                    sliderSettings.navigation = settings.navigation;
                }
            } else {
                if (settings.arrows) {
                    sliderSettings.navigation = {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }
        if ('slidesToShow' in settings) {
            sliderSettings.slidesPerView = settings.slidesToShow;
        }
        if ('autoplay' in settings) {
            sliderSettings.autoplay = {
                delay: settings.autoplay,
                disableOnInteraction: true
            };
        }
        if ('breakpoints' in settings) {
            sliderSettings.breakpoints = {};
            for (var breakpointSize in settings.breakpoints) {
                breakpointSize = parseInt(breakpointSize);
                sliderSettings.breakpoints[breakpointSize] = {};
                var breakpointSizeSettings = settings.breakpoints[breakpointSize];
                for (var sizeSetting in breakpointSizeSettings) {
                    if ('loop' == sizeSetting) {
                        sliderSettings.loop = breakpointSizeSettings[sizeSetting];
                    }
                    if ('dots' == sizeSetting && breakpointSizeSettings[sizeSetting] == true) {
                        sliderSettings.breakpoints[breakpointSize].pagination = {
                            enabled: breakpointSizeSettings[sizeSetting],
                            el: '.swiper-pagination',
                            clickable: true
                        };
                        if (classesForSettings && classesForSettings.pagination) {
                            sliderSettings.breakpoints[breakpointSize].pagination.el = classesForSettings.pagination;
                        }  
                    }
                    if ('arrows' == sizeSetting && breakpointSizeSettings[sizeSetting] == true) {
                        sliderSettings.breakpoints[breakpointSize].navigation = {
                            enabled: breakpointSizeSettings[sizeSetting],
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        };
                        if (classesForSettings && classesForSettings.next && classesForSettings.prev) {
                            sliderSettings.breakpoints[breakpointSize].navigation.nextEl = classesForSettings.next;
                            sliderSettings.breakpoints[breakpointSize].navigation.prevEl = classesForSettings.prev;
                        }
                    }
                    if ('slidesToShow' == sizeSetting) {
                        sliderSettings.breakpoints[breakpointSize].slidesPerView = breakpointSizeSettings.slidesToShow;
                    }
                    if ('slidesToScroll' == sizeSetting) {
                        sliderSettings.breakpoints[breakpointSize].slidesPerGroup = breakpointSizeSettings.slidesToScroll;
                    }
                    if ('autoplay' in breakpointSizeSettings) {
                        sliderSettings.autoplay = {
                            delay: breakpointSizeSettings.autoplay,
                            disableOnInteraction: true
                        };
                    } else {
                        sliderSettings.breakpoints[breakpointSize].autoplay = false;
                    }
                }
            }
        }
    }
    
    return new Swiper (elementClass, sliderSettings);
}

function formatSlickHTML($element, settings, isEinstein) {
    var originalSlideElements = $element.children().toArray();

    // Einstein CQuotient scripts are mixed in with slides at time of slider initialization. Rework the HTML structure.
    if (originalSlideElements.length > 0 && isEinstein) {
        var modifiedSlideElements = [];
        var cqScripts = [];

        $.each(originalSlideElements, function(index, element) {
            if (element.tagName.toUpperCase() === 'SCRIPT') {
                cqScripts.push(element);
            } else {
                if (element.tagName.toUpperCase() === 'LI') {
                    modifiedSlideElements.push(element);
                }  
            }
        });

        if (cqScripts.length > 0) {
            $element.closest('div.slider-clip').append(cqScripts);
        }

        $element.html('');
        $element.append(modifiedSlideElements);
    }
    return;
}

function formatSwiperHTML($element, settings, isEinstein) {
    var originalSlideElements = $element.children().toArray();
    var uniqueSlider, modifiedSlideElements, cqScripts, additionalSlideCount;
    var duplicatedSlideElements = [];
    var hasSwiperHTML = $element.hasClass('swiper-full-wrapper') && $element.find('.swiper').length > 0;
    var swiperClassesForSettings = {};

    if ($element.attr('id') && $element.attr('id').indexOf('slider-index') > -1) {
        uniqueSlider = $element.attr('id');
    } else {
        uniqueSlider = 'slider-index-' + getUniqueNumber().toString();
    }

    var $swiperParent = $('<div />', { 'class': 'swiper', 'id': uniqueSlider }),
        $swiperPagination = $('<div />', { 'class': 'swiper-pagination swiper-pagination-' + uniqueSlider }),
        $swiperNavigation = $('<div />', { 'class': 'swiper-navigation-buttons-container' }),
        $swiperPrevArrow = $('<div />', { 'class': 'swiper-button-prev swiper-button-prev-' + uniqueSlider }),
        $swiperNextArrow = $('<div />', { 'class': 'swiper-button-next swiper-button-next-' + uniqueSlider }),
        $swiperWrapper = $('<div />', { 'class': 'swiper-wrapper' });

    if (settings) {
        if ('breakpoints' in settings) {
            for (var breakpointSize in settings.breakpoints) {
                var breakpointSizeSettings = settings.breakpoints[breakpointSize];
                var navDisplayClass;
                if ('arrows' in breakpointSizeSettings) {
                    if (breakpointSizeSettings.arrows) {
                        navDisplayClass = breakpointSize == '0' ? 'show-mobile-nav-buttons' : 'show-desktop-nav-buttons';
                        $swiperNavigation.addClass(navDisplayClass);
                    }
                }
            }
        }
    }    

    if (!hasSwiperHTML && originalSlideElements.length > 0) {
        $element.addClass('swiper-full-wrapper');

        // Einstein CQuotient scripts are mixed in with slides at time of slider initialization. Rework the HTML structure.
        if (isEinstein) {
            modifiedSlideElements = [];
            cqScripts = [];
            $.each(originalSlideElements, function(index, element) {
                if (element.tagName.toUpperCase() === 'SCRIPT') {
                    cqScripts.push(element);
                } else {
                    var formattedSlide;
                    if (element.tagName.toUpperCase() === 'DIV') {
                        formattedSlide = $(element).addClass('swiper-slide');
                    } else {
                        formattedSlide = $('<div />', { 'class': 'swiper-slide' }).html($(element).html());
                    }
                    modifiedSlideElements.push(formattedSlide);
                }
            });
        } else {
            // Proceed to modify regular slide elements (non-Einstein)
            modifiedSlideElements = originalSlideElements.map(function(element, index) {
                if (element.tagName.toUpperCase() === 'DIV') {
                    return $(element).addClass('swiper-slide')[0];
                } else {
                    return $('<div />', { 'class': 'swiper-slide' }).html($(element).html())[0];
                }
            });
        }
        $swiperWrapper.append(modifiedSlideElements);
        $swiperNavigation.append($swiperPrevArrow).append($swiperNextArrow);
        $swiperParent.append($swiperWrapper).append($swiperPagination);

        var arrowsPosition = settings && 'arrowsPosition' in settings ? settings.arrowsPosition : null;
        if (arrowsPosition && arrowsPosition == 'inside') {
            $swiperParent.append($swiperNavigation).addClass('swiper-inside-navigation');
        }
        $element.html($swiperParent);
        if (!arrowsPosition || arrowsPosition == 'outside') {
            $element.prepend($swiperNavigation);
        }
        if (isEinstein && cqScripts.length > 0) {
            $element.append(cqScripts);
        }

        swiperClassesForSettings.parent = '#' + uniqueSlider;
        swiperClassesForSettings.pagination = '.swiper-pagination-' + uniqueSlider;
        swiperClassesForSettings.prev = '.swiper-button-prev-' + uniqueSlider;
        swiperClassesForSettings.next = '.swiper-button-next-' + uniqueSlider;
    }
    return swiperClassesForSettings;
}

function init (elementClass, settings, isEinstein) {
    var selector = elementClass;
    if (!elementClass || ($(elementClass).length == 0 && elementClass.indexOf(':has') < 0)) {
        return;
    }
    if (SitePreferences.CONTENT_SLOT_SLIDER == 'swiperJS') { 
        $(elementClass).each(function(n, elm) {
            var $element = $(elm);
            var hasSwiperHTML = $element.find('.swiper-initialized').length > 0;

            if (!hasSwiperHTML) {
                var classesForSettings = formatSwiperHTML($element, settings, isEinstein);
                if (!settings) {
                    settings = {};
                }
                if (classesForSettings) {
                    // Override class for ID if we have it
                    selector = classesForSettings.parent;
                    if (settings.dots) {
                        settings.pagination = {
                            el: classesForSettings.pagination,
                            clickable: true
                        };
                    }
                    if (settings.arrows) {
                        settings.navigation = {
                            nextEl: classesForSettings.next,
                            prevEl: classesForSettings.prev
                        }   
                    }
                }
                return initSwiper(selector, settings, classesForSettings);
            }
        });
    } else {
        $(elementClass).each(function(n, elm) {
            var $element = $(elm);
            formatSlickHTML($element, settings, isEinstein);
            initSlick(elementClass, settings);
        });
    }
}

function initializeCategorySliders(elementClass) {
	var $categorySliders = $(elementClass);
	if ($categorySliders.length > 0) {
		$categorySliders.each(function(index, element) {
			var $element = $(element);
            var categorySliderOptions = {
                breakpoints: {}
            };
            var uniqueSlider = 'category-slider-index-' + getUniqueNumber().toString();
            $element.addClass(uniqueSlider);

			// Desktop slot config options
			var categorySliderDesktopOptions = $element.attr('slider-desktop-options');
			if (categorySliderDesktopOptions) {
				categorySliderDesktopOptions = JSON.parse(categorySliderDesktopOptions);
                categorySliderOptions.breakpoints[categorySliderDesktopOptions.breakpointSize] = categorySliderDesktopOptions;
			}
			// Mobile slot config options
			var categorySliderMobileOptions = $element.attr('slider-mobile-options');
			if (categorySliderMobileOptions) {
				categorySliderMobileOptions = JSON.parse(categorySliderMobileOptions);
                categorySliderOptions.breakpoints[categorySliderMobileOptions.breakpointSize] = categorySliderMobileOptions;
			}

            init('.' + uniqueSlider, categorySliderOptions);
		});
	}
}

function initializeRecommendationSlider() {
	var attemptNumber = 0;
	var timer = setInterval(recommendationSliderIntervalHandler, 500);
	function recommendationSliderIntervalHandler() {
        var sliderInitializedClass = SitePreferences.CONTENT_SLOT_SLIDER == 'swiperJS' ? '.swiper-initialized' : '.slick-initialized';
		var recommendationSliderInitialized = $('.product-recommendations .slider-products-primary .slides').find(sliderInitializedClass).length > 0;
		if (pageContext.site == SiteConstants.SiteIds.BootBarnUS) {
			recommendationSliderInitialized = $('.recomm-prod-anchor-slot .product-recommendations .slider-products-primary .slides').find(sliderInitializedClass).length > 0;
		}

		attemptNumber++;
		if(recommendationSliderInitialized || attemptNumber > 20) {
			$(document).trigger('EinsteinProductRecommendations.Done', $('#product-recommendations'));
            if (SitePreferences.CONTENT_SLOT_SLIDER == 'imgViewer2Slick') { 
			    $(document).trigger('SlickSliderAccessibilityMods');
            }
			clearInterval(timer);
			return;
		}
		if (pageContext.site == SiteConstants.SiteIds.BootBarnUS) {
			if ($('.no-hits-banner').length > 0) {
				$('.no-hits-banner .slider-products-primary').trigger('init-slider');
			} else {
				$('.recomm-prod-anchor-slot .product-recommendations .slider-products-primary').trigger('init-slider');
			}
		} else {
			$('.product-recommendations .slider-products-primary').trigger('init-slider');
		}
	}
}