'use strict';

exports.initialize = initialize;

var ImageConstants;
var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var carouselVideo = require('./../CarouselVideo');

var singleImageSlickOptions;
var productThumbnailSlickOptions;

var html = [];
var data = [];
var loaded = [];
var options;

var $singleImageSlides;
var $thumbnailImageContainer;
var $thumbnailImageSlides;

function initialize(params) {
	if (params) {
    	options = params;
	}
    var $pdpMain = $('#pdpMain');

	if (pageContext.site === SiteConstants.SiteIds.CountryOutfittersUS) {
		ImageConstants = require('../../../../../app_storefront_countryoutfitters/cartridge/js/ImageConstants');
	} else if (pageContext.isstoresite) {
		ImageConstants = require('../../../../../bootbarn_rsp/cartridge/js/ImageConstants');
	} else { 
		ImageConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ImageConstants');
	}
	singleImageSlickOptions = ImageConstants.SlickSlider.Options.Single;
	productThumbnailSlickOptions = ImageConstants.SlickSlider.Options.Thumbnail;

    $singleImageSlides = $('.slider-single-image .slides');
    $thumbnailImageContainer = $('.product-thumbnails-container');
	$thumbnailImageSlides = $thumbnailImageContainer.find('.slides');

    $pdpMain.off('click.Variant.Leaflet.Zoom.In').on('click.Variant.Leaflet.Zoom.In', '.custom.leaflet-control-zoom-in', function (e) {
		var $slides = $('.slider-single-image .slides');
		var slick = $slides.slick('getSlick');
		$slides.find('[data-slick-index="' + slick.currentSlide + '"] img.high-resolution').imgViewer2('getMap').zoomIn();
    });
    
    $pdpMain.off('click.Variant.Leaflet.Zoom.Out').on('click.Variant.Leaflet.Zoom.Out', '.custom.leaflet-control-zoom-out', function (e) {
		var $slides = $('.slider-single-image .slides');
		var slick = $slides.slick('getSlick');
		$slides.find('[data-slick-index="' + slick.currentSlide + '"] img.high-resolution').imgViewer2('getMap').zoomOut();
    });
	
	if (pageContext.site === SiteConstants.SiteIds.SheplersUS || pageContext.site === SiteConstants.SiteIds.BootBarnUS || pageContext.options.StoreUIVersion == '2024Redesign') {
		$(window).off('resize.PDP.Slider.Primary').on('resize.PDP.Slider.Primary', function (event) {
			if (data['mapResizeTimer']) {
				clearTimeout(data['mapResizeTimer']);
			}
			data['mapResizeTimer'] = setTimeout(mapResizeTimerHandler, 250);
		});
	}
	
	$singleImageSlides.on('init', function(event, slick) {
		// if (pageContext.site === SiteConstants.SiteIds.SheplersUS || pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
			var $initialSlide = $singleImageSlides.find('.slick-slide.slick-active');
			data['singleImageSlidesInitialSlide'] = $initialSlide.attr('data-slick-index');
			data['singleImageSlidesCurrentSlide'] = data['singleImageSlidesInitialSlide'];

			initializeImageViewerOnSlide($initialSlide);
			// setTimeout(function(){resizeSliderToMatchSlide($initialSlide);}, 250);
		// }
	});
	
	$singleImageSlides.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		if (nextSlide != data['singleImageSlidesCurrentSlide']) {
			$thumbnailImageSlides.find('.slick-current').removeClass('slick-current');
			$thumbnailImageSlides.find('.slide[data-slick-index="' + nextSlide + '"]').addClass('slick-current');
			setThumbnailPageBySlide(nextSlide + 1);

			// var $currentSlide = $singleImageSlides.find('[data-slick-index="' + currentSlide + '"]');
			// removeImageViewerOnSlide($currentSlide);

			var $nextSlide = $singleImageSlides.find('[data-slick-index="' + nextSlide + '"]');
			// turn off zoom on video slide
			if (!$nextSlide.hasClass('carousel-video-slide')) {
				initializeImageViewerOnSlide($nextSlide);
			}
			// initializeSlidesToSide($nextSlide);

			data['singleImageSlidesCurrentSlide'] = nextSlide;
		}
	});

    var singleImageSlickOptions = ImageConstants.SlickSlider.Options.Single;
    if (pageContext.site === SiteConstants.SiteIds.SheplersUS || pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
    	singleImageSlickOptions.dots = true;
		singleImageSlickOptions.responsive[0].settings.arrows = false;
	}



console.log('$singleImageSlides', $singleImageSlides);

	$singleImageSlides.not('.slick-initialized').slick(singleImageSlickOptions);

    var productThumbnailSlickOptions = ImageConstants.SlickSlider.Options.Thumbnail;
    if (pageContext.site === SiteConstants.SiteIds.SheplersUS || pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
		productThumbnailSlickOptions.vertical = true;
	}
	$thumbnailImageSlides.not('.slick-initialized').slick(productThumbnailSlickOptions);

	data['thumbnailsCurrentPage'] = 1;
	data['thumbnailsMaximumSlide'] = $thumbnailImageSlides.find('.slide').length;
	data['thumbnailsMaximumPage'] = Math.ceil(data['thumbnailsMaximumSlide'] / productThumbnailSlickOptions.slidesToShow);

	if (data['thumbnailsMaximumPage'] <= 1) {
		$thumbnailImageContainer.find('.product-thumbnails-arrow.next').addClass('disable');
	} else {
		$thumbnailImageContainer.find('.product-thumbnails-arrow.next').removeClass('disable');
	}

	$('.product-thumbnails-container .product-thumbnails-arrow').on('click', function(event) {
		event.preventDefault();

		var $this = $(this);
		var pageDelta = 0;
		if ($this.hasClass('next')) {
			pageDelta = 1;
		} else if ($this.hasClass('previous')) {
			pageDelta = -1;
		}

		setThumbnailPage(data['thumbnailsCurrentPage'] + pageDelta);
	});

	$('.product-thumbnails .slide a').on('click', function(event) {
		event.preventDefault();

		var $this = $(this);
		var $currentSlide = $this.closest('.slick-track').find('.slick-current');
		var currentSlide = $currentSlide.data('slick-index');
		var $nextSlide = $this.closest('.slide');
		var nextSlide = $nextSlide.data('slick-index');

		$thumbnailImageSlides.find('.slick-current').removeClass('slick-current');
		$nextSlide.addClass('slick-current');
		$singleImageSlides.slick('slickGoTo', nextSlide);
	});

	carouselVideo.init();
}

function initializeSlidesToSide($slide) {
	var $leftSlide = $slide.prev();
	if ($leftSlide.length > 0) {
		initializeImageViewerOnSlide($leftSlide);
	}

	var $rightSlide = $slide.next();
	if ($rightSlide.length > 0) {
		initializeImageViewerOnSlide($rightSlide);
	}
}

function setThumbnailPageBySlide(selectedSlide) {
	if (selectedSlide >= 1 && selectedSlide <= data['thumbnailsMaximumSlide']) {
		setThumbnailPage(Math.ceil(selectedSlide / ImageConstants.SlickSlider.Options.Thumbnail.slidesToShow));
	}
}

function setThumbnailPage(selectedPage) {
	if (selectedPage >= 1 && selectedPage <= data['thumbnailsMaximumPage']) {
		if (pageContext.site === SiteConstants.SiteIds.CountryOutfittersUS) {
			var offsetX = $thumbnailImageSlides.find('.slick-track').offset().left - $thumbnailImageSlides.find('.slick-track .slide[data-slick-index="' + (selectedPage * ImageConstants.SlickSlider.Options.Thumbnail.slidesToShow - ImageConstants.SlickSlider.Options.Thumbnail.slidesToShow) + '"]').offset().left + 'px';
			$thumbnailImageSlides.find('.slick-track').css('transform', 'translate3d(' + offsetX + ', 0, 0)');
		} else {
			var offsetY = $thumbnailImageSlides.find('.slick-track').offset().top - $thumbnailImageSlides.find('.slick-track .slide[data-slick-index="' + (selectedPage * ImageConstants.SlickSlider.Options.Thumbnail.slidesToShow - ImageConstants.SlickSlider.Options.Thumbnail.slidesToShow) + '"]').offset().top + 'px';
			$thumbnailImageSlides.find('.slick-track').css('transform', 'translate3d(0, ' + offsetY + ', 0)');
		}

		if (selectedPage > 1) {
			$thumbnailImageContainer.find('.product-thumbnails-arrow.previous').removeClass('disable');
		} else {
			$thumbnailImageContainer.find('.product-thumbnails-arrow.previous').addClass('disable');
		}

		if (selectedPage < data['thumbnailsMaximumPage']) {
			$thumbnailImageContainer.find('.product-thumbnails-arrow.next').removeClass('disable');
		} else {
			$thumbnailImageContainer.find('.product-thumbnails-arrow.next').addClass('disable');
		}

		data['thumbnailsCurrentPage'] = selectedPage;
	}
}

function removeImageViewerOnSlide($slide) {
	if ($slide.find('img.high-resolution').length > 0) {
		$slide.find('img').removeClass('high-resolution').imgViewer2('destroy');
	}
}

function initializeImageViewerOnSlide($slideToInitialize) {
	if ($slideToInitialize.find('img.high-resolution').length == 0) {
		$slideToInitialize.find('img').attr('src', $slideToInitialize.find('a.zoomedImage').attr('href')).addClass('high-resolution').imgViewer2({
			zoomMax: 4,
			dragable: false,
			onReady: function() {
				var imgViewer2 = this;
				var map = this.map;

				imgViewer2.setZoom(1);
				if (map && map.tap) {
					map.tap.disable();
				}
				if (map && map.dragging) {
					map.dragging.disable();
				}

				$(map.getContainer()).off('wheel').on('wheel', function(event) {
					var wheelScrollAnimationTargetDelta = 0;

					if (data['wheelScrollAnimation']) {
						wheelScrollAnimationTargetDelta = data['wheelScrollAnimationTarget'] - $(window).scrollTop();
						data['wheelScrollAnimation'].stop();
					}

					var zoomLevel = imgViewer2.getZoom();
					var zoomMaximum = $slideToInitialize.find('img.high-resolution').imgViewer2('option', 'zoomMax');
					if ((zoomLevel == 1 && event.originalEvent.deltaY > 0) || (zoomLevel > (zoomMaximum - 0.5)) && event.originalEvent.deltaY < 0) {
						data['wheelScrollAnimationTarget'] = $(window).scrollTop() + event.originalEvent.deltaY + wheelScrollAnimationTargetDelta;
						data['wheelScrollAnimation'] = $('html, body').animate({
							scrollTop: data['wheelScrollAnimationTarget']
						}, {
							duration: 'fast',
							complete: function() { 
								data['wheelScrollAnimation'] = null;
							}
						});
					}
				});

				map.on('zoom', function(event) {
					var zoomLevel = imgViewer2.getZoom();

					if (zoomLevel > 1.05) {
						$slideToInitialize.find('img.high-resolution').imgViewer2('option', 'dragable', true);

						if (map && map.tap) {
							map.tap.enable();
						}
						if (map && map.dragging) {
							map.dragging.enable();
						}
						$('.slider-single-image .slides').slick('slickSetOption', 'draggable', false, true);
						$('.slider-single-image .slides').slick('slickSetOption', 'swipe', false, true);
						$('.slider-single-image .slides').slick('slickSetOption', 'touchMove', false, true);
					} else {
						$slideToInitialize.find('img.high-resolution').imgViewer2('option', 'dragable', false);

						if (map && map.tap) {
							map.tap.disable();
						}
						if (map && map.dragging) {
							map.dragging.disable();
						}
						$('.slider-single-image .slides').slick('slickSetOption', 'draggable', true, true);
						$('.slider-single-image .slides').slick('slickSetOption', 'swipe', true, true);
						$('.slider-single-image .slides').slick('slickSetOption', 'touchMove', true, true);
					}
				});
			}
		});
	} else {
		var slideImageMap = $slideToInitialize.find('img.high-resolution').imgViewer2('getMap');
		if (slideImageMap) {
			slideImageMap.invalidateSize();
		}
	}
}

function mapResizeTimerHandler() {
	if (data['singleImageSlidesInitialSlide'] != undefined) {
		var $initialSlide = $('.slider-single-image .slides').find('[data-slick-index="' + data['singleImageSlidesInitialSlide'] + '"]');
		resizeSliderToMatchSlide($initialSlide);

        var slick = $singleImageSlides.slick('getSlick');
        var $currentSlide = $singleImageSlides.find('[data-slick-index="' + slick.currentSlide + '"]');
		var currentSlideImageMap = $currentSlide.find('img.high-resolution').imgViewer2('getMap');
		if (currentSlideImageMap) {
			currentSlideImageMap.invalidateSize();
		}
	}
}

function resizeSliderToMatchSlide($slide) {
	var $slideTrack = $slide.closest('.slick-track');
	$slideTrack.css('height', 'auto');

	var $slideImage = $slide.find('.slide-image > img');
	var slideImageHeight = $slide.height();
	var slideImageWidth = $slide.width();
	var slideAspectRatio = slideImageWidth / slideImageHeight;

	$slideTrack.css('height', slideImageHeight);
	$slideTrack.find('.slick-slide').not($slide).each(function() {
		var $this = $(this);
		var $thisImage = $this.find('.slide-image > img');
		var thisImageAspectRatio = $thisImage.width() / $thisImage.height();

		if ($thisImage.height() > slideImageHeight && thisImageAspectRatio <= slideAspectRatio) {
			$this.addClass('constrain-height');
		}
	});
}