'use strict';

var util = require('../../util');
var shipping = require('./shipping');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    // select address from list
    $(document).on('change', '.address select[name$="_addressList"]', function () {
        var $form = $('.address');
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $form);
        shipping.updateShippingMethodList();
        // re-validate the form
        $form.validate().form();
    });
};
