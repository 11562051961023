'use strict';

exports.AddressTypes = {
	Customer: 'CustomerAddress',
	Store: 'StoreAddress'
}

exports.CountryCodes = {
	UnitedStates: 'US',
	Canada: 'CA'
}

exports.StateCodes = {
	NewYork: 'NY'
}

exports.KeyCodes = {
	Enter: 13,
	Tab: 9,
	ArrowLeft: 37,
	ArrowUp: 38,
	ArrowRight: 39,
	ArrowDown: 40
}

exports.Responsive = {
	Widths: {
		Mobile: 767,
		Tablet: 1024
	}
}

exports.SiteIds = {
	BootBarnUS: 'bootbarn_us',
	SheplersUS: 'sheplers_us',
	CountryOutfittersUS: 'country_outfitters_us',
	BootBarnRspUS: 'bootbarn_rsp_us',
	BootBarnB2BUS: 'bootbarn_b2b_us',
	Rangefinder: 'rangefinder'
}

exports.ProductRecommendationSystems = {
	Einstein: 'Einstein',
	Relevant: 'Relevant'
}