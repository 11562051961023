'use strict';

var ajax = require('../../ajax'),
	formPrepare = require('./formPrepare'),
	progress = require('../../progress'),
    tooltip = require('../../tooltip'),
	util = require('../../util'),
	TPromise = require('promise');

function initializeEvents() {
	$(document).on('update-payment-methods', function(e, callback) {
		if ($('.payment-method-option').length == 0) { return; }
		updatePaymentMethods().then(function (response) {
			if (!response) { return; }
			$('.payment-methods-section').replaceWith(response);
			if (callback) { callback(); }
		});
	});
}

/**
 * @description Make the AJAX request to set refresh payment methods
 * @param {Element} form The form element that present address object
 * @param {Element} Action name to apply to form
 * @returns {Promise}
 */
var updatePaymentMethods = function() {
    return TPromise.resolve($.ajax({
        type: 'GET',
        url: util.ajaxUrl(Urls.updatePaymentMethods),
		data: 'returnJSON=true'
    }));
};

exports.init = function() {
	initializeEvents();
};