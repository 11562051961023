'use strict';

var _ = require('lodash');
var loadingIndicator = require('../../components/LoadingIndicator');
var giftCardBalanceInquiryDialog = require('../../components/GiftCardBalanceInquiryDialog');
var GoogleTagManagerClient = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var ValidationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;

exports.initializeSelectors = initializeSelectors;
exports.initialize = initialize;

var giftCardPaymentContainerSelector;
var $giftCardPaymentContainer;
var $giftCardPaymentForm;
var $giftCardPaymentCardNumber;
var $giftCardPaymentPinNumber;
var $giftCardPaymentMessage;
var $giftCardPaymentErrorMessage;
var $giftCardPaymentApplied;
var $giftCardPaymentApplyButton;
var giftCardValidator;
var giftCardRecaptcha;
var options = {};
var loaded = {};

function renderRecaptcha() {
	if (loaded.Recaptcha && $giftCardPaymentApplyButton.length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForGiftCards && options.Recaptcha.GoogleSiteKey) {
		giftCardRecaptcha = grecaptcha.render('svs-gift-card-apply-button', {
	        sitekey: options.Recaptcha.GoogleSiteKey,
	        callback: submitGiftCard,
	        badge: 'bottomleft'
	    });
	}
}

function initializeSelectors() {
    giftCardPaymentContainerSelector = '[payment-method="SVS_Gift_Card"]';
    $giftCardPaymentContainer = $(giftCardPaymentContainerSelector);
    $giftCardPaymentForm = $giftCardPaymentContainer.find('form');
    $giftCardPaymentCardNumber = $giftCardPaymentContainer.find('input[name$="giftCard_number"]');
    $giftCardPaymentPinNumber = $giftCardPaymentContainer.find('input[name$="giftCard_pin"]');
    $giftCardPaymentMessage = $giftCardPaymentContainer.find('.messages');
    $giftCardPaymentErrorMessage = $giftCardPaymentContainer.find('span.error');
    $giftCardPaymentApplied = $giftCardPaymentContainer.find('.form-giftcards-applied');
    $giftCardPaymentApplyButton = $giftCardPaymentContainer.find('.apply');
}

function initialize(optionsParameter) {
    initializeSelectors();
	options = optionsParameter || {};
	
	renderRecaptcha();
	$(document).on('Recaptcha.Loaded', function(event) {
		if (!loaded.Recaptcha) {
			loaded.Recaptcha = true;
	        renderRecaptcha();
	    }
	});
	
    $(document).on('click', giftCardPaymentContainerSelector + ' .apply:not([loading])', function(event) {
		event.preventDefault();
		
        if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForGiftCards || !options.Recaptcha.GoogleSiteKey) {
            $(document).trigger('GiftCard.Apply');
        }
	});
	
	$(document).on('GiftCard.Apply', function(event) {		
        event.preventDefault();

        if (parseFloat($('[balance-due]').first().attr('balance-due')) <= 0) {
            $giftCardPaymentErrorMessage.html(Resources.RewardsCredit.NoBalanceDue).show();
            
            if (giftCardRecaptcha != undefined) {
				grecaptcha.reset(giftCardRecaptcha);
			}
        } else {
            $giftCardPaymentErrorMessage.html('').hide();
            giftCardValidator = $giftCardPaymentForm.validate();
			$.validator.addMethod('giftCardNumberLength', ValidationHelper.ValidateGiftCardNumberLength, Resources.Validation.Messages.GiftCard.Number.Length);
			$.validator.addMethod('giftCardNumberValues', ValidationHelper.ValidateGiftCardNumberValues, Resources.Validation.Messages.GiftCard.Number.Values);
            $giftCardPaymentCardNumber.rules('add', ValidationRules.GiftCard.Number);
            $giftCardPaymentPinNumber.rules('add', ValidationRules.GiftCard.Pin);
            giftCardValidator.form();

            if (Urls && Urls.GiftCard.Apply && giftCardValidator.valid()) {
                var giftCardRowContainerHtml = _.template($('#template-gift-card-row-container').html(), TemplateConstants.Lodash.Settings)();
                var $giftCardPaymentAppliedGiftCard = $(giftCardRowContainerHtml).appendTo($giftCardPaymentApplied);

                $giftCardPaymentApplyButton.loading(options.LoadingIndicator || {
                    IsImageShown: false,
                    Label: Resources.Loading.Processing
                });
				if (!options.LoadingIndicator) {
					$giftCardPaymentAppliedGiftCard.loading(options.LoadingIndicator || {
						Label: Resources.Loading.GiftCard.Applying
					});
				}

				var giftCardPaymentCardNumber = $giftCardPaymentCardNumber.val().match(/(\d)/g);
				if (giftCardPaymentCardNumber && giftCardPaymentCardNumber.length) {
					giftCardPaymentCardNumber = giftCardPaymentCardNumber.join('');
				}

                $.ajax({
                    method: 'POST',
                    url: Urls.GiftCard.Apply,
                    contentType: 'application/json',
                    data: JSON.stringify({
                        number: giftCardPaymentCardNumber,
                        pin: $giftCardPaymentPinNumber.val(),
                        amount: null
                    }),
                    dataType: 'json',
                    success: function (data) {
						if ($giftCardPaymentApplyButton.attr('loading')) {
                        	$giftCardPaymentApplyButton.loading('destroy');
						}
						if ($giftCardPaymentAppliedGiftCard.attr('loading')) {
                        	$giftCardPaymentAppliedGiftCard.loading('destroy');
						}
                        
                        if (data.success) {
                            $giftCardPaymentCardNumber.val('');
                            $giftCardPaymentPinNumber.val('');
                            
                            if (data.amountApplied && data.amountApplied.value > 0) {
                            	$giftCardPaymentApplied.find('[data-id="' + data.id + '"]').remove();                            	
                                $giftCardPaymentAppliedGiftCard.attr('data-id', data.id);

                                var giftCardRowContentHtml = _.template($('#template-gift-card-row-content').html(), TemplateConstants.Lodash.Settings)({
                                    cardNumber: data.number,
                                    amountApplied: data.amountApplied.displayValue
                                });

                                $giftCardPaymentAppliedGiftCard.html(giftCardRowContentHtml);
                            }
                            
                            if (data.message) {
                            	$giftCardPaymentErrorMessage.html(data.message).show();
                            }

                            $(document).trigger('Cart.Update');
                        } else {
                        	if (data.message == 'Invalid Message') {
                        		data.message = Resources.Error.Generic;	
                        	}
                        	
                            $giftCardPaymentAppliedGiftCard.remove();
                            $giftCardPaymentErrorMessage.html(data.message || Resources.Error.Generic).show();
                        }
                        
						if (giftCardRecaptcha != undefined) {
							grecaptcha.reset(giftCardRecaptcha);
						}

						var dataObject = {
							value: data.amountApplied.value,
							payment_type: 'Gift Card SVS'
						}
						GoogleTagManagerClient.PushDataLayerForDataLayerType('AddPaymentInfo', dataObject);
                    },
                    error: function() {
						$giftCardPaymentApplyButton.loading('destroy');
						$giftCardPaymentAppliedGiftCard.remove();
						$giftCardPaymentErrorMessage.html(Resources.Error.Generic).show();
						
						if (giftCardRecaptcha != undefined) {
							grecaptcha.reset(giftCardRecaptcha);
						}
					},
					complete: function(data, status, xhr) {
						if (data.status == 302 && data.responseText) {
							location.href = data.responseText;
						}
					}
                });
            } else {
            	if (giftCardRecaptcha != undefined) {
					grecaptcha.reset(giftCardRecaptcha);
				}	
            }
        }
    });

    $(document).off('mousedown.GiftCardPaymentPanel').on('mousedown.GiftCardPaymentPanel', function (event) {
        if (giftCardValidator && $(event.target).closest($giftCardPaymentContainer).length == 0) {
            giftCardValidator.resetForm();
            $giftCardPaymentErrorMessage.html('').hide();
        }
    });

    $(document).on('CartData.Update', function (event, data) {
    	$('[has-gift-cards]').attr('has-gift-cards', data.HasGiftCards || false);
	    $('[has-gift-cards-only]').attr('has-gift-cards-only', data.HasGiftCardsOnly || false);
	    
	    if (data.HasGiftCards && !data.AllowGiftCardPurchaseWithGiftCard) {
	    	$giftCardPaymentMessage.html(Resources.GiftCard.GiftCardPurchaseWithGiftCardNotAllowed);
	    	$giftCardPaymentContainer.attr('status', 'locked');
	    } else {
	    	$giftCardPaymentMessage.html('');
	    	$giftCardPaymentContainer.attr('status', 'active');
	    }
    });
    
    $(document).on('GiftCard.Clear', function () {
        $giftCardPaymentApplied.html('');
        $('.order-balance-due').html('');
    });

    $(document).on('click', giftCardPaymentContainerSelector + ' .remove:not([loading])', function (event) {
        event.preventDefault();

        var $giftCardRow = $(this).closest('.form-giftcard-applied');
        $giftCardRow.loading(options.LoadingIndicator || {
            Label: Resources.Loading.GiftCard.Removing
        });
        $giftCardPaymentErrorMessage.html('').hide();

        $.ajax({
            url : Urls.GiftCard.Remove,
            type : 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
                id: $giftCardRow.data('id')
            }),
            dataType : 'json',
            success : function(data) {
            	$giftCardRow.loading('destroy');
            	
                if (data.success) {
                    $giftCardRow.remove();

                    $(document).trigger('Cart.Update');
                } else {
                	$giftCardPaymentErrorMessage.html(data.error || Resources.Error.Generic).show();
                }
            },
            error: function() {
				$giftCardRow.loading('destroy');
				$giftCardPaymentErrorMessage.html(Resources.Error.Generic).show();
			}
        });
    });

    $(document).on('click.BalanceInquiry', '[payment-method="SVS_Gift_Card"] .balance-inquiry a', function (event) {
        event.preventDefault();
        giftCardBalanceInquiryDialog.initialize();
    });
    
    $(document).on('click', '.form-label.expander', function(){
    	$('form.svs-gift-card-information').slideToggle();
    	$giftCardPaymentContainer.toggleClass('svs-collapsed');
    });
}
