'use strict';

var ajax = require('../../ajax'),
	formPrepare = require('./formPrepare'),
	progress = require('../../progress'),
    tooltip = require('../../tooltip'),
		util = require('../../util'),
		cart = require('../cart'),
	shipping = require('./shipping'),
	TPromise = require('promise');

var shippingMethods;

function initializeEvents() {
	$(document).on('change', '#dwfrm_billingaddress_country', function(){
		renderOptions('#dwfrm_billingaddress_states_state', '#dwfrm_billingaddress_country');
		var $initialState = $('.bill-address-fields .initial-state');
		if ($initialState.length > 0 && $initialState.val() != '') {
			$('#dwfrm_billingaddress_states_state').val($initialState.val());
		}

		if (this.value !== 'US' && this.value !== 'CA') {
			$('#dwfrm_billingaddress_states_state').parents('.formfield').hide();
		} else {
			$('#dwfrm_billingaddress_states_state').parents('.formfield').show();
		}
	});
	$('#dwfrm_billingaddress_country').trigger('change');

	$(document).on('change', '#dwfrm_shippingaddress_country', function(){
		renderOptions('#dwfrm_shippingaddress_states_state', '#dwfrm_shippingaddress_country');
		var $initialState = $('.editaddress-shipping .initial-state');
		if ($initialState.length > 0 && $initialState.val() != '') {
			$('#dwfrm_shippingaddress_states_state').val($initialState.val());
		}

		if (this.value !== 'US' && this.value !== 'CA') {
			$('#dwfrm_shippingaddress_states_state').parents('.formfield').hide();
		} else {
			$('#dwfrm_shippingaddress_states_state').parents('.formfield').show();
		}

		if($(this).val().toUpperCase() == 'US'){
			$('#shipping-international-info').hide();
		} else {
			$('#shipping-international-info').show();
		}
	});
	$('#dwfrm_shippingaddress_country').trigger('change');

	$(document).on('change', 'form [id*="_postal"]', function () {
		var $this = $(this);
		$this.val($this.val().toUpperCase());
	});

	$('.address-section').addClass('active');

	$(document).on('click', '.shipping-same', function(){
		//$('.shipping-address-section').addClass('hidden');
		//$('#submitBilling').show();
		if($('.shipping-same').is(':checked')){
			$('input[name$="_useAsShippingAddress"]').prop('checked', true);
		} else {
			$('input[name$="_useAsShippingAddress"]').prop('checked', false);
		}

	});

	$(document).on('click', '.add-diff', function(){
		//$('.shipping-address-section').removeClass('hidden');
		//$('#submitBilling').hide();
		$('input[name$="_useAsShippingAddress"]').prop('checked', false);
	});

	/*BOOT-1186 explains behavior check before update*/
	$(document).on('click', '.billing-address-form .edit-address-det', function (e) {
		e.preventDefault();

		if($('.billing-address-type-selector input:checked').val() == 1){
			$('.billing-select-address').show();
		} else {
			$('.bill-address-fields').show();
			$('.billing-select-address').hide();
		}

		$('.billing-address-preview').hide();
		$('.billing-address-data.form-info').hide();
		$('.billing-address-form').show();
		$('#billingAddressForm').show();
		$('.shipping-address-form').data('hidden-editable', $('.shipping-address-data.form-info').html().length ? true : false).hide();
		$('.form-checkout.payment-section').removeClass('active').find('.shipping-payment-cont').addClass('hidden');
	});

	$(document).on('click', '.shipping-address-section .edit-address-det', function (e) {
		e.preventDefault();

		$('.shipping-address-form').data('hidden-editable', true).hide();
		$('.shipping-address-data').hide();
		$('.shipping-address-form').show();
		$('.billing-address-data.form-info').css('display', 'block');
		$('.form-checkout.payment-section').removeClass('active').find('.shipping-payment-cont').addClass('hidden');
	});

	$(document).on('click', '.billing-address-type-selector input', function(e, saveFormVals){
		var v = $(this).val();
		var $billingAddressFormFields = $('#billingAddressForm .bill-address-fields');
		if(v == 1){
			$('.shipping-same').prop('checked', $billingAddressFormFields.data('same-checked') || false);
			$('.billing-select-address').show();
			$('.bill-address-fields').addClass('collapsed');
			$('.billing-address-preview').show();
			$('#dwfrm_billing_addressList').html($('#dwfrm_billing_addressList option:nth(0)'));
			$('.billing-address-preview').hide();
			var $form = $('#billingAddressForm');
			var selectedAddress = $('#dwfrm_billing_addressList option:nth(0)').data('address');
			util.fillAddressFields(selectedAddress, $form);

		} else if(v == 2){
			$billingAddressFormFields.data('same-checked', $('.shipping-same').prop('checked'));
			$('.bill-address-fields .initial-state').val('');
			$('.billing-select-address').hide();
			$('.billing-address-preview').hide();
			$('.bill-address-fields').removeClass('collapsed');
			if(typeof saveFormVals == 'undefined' || !saveFormVals){
				$('#dwfrm_billing_addressList').val('');
				$billingAddressFormFields.find('input:text, input:password, input:file, textarea').not('input[name*="_email"]').val('');
				$billingAddressFormFields.find('select option:selected').removeAttr('selected');
				$billingAddressFormFields.find('input:checkbox, input:radio').removeAttr('checked');
			}
			$('.shipping-same').prop('checked', true);
			$('#dwfrm_billingaddress_country').trigger('change');
		}
		if($('.shipping-same').is(':checked')){
			$('input[name$="_useAsShippingAddress"]').prop('checked', true);
		} else {
			$('input[name$="_useAsShippingAddress"]').prop('checked', false);
		}
	});

	$(document).on('click', '.shipping-address-type-selector input', function(){
		var v = $(this).val();
		var $shippingAddressFormFields = $('#shippingAddressForm .editaddress-shipping');
		if(v == 1){
			$('.shipping-select-address').show();
			$('.editaddress-shipping').addClass('collapsed');
			$('.shipping-address-preview').html('').show();
		} else if(v == 2){
			$('.editaddress-shipping .initial-state').val('');
			$('.shipping-select-address').hide();
			$('.shipping-address-preview').hide();
			$('.editaddress-shipping').removeClass('collapsed');
			$('#dwfrm_singleshipping_addressList').val('');
			$shippingAddressFormFields.find('input:text, input:password, input:file, textarea').val('');
			$shippingAddressFormFields.find('select option:selected').removeAttr('selected');
			$shippingAddressFormFields.find('input:checkbox, input:radio').removeAttr('checked');
			$('#dwfrm_shippingaddress_country').trigger('change');

		}
	});
	/**
	 * @function
	 * @description Selects the first address from the list of addresses
	 */
	var $form = $('#billingAddressForm');
    // select address from list
    $(document).on('change', '#billingAddressForm select[name$="_addressList"]', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $form);

        $form.validate().form();
    });

    var shippingAddrForm = $('#shippingAddressForm');
    $(document).on('change', '#shippingAddressForm select[name$="_addressList"]', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, shippingAddrForm);

        shippingAddrForm.validate().form();
    });

    //address submission
    $(document).on('click', '#submitBilling', function(e){
    	e.preventDefault();
    	$('#submitBilling').attr('disabled', true);
    	progress.show();

    	// prevent submitting saved address w/out a selection
    	if ($('.billing-address-type-selector input[value=1]').prop('checked') && $('#dwfrm_billing_addressList').val() == '') {
    		$('#submitBilling').attr('disabled', false);
    		return;
    	}

    	if($('.shipping-same').is(':checked')){
			$('input[name$="_useAsShippingAddress"]').prop('checked', true);
		} else {
			$('input[name$="_useAsShippingAddress"]').prop('checked', false);
		}

    	var $form = $('#billingAddressForm'),
    		actionName = $('#selectOpcAddress').attr('name');
    	$form.validate().settings.ignore = [":not(.required)"];
    	$form.validate().form();

    	$('.custom-email-error').remove();

    	var isEmailValid = util.validateEmail($('#dwfrm_billingaddress_email').val());

    	if($form.valid() && isEmailValid){
    		//set billing address
    		setOPCAddress('#billingAddressForm', actionName).then(function (response) {
    			var data = JSON.parse(response);

    			if(data.status == 'success'){
    				updateSummary();
    				$('#billingAddressForm').hide();
	        		$('.billing-address-data').html('').append(buildDetailsBlock('#billingAddressForm')).show();

    				if($('.shipping-same').prop('checked')){
    					populateBillingToShipping('#shippingAddressForm', '#billingAddressForm');
        	    	}
    				$('.shipping-address-cont').removeClass('hidden');

    				var $shippingAddressForm = $('#shippingAddressForm');
    				if(SitePreferences.BYPASS_SHIPPING && $('.shipping-same').prop('checked')){
    					updateShippingMethodList('#billingAddressForm');
    					$('.shipping-address-data').html('').append(buildDetailsBlock('#shippingAddressForm')).show();
    					$shippingAddressForm.hide();
    					$('#shippingAddressForm').data('shipping-filled', true);
    					$('.payment-section').addClass('active');
    					$('.shipping-payment-cont').removeClass('hidden');
        	        	$('.shipping-address-section').addClass('active');
    				} else {
    					$('.shipping-address-section').addClass('active');
    					$('.shipping-address-section').show();
    					$('.shipping-address-form').show();
    				}

    				if($('#shippingAddressForm').data('shipping-filled') == true){
    					$('.form-checkout.payment-section').addClass('active').find('.shipping-payment-cont').removeClass('hidden');
    					$shippingAddressForm.hide();
    					$('.shipping-address-data').show();
    				}

    			} else if(data.status == 'shippingRestrictionsError') {
					openRestrictionsPopup(data);
    			} else {
    				console.log('submission error');
    			}

    			progress.hide();
    			$('#submitBilling').attr('disabled', false);
    	    }.bind(this));

    	} else {
    		progress.hide();
    		$('#submitBilling').attr('disabled', false);

    		if (!isEmailValid && $form.valid()) {
    			$('#dwfrm_billingaddress_email').parent().append('<span class="error custom-email-error">' + Resources.VALIDATE_EMAIL + '</span>');
    		}
    		$('.billing-address-type-selector input[value="2"]').trigger('click', [true]);
    	}
    });

    $(document).on('click', '#submitShipping', function(e){
    	var $form = $('#shippingAddressForm'),
			actionName = $(this).attr('name');
    	e.preventDefault();
    	$('#submitShipping').attr('disabled', true);
    	progress.show();

    	// prevent submitting saved address w/out a selection
    	if ($('.shipping-address-type-selector input[value=1]').prop('checked') && $('#dwfrm_singleshipping_addressList').val() == '') {
    		$('#submitShipping').attr('disabled', false);
    		return;
    	}

    	$form.validate().form();
    	if($form.valid()){
    		setOPCAddress('#shippingAddressForm', actionName).then(function (response) {
    			var data = JSON.parse(response);

    			if(data.status == 'success'){
    				updateShippingMethodList('#shippingAddressForm');
    				$('#shippingAddressForm').data('shipping-filled', true);
        			$('#shippingAddressForm').hide();
            		$('.shipping-address-data').html('').append(buildDetailsBlock('#shippingAddressForm')).show();
            		//before proceed to payment check if billing address is correct
            		var bilForm = $('#billingAddressForm');
            		bilForm.validate().settings.ignore = [":not(.required)"];

            		if(bilForm.valid()){
            			if($('#isShippingAddressSameAsBillingAddress').is(":checked")) { $('#isShippingAddressSameAsBillingAddress').click(); }
            			$('#submitBilling').click();
	            		$('.shipping-payment-cont').removeClass('hidden');
	            		$('.payment-section').addClass('active');
                	} else {
                		$('.shipping-payment-cont').addClass('hidden');
	            		$('.payment-section').removeClass('active');
	            		$('.billing-address-data.form-info').hide();
	            		var formBody = $('.billing-address-form');
	            		formBody.show();
	            		formBody.find('input.required.field.error').eq(0).focus();
                	}
        			updateSummary();
    			} else {
    				console.log('submission error');
    				switch (data.status) {
    					case 'shippingRestrictionsError': {
    						openRestrictionsPopup(data);
    					}
    				}
    			}
    			progress.hide();
    			$('#submitShipping').attr('disabled', false);
    	    }.bind(this));

    	} else {
    		progress.hide();
    		$('#submitShipping').attr('disabled', false);
    	}

    });

	$(document).on('click', '.toggle.top, .toggle.bottom', function(e){
    	e.preventDefault();
    	$('#internationalinfo').slideToggle();
	});


	var billingSavedAddrDropdown = $('#dwfrm_billing_addressList');
	//preset default address if exists
	if(billingSavedAddrDropdown.length > 0 && billingSavedAddrDropdown.val() !== ''){
		setTimeout(function(){
			//pass customer to second step in this case
			$('#billingAddressForm').hide();
    		$('.billing-address-data').html('').append(buildDetailsBlock('#billingAddressForm')).show();
    		$('.shipping-address-cont').removeClass('hidden');
    		$('.shipping-address-section').addClass('active');
		}, 13);

	}

	var shippingSavedAddrDropdown = $('#dwfrm_singleshipping_addressList');
	if(shippingSavedAddrDropdown.length > 0 && shippingSavedAddrDropdown.val() !== ''){
		setTimeout(function(){
			updateShippingMethodList('#shippingAddressForm');
			//pass customer to third step in this case
			$('.shipping-address-data').html('').append(buildDetailsBlock('#shippingAddressForm')).show();
	        $('#shippingAddressForm').hide();
			$('.payment-section').addClass('active');
			$('.shipping-payment-cont').removeClass('hidden');
			$('.shipping-address-cont').removeClass('hidden');
			$('#shippingAddressForm').data('shipping-filled', true);
	        updateSummary();
		}, 13);
	}

	//draw address preview on change
	$(document).on('change', '#dwfrm_billing_addressList', function(){
		var $this = $(this),
			upd = function(){
				var el = $('.billing-address-preview').html('');
				$this.val() && el.append(buildDetailsBlock('#billingAddressForm', true)).show();
			};

		setTimeout(upd, 13);

	});
	billingSavedAddrDropdown.trigger('change');

	//draw address preview on change
	$(document).on('change', '#dwfrm_singleshipping_addressList', function(){
		var $this = $(this),
			upd = function(){
				var el = $('.shipping-address-preview').html('');
				$this.val() && el.append(buildDetailsBlock('#shippingAddressForm', true)).show();
			};

		setTimeout(upd, 13);
	});

	if (shippingSavedAddrDropdown.length && shippingSavedAddrDropdown.parents('form').find('input[id*=_address1]').val() == '') {
		shippingSavedAddrDropdown.trigger('change');
	}

	setTimeout(function(){
		if($('#shippingAddressForm input[id*=_firstName]').val() != ''){
			var $form = $('#shippingAddressForm'),
			actionName = "dwfrm_shippingaddress_saveopcaddress";

			$form.validate().form();
			if($form.valid()){
				setOPCAddress('#shippingAddressForm', actionName).then(function (response) {
					var data = JSON.parse(response);

					if(data.status != 'success'){
						console.log('submission error');
					}
			    }.bind(this));
			}
		}
	}, 30);

}

/**
 * create address object from form
 * @param formSelector
 * @returns Address Object
 */
function createAddressObj(formSelector){
	var $form = $(formSelector);
	return {
		firstName: util.htmlEncode($form.find('input[name$="_firstName"]').val()),
		lastName: util.htmlEncode($form.find('input[name$="_lastName"]').val()),
		address1: util.htmlEncode($form.find('input[name$="_address1"]').val()),
		address2: util.htmlEncode($form.find('input[name$="_address2"]').val()),
		address3: util.htmlEncode($form.find('input[name$="_address3"]').val()),
		countryCode: util.htmlEncode($form.find('select[id$="_country"]').val()),
		stateCode: util.htmlEncode($form.find('select[id$="_state"]').val()),
		postalCode: util.htmlEncode($form.find('input[name$="_postal"]').val().toUpperCase()),
		city: util.htmlEncode($form.find('input[name$="_city"]').val()),
		phone: util.htmlEncode($form.find('input[name$="_phone"]').val()),
		email: util.htmlEncode($form.find('input[name$="_email"]').val())
	};
}

function populateBillingToShipping(shippingAddrFormSelector, billingAddrFormSelector){
	var shippingAddrForm = $(shippingAddrFormSelector);
	var billingAddrObj = createAddressObj(billingAddrFormSelector);
    shippingAddrForm.find('input[name$="_firstName"]').val(billingAddrObj.firstName).trigger('change');
    shippingAddrForm.find('input[name$="_lastName"]').val(billingAddrObj.lastName).trigger('change');
    shippingAddrForm.find('input[name$="_address1"]').val(billingAddrObj.address1).trigger('change');
    shippingAddrForm.find('input[name$="_address2"]').val(billingAddrObj.address2).trigger('change');
    shippingAddrForm.find('input[name$="_address3"]').val(billingAddrObj.address3).trigger('change');
    shippingAddrForm.find('input[name$="_postal"]').val(billingAddrObj.postalCode).trigger('change');
    shippingAddrForm.find('input[name$="_city"]').val(billingAddrObj.city).trigger('change');
    shippingAddrForm.find('select[id$=country]').val(billingAddrObj.countryCode).trigger('change');
    shippingAddrForm.find('select[id$=state]').val(billingAddrObj.stateCode).trigger('change');
    shippingAddrForm.find('input[name$="_phone"]').val(billingAddrObj.phone).trigger('change');
}

function buildDetailsBlock(formSelector, compact){
	var $form = $(formSelector),
    	data = createAddressObj($form),
    	htmlText = $('<div/>', {"class": "address-details-st"}),
    	isCompact = typeof compact != undefined && compact == true,
    	header = isCompact ? '' : $form.attr('id') == 'billingAddressForm'? '<h6>Billing Recipient</h6>' : '<h6>Ship To:</h6>',
    	editButton = isCompact ? '' : '<p class="text-right"><a href="#" class="edit-address-det" tabindex="0">Edit<span class="visually-hidden">&nbsp;Address Information</span></a></p>';

    if(!isCompact && $form.attr('id') == 'shippingAddressForm') {
    	$('.shipping-address-section').addClass('active');
    }
    for ( var _k in data ) {
    	if ( data[_k] != null && data[_k].search(/\^/) != -1 ) {
    		data[_k] = data[_k].replace(/\^/gi,"'");
    	}
    }
    if (data.email) {

    	var htmlcontent = header;
    		htmlcontent += '<div>'+data.firstName + ' ' + data.lastName + ' </div>';
    		htmlcontent += '<div>' + data.address1 + ' </div>';
    		if (data.address2 !== '') {
    			htmlcontent += '<div>' + data.address2 + ' </div>';
    		}
    		if (data.address3 !== '') {
    			htmlcontent += '<div>' + data.address3 + ' </div>';
    		}

    		htmlcontent += '<div>' + data.city;


    		if(data.stateCode != null && data.stateCode != '' && data.stateCode != undefined && data.stateCode.toUpperCase() != 'OTHER') {
    			htmlcontent += ' ' + data.stateCode;
    		}

    		htmlcontent += ' ' + data.postalCode;
    		htmlcontent += ' ' + data.countryCode + '</div>';
    		htmlcontent += '<div>' + data.phone + '</div>';
    		htmlcontent += '<div>'+data.email+'</div>' + editButton;

    	htmlText.html(htmlcontent);

    } else {
    	var htmlcontent = header;
    		htmlcontent += '<div>'+data.firstName + ' ' + data.lastName + ' </div>';
    		htmlcontent += '<div>' + data.address1 + ' </div>';
    		if (data.address2 !== '') {
    			htmlcontent += '<div>' + data.address2 + ' </div>';
    		}
    		if (data.address3 !== '') {
    			htmlcontent += '<div>' + data.address3 + ' </div>';
    		}
    		htmlcontent += '<div>' + data.city;

    		if(data.stateCode != null && data.stateCode != '' && data.stateCode != undefined && data.stateCode.toUpperCase() != 'OTHER') {
    			htmlcontent += ' ' + data.stateCode;
    		}
    		htmlcontent += ' ' + data.postalCode;
    		htmlcontent += ' ' + data.countryCode +  '</div>';
    		htmlcontent += '<div>' + data.phone + '</div>' + editButton;

    	htmlText.html(htmlcontent);
    }

    return htmlText;
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(formSelector, url, extraParams) {
    var $form = $(formSelector);

    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        address3: $form.find('input[name$="_address3"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

function updateShippingMethodList(sel) {
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
    var url = getShippingMethodURL(sel, Urls.shippingMethodsJSON);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            var shippingMethodListData = {
        		selectedPaymentMethod: $('#paymentSection input:checked').val()
        	}

            // load the shipping method form
            var smlUrl = getShippingMethodURL(sel, Urls.shippingMethodsList);

            $shippingMethodList.load(smlUrl, shippingMethodListData, function () {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
                	$('[name$="_shippingMethodID"]').prop('disabled', true);
                	selectShippingMethod($(this).val());
                });

                progress.hide();
                tooltip.init();
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                    $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                }
            });
        }
    });
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    cart.updateCart('dwfrm_cart_updateCart');

    return;
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }

    // attempt to set shipping method
    var url = getShippingMethodURL('#shippingAddressForm', Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {

            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                $('[name$="_shippingMethodID"]').prop('disabled', false);
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

        }
    });
}

/**
 * @description Make the AJAX request to set address data
 * @param {Element} form The form element that present address object
 * @param {Element} Action name to apply to form
 * @returns {Promise}
 */
var setOPCAddress = function (form, name) {
    var $form = $(form),
    	url = (form == '#billingAddressForm') ? Urls.setOpcAddress :  $form.attr('action');

    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(url),
        data: $form.serialize() + '&' + name + '=' + name
    }));
};

var openRestrictionsPopup = function (data) {
	var payload = JSON.parse(data.payload);
	var popup = require('../../popup');

	var list = $.map(payload.list, function (el) {
		return '<li>' + el.name + ':<br>' + el.message + '</li>';
	}).join('');

	popup.init({
		title: payload.title,
		content: payload.message + '<ul class="shipping-restrictions-popup__list">' + list + '</ul>',
	}).showPopup();
}

function renderOptions(optionParent, optionsStoreId) {
	var options = '';
	var optionsStoreOptions = $(optionsStoreId + ' option:selected').data('options');
	var statesList;

	if (optionsStoreOptions) {
		statesList = JSON.parse(optionsStoreOptions.replace(/\'/g, '\"'));

		statesList.forEach(function (state) {
			options += '<option class="select-option" label="' + state.label + '" value="' + state.code + '">' + state.label + '</option>';
		});

		$(optionParent).empty().append(options);

		if (statesList.length <= 1) {
			$(optionParent).removeClass('required');
			$(optionParent).parent().parent().parent().hide();
		} else {
			$(optionParent).addClass('required');
			$(optionParent).parent().parent().parent().show();
		}
	}
}

$(document).on('update-shipping', function() {
	if($('.shipping-methods-section').length == 0){ return; }

	setTimeout(function(){
		updateShippingMethodList('#shippingAddressForm');
	}, 100);
});

exports.init = function () {
	initializeEvents();
};

exports.updateSummary = updateSummary;