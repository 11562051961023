'use strict';

var addToCart = require('./product/addToCart');
var quantityStepper = require('../quantityStepper');
var loadingIndicator = require('../components/LoadingIndicator');
var _ = require('lodash');
var TemplateConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');

var html = {};
var options = {};
var data = {};
var templates = {};

var video;
var stream;
var barcodeDetector;
var barcodeDetectorInterval;
var barcodeScanInputAddProductTimer;
var barcodeScanInputFocusInterval;
var productSearchInputFocusInterval;

var $productFinder;
var $barcodeScanner;
var $barcodeScannerInputContainer;
var $barcodeScannerInput;
var $barcodeScannerErrorContainer;
var $barcodeScannerAddButton;
var $barcodeScannerAddButtonText;
var $barcodeScannerStartButton;
var $barcodeScannerStartButtonText;
var $barcodeScannerModal;
var $productSearch;
var $productSearchInputContainer;
var $productSearchInput;
var $productSearchErrorContainer;
var $productSearchSearchButton;
var $productSearchSearchButtonText;
var $productSearchResultsContainer;
var $productSearchResults;
var $productSearchResultsFooter;
var $productSearchResultsLoadMore;

function initialize(options) {
	$productFinder = $('.product-finder');
	$barcodeScanner = $productFinder.find('.section.scan');
	$barcodeScannerInputContainer = $barcodeScanner.find('.input');
	$barcodeScannerInput = $barcodeScannerInputContainer.find('input');
	$barcodeScannerErrorContainer = $barcodeScanner.find('.error');
	$barcodeScannerAddButton = $barcodeScanner.find('.action.add');
	$barcodeScannerAddButtonText = $barcodeScannerAddButton.find('span');
	$barcodeScannerStartButton = $barcodeScanner.find('.action.start');
	$barcodeScannerStartButtonText = $barcodeScannerStartButton.find('span');
	$barcodeScannerModal = $barcodeScanner.find('.modal');
	$productSearch = $productFinder.find('.section.search');
	$productSearchInputContainer = $productSearch.find('.input');
	$productSearchInput = $productSearchInputContainer.find('input');
	$productSearchErrorContainer = $productSearch.find('.error');
	$productSearchSearchButton = $productSearch.find('.action.search');
	$productSearchSearchButtonText = $productSearchSearchButton.find('span');
	$productSearchResultsContainer = $productSearch.find('.results-container');
	$productSearchResults = $productSearchResultsContainer.find('.results');
	$productSearchResultsFooter = $productSearchResultsContainer.find('footer');
	$productSearchResultsLoadMore = $productSearchResultsContainer.find('.load-more');

	if (('BarcodeDetector' in window)) {
		$barcodeScanner.addClass('barcode-detector');
	}

	data['ProductSearchPageSize'] = 5;

    templates['variations'] = _.template($('#template-variations').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelector'] = _.template($('#template-variations-selector').html(), TemplateConstants.Lodash.Settings);
	templates['variationSelectorAttributeGuide'] = _.template($('#template-variations-attribute-guide').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorDropdown'] = _.template($('#template-variations-selector-dropdown').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorDropdownOption'] = _.template($('#template-variations-selector-dropdown-option').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorDropdownOptionSelected'] = _.template($('#template-variations-selector-dropdown-option-selected').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorSwatch'] = _.template($('#template-variations-selector-swatch').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorSwatchOption'] = _.template($('#template-variations-selector-swatch-option').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorSwatchOptionUnselectable'] = _.template($('#template-variations-selector-swatch-option-unselectable').html(), TemplateConstants.Lodash.Settings);

	// $(document).off('keyup.BarcodeScannerInput').on('keyup.BarcodeScannerInput', '.input', function(event) {
	// 	var $this = $(this);

	// 	$barcodeScannerErrorContainer.html('');
	// 	if (barcodeScanInputAddProductTimer) {
	// 		clearTimeout(barcodeScanInputAddProductTimer);
	// 	}

	// 	barcodeScanInputAddProductTimer = setTimeout(function() {
	// 		addProductWithUpc($this.val());
	// 	}, 250);
	// });

	barcodeScanInputFocusInterval = setInterval(function() {
		if (!$barcodeScannerInput.is(':visible:focus') && $barcodeScanner.find(':focus').length == 0) {
			$barcodeScannerInput.focus();
		}
	}, 500);

	productSearchInputFocusInterval = setInterval(function() {
		if (!$productSearchInput.is(':visible:focus') && !$productSearch.hasClass('active') && $productSearch.find(':focus').length == 0) {
			$productSearchInput.focus();
		}
	}, 500);

	$productFinder.off('click.Add').on('click.Add', '.action.add', function(event) {
		event.preventDefault();
		addProductWithUpc($barcodeScannerInput.val());
	});

	$productFinder.off('click.OptionsToggle').on('click.OptionsToggle', '.action.options', function(event) {
		$(this).addClass('active');
	});

	$productFinder.off('click.ModeSet').on('click.ModeSet', '.options [set-mode]', function(event) {
		$barcodeScannerErrorContainer.html('');
		$productSearchErrorContainer.html('');
		$('.product-finder').attr('mode', $(this).attr('set-mode'));
		$('.action.options').removeClass('active');
	});

	$(document).off('click.OptionsClose').on('click.OptionsClose', function(event) {
		var $target = $(event.target);
		if (!$target.hasClass('options') && $target.closest('.options').length == 0) {
			$('.action.options').removeClass('active');
		}
	});

	$barcodeScanner.off('click.KeyboardToggle').on('click.KeyboardToggle', '.options .keyboard', function(event) {
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			$barcodeScannerInput.attr('inputmode', 'none');
		} else {
			$(this).addClass('active');
			$barcodeScannerInput.attr('inputmode', $barcodeScannerInput.attr('type'));
		}
		$('.action.options').removeClass('active');
	});

	$barcodeScanner.off('click.BarcodeScannerStart').on('click.BarcodeScannerStart', '.action.start', function(event) {
		if (!('BarcodeDetector' in window)) {
			alert('Barcode Detector is not supported by this browser.');
  		} else {
			$('.section.scan').addClass('active');

			if (!barcodeDetector) {
				barcodeDetector = new BarcodeDetector();
			}

			video = $barcodeScannerModal.find('video')[0];
			video.setAttribute('playsinline', '');
			video.setAttribute('autoplay', '');
			video.setAttribute('muted', '');
			video.style.width = '100%';
			video.style.height = '100%';

			var constraints = {
				audio: false,
				video: {
					facingMode: 'environment'
				}
			};
			
			navigator.mediaDevices.getUserMedia(constraints).then(function success(mediaStream) {
				stream = mediaStream;
				video.srcObject = stream;
			});

			video.onloadedmetadata = function(event) {
				barcodeDetectorInterval = setInterval(detectBarcode, 500);
			};
		}
	});

	$(document).off('click.BarcodeScannerStop').on('click.BarcodeScannerStop', '.modal .close', function(event) {
		closeBarcodeScanner();
	});

	$(document).off('paste.UPC').on('paste.UPC', function(event) {
		if (!$barcodeScannerInput.is(":focus")) {
			getClipboardValue(event, this, function(upc) {
				$barcodeScannerErrorContainer.html('');
				addProductWithUpc(upc);
			});
		}
	});

	$productSearchInput.off('keyup.ProductSearchChange').on('keyup.ProductSearchChange', function(event) {
		updateProductSearchAction();
	});

	$productSearch.off('click.ProductSearchStart').on('click.ProductSearchStart', '.action.search', function(event) {
		event.preventDefault();
		var query = $productSearchInput.val();
		if (query) {
			if ($productSearchInputContainer.attr('action') == 'search') {
				$productSearchResults.html('');
				$productSearch.removeClass('active');
				data['ProductSearchPage'] = 1;
				searchProductsWithQuery(query);
			} else {
				resetProductSearch();
			}
		}
	});

	$productSearch.off('click.ProductSearchLoadMore').on('click.ProductSearchLoadMore', '.load-more', function(event) {
		var query = $productSearchInput.val();
		if (query) {
			data['ProductSearchPage'] += 1;
			searchProductsWithQuery(query);
		}
	});

	$productSearch.off('click.ProductSearchProductSelect').on('click.ProductSearchProductSelect', '.grid-tile .product-tile a', function(event) {
		event.preventDefault();

		var $this = $(this);
		var $gridTile = $this.closest('.grid-tile');
		var $productTile = $this.closest('.product-tile');
		if ($gridTile.find('.product-variations').length > 0) {
			$gridTile.find('.product-variations').remove();
		} else {
			getStylesForProduct($productTile.data('itemid'));
		}
	});

	$productSearch.off('change.ProductSearchAttributeSelect').on('change.ProductSearchAttributeSelect', '.grid-tile .product-variations select', function(event) {
		var $productTile = $(this).closest('.grid-tile').find('.product-tile');
		getStylesForProduct($productTile.data('itemid'));
	});

	$productSearch.off('click.ProductSearchProductAdd').on('click.ProductSearchProductAdd', '.grid-tile .product-variations .btn.add', function(event) {
		var $this = $(this);
		if ($this.attr('pid')) {
			addProductWithProductId($this.attr('pid'), $this);
		}
	});

	$productSearch.off('click.ProductSearchClose').on('click.ProductSearchClose', '.results-container .close', function(event) {
		$('body').removeClass('product-search');
	});

	quantityStepper.init(null, renderCart);

	$(document).off('click.Checkout').on('click.Checkout', '.checkout .btn.btn-checkout', function (event) {
		$(this).loading({
			IsLabelShown: false,
			BackgroundColor: 'transparent'
		});
	});

	addToCart();
}

function updateProductSearchAction() {
	var productSearchInputValue = $productSearchInput.val();
	if (productSearchInputValue) {
		productSearchInputValue = productSearchInputValue.toLowerCase();
	}
	var productSearchQueryValue = $productSearchInputContainer.attr('query');
	if (productSearchQueryValue) {
		productSearchQueryValue = productSearchQueryValue.toLowerCase();
	}

	if (productSearchInputValue && productSearchQueryValue && productSearchInputValue == productSearchQueryValue) {
		$productSearchInputContainer.attr('action', 'reset');
	} else {
		$productSearchInputContainer.attr('action', 'search');
	}
}

function searchProductsWithQuery(query) {
	$productSearchErrorContainer.html('');
	$productSearchInputContainer.attr('query', query);
	$productSearchInputContainer.loading({
		IsImageShown: false,
		IsLabelShown: false
	});
	$productSearchSearchButton.loading({
		IsLabelShown: false,
		BackgroundColor: 'transparent'
	});
	if ($productSearchResultsLoadMore.is(':visible')) {
		$productSearchResultsLoadMore.loading({
			IsLabelShown: false,
			BackgroundColor: 'transparent'
		});
	}
	$productSearchInput.attr('disabled', 'disabled');

	$.ajax({
		type: 'GET',
		url: Urls.Search.Show,
		data: {
			format: 'ajax',
			q: query,
			// prefn1: 'availableStores',
			// prefv1: options.AgentStoreID,
			start: parseInt((data['ProductSearchPage'] - 1) * data['ProductSearchPageSize'], 10),
			sz: data['ProductSearchPageSize']
		},
		success: function(response) {
			var $html = $(response);
			var $productGrid = $html.find('#search-result-items');
			var $pagination = $html.find('.search-result-options .pagination[count]').first();
			var productTileCount = $productGrid.find('.grid-tile').length + $('.section.search .results-container').find('.grid-tile').length;
			var productTotalCount = 0;
			if ($pagination.length > 0) {
				productTotalCount = parseInt($html.find('.search-result-options .pagination[count]').first().attr('count').replace(/\D/g, ''), 10);
			}

			if (productTileCount > 0) {
				$productSearchResults.append($productGrid.html());
				if (productTileCount < productTotalCount) {
					$productSearchResultsFooter.show();
				} else {
					$productSearchResultsFooter.hide();
				}
			} else {
				$productSearchResults.append($('<li class="no-results">No products found for your search.</li>'));
				$productSearchResultsFooter.hide();
			}
			$productSearch.addClass('active');
		},
		complete: function(xhr) {
			$productSearchInputContainer.attr('upc', '');
			$productSearchInputContainer.loading('destroy');
			$productSearchSearchButton.loading('destroy');
			if ($productSearchResultsLoadMore.attr('loading')) {
				$productSearchResultsLoadMore.loading('destroy');
			}
			$productSearchInput.removeAttr('disabled');
			updateProductSearchAction();
		}
	});
}

function getStylesForProduct(productId) {
	var $productTile = $productSearchResults.find('.product-tile[data-itemid="' + productId + '"]');

	if ($productTile.length > 0) {
		var $gridTile = $productTile.closest('.grid-tile');

		if ($gridTile.length > 0) {
			var isFirstLoad = false;
			var $productVariations;
			if ($gridTile.find('.product-variations').length > 0) {
				$productVariations = $gridTile.find('.product-variations');
			} else {
				isFirstLoad = true;
				$productVariations = $gridTile.append('<div class="product-variations"></div>').find('.product-variations');
			}
			$productVariations.loading({
				IsLabelShown: false,
				BackgroundColor: 'transparent'
			});
			if ($gridTile.find('.btn.add').is(':visible')) {
				$gridTile.find('.btn.add').loading({
					IsLabelShown: false,
					BackgroundColor: 'transparent'
				});
			}
			data['attributeGuides'] = {};

			var payload = {
				pid: $productTile.data('itemid')
			}

			if (!isFirstLoad) {
				var $productVariationAttributesSelected = $productVariations.find('[attribute] option.selectable:checked');
				$productVariationAttributesSelected.each(function() {
					var $this = $(this);
					var productVariationAttributeId = $this.closest('[attribute]').attr('attribute');
					var productVariationAttributeValue = $this.val();
					payload['dwvar_' + payload.pid + '_' + productVariationAttributeId] = productVariationAttributeValue;
				});
			}

			$.ajax({
				method: 'post',
				url: Urls.getVariationAttributes,
				data: payload,
				dataType: 'json',
				success: function(response) {
					html['variationSelectors'] = '';
					for (var variation in response.variations) {
						var variationData = response.variations[variation];
						var variationDataValueSelected = null;
						
						html['variationSelectorWidgetOptions'] = '';
										
						for (var variationValue in variationData.values) {
							var variationDataValue = variationData.values[variationValue];
							var variationDataValueTemplate;
							
							var variationDataValuePayload = {
								attribute: variationDataValue.attribute,
								valueId: variationDataValue.valueId,
								value: variationDataValue.value,
								title: variationDataValue.title,
								text: variationDataValue.text,
								stock: variationDataValue.stock,
								shipping: variationDataValue.shipping,
								isSameDayShippingWindow: variationDataValue.isSameDayShippingWindow,
								quantitySourceType: variationDataValue.quantitySourceType,
								href: variationDataValue.href,
								selectable: variationDataValue.selectable ? 'selectable' : 'unselectable',
								selected: !isFirstLoad && variationDataValue.selected ? 'selected' : '',
								swatchImageUrl: variationDataValue.images.swatch.url,
								largeSwatchImageUrl: variationDataValue.images.largeSwatch.url,
								hrefAttribute: variationDataValue.selectable ? 'href' : 'xhref'
							};
							
							if (variationData.type == 'swatch') {
								if (variationDataValue.selectable) {
									variationDataValueTemplate = templates['variationSelectorSwatchOption'];
								
									variationDataValuePayload.imageLarge = JSON.stringify({
										url: variationDataValue.images.large.url,
										title: variationDataValue.images.large.title,
										alt: variationDataValue.images.large.alt,
										hires: variationDataValue.images.hires.url
									});
								} else {
									variationDataValueTemplate = templates['variationSelectorSwatchOptionUnselectable'];
								}
							} else {
								variationDataValueTemplate = templates['variationSelectorDropdownOption'];
								
								if (variationDataValue.selected) {
									variationDataValueSelected = variationDataValue;
								}
							}
						
							html['variationSelectorWidgetOptions'] += variationDataValueTemplate(variationDataValuePayload);
						}	
						
						if (variationData.type == 'swatch') {
							html['variationSelectorWidget'] = templates['variationSelectorSwatch']({
								attribute: variationData.productVariationAttribute,
								options: html['variationSelectorWidgetOptions']
							});
						} else {
							var placeholder = variationData.label.placeholder;
							if (variationDataValueSelected) {
								placeholder = templates['variationSelectorDropdownOptionSelected']({
									text: variationDataValueSelected.text,
									stock: variationDataValueSelected.stock,
									shipping: variationDataValueSelected.shipping,
									isSameDayShippingWindow: variationDataValueSelected.isSameDayShippingWindow,
									quantitySourceType: variationDataValueSelected.quantitySourceType
								});
							}
							
							html['variationSelectorWidget'] = templates['variationSelectorDropdown']({
								attribute: variationData.productVariationAttribute,
								text: placeholder,
								options: html['variationSelectorWidgetOptions']
							});
						}

						html['attributeGuide'] = '';
						if (variationData.attributeGuide) {
							data['attributeGuides'][variationData.productVariationAttribute] = variationData.attributeGuide;
							html['attributeGuide'] = templates['variationSelectorAttributeGuide'](_.extend(variationData.attributeGuide, { Attribute: variationData.productVariationAttribute }));
						}

						html['variationSelector'] = templates['variationSelector']({
							attribute: variationData.productVariationAttribute,
							label: {
								text: variationData.label.text,
								message: variationData.label.message
							},
							selector: html['variationSelectorWidget'],
							guide: html['attributeGuide']
						});

						html['variationSelectors'] += html['variationSelector'];
					}
					
					html['selectors'] = templates['variations']({
						selectors: html['variationSelectors']
					});

					$productVariations.html(html['selectors']).addClass('loaded');
					if (response.pid) {
						$gridTile.find('.btn.add').attr('pid', response.pid);
					} else {
						$gridTile.find('.btn.add').removeAttr('pid');
					}
				},
				complete: function(xhr) {
					$productVariations.loading('destroy');
				}
			});
		}
	}
}

function resetProductSearch() {
	$productSearch.removeClass('active');
	$productSearchInputContainer.attr('query', '');
	$productSearchInput.val('');
	$productSearchInput.focus();
	updateProductSearchAction();
}

function addProductWithProductId(productId, $gridTileAddButton) {
	if ($gridTileAddButton) {
		$gridTileAddButton.loading({
			IsLabelShown: false,
			BackgroundColor: 'transparent'
		});
	}
	$productSearchErrorContainer.html('');

	addProduct({
		payload: {
			pid: productId
		},
		complete: function(xhr) {
			if ($gridTileAddButton && $gridTileAddButton.attr('loading')) {
				$gridTileAddButton.loading('destroy');
			}
			resetProductSearch();
			renderCart();
		}
	});
}

function detectBarcode() {
	var canvas = $barcodeScannerModal.find('canvas')[0];
	canvas.width = video.videoWidth;
	canvas.height = video.videoHeight;

	var canvasContext = canvas.getContext('2d');
	canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);

	var barcodeDetector = new BarcodeDetector();
	barcodeDetector.detect(canvasContext.getImageData(0, 0, canvas.width, canvas.height)).then(barcodes => {
		$barcodeScannerModal.addClass('scanned');
		
		barcodes.forEach(function(barcode) {
			var barcodeValue;
			if ('rawValue' in barcode) {
				barcodeValue = barcode.rawValue;
			} else if ('rawData' in barcode) {
				barcodeValue = barcode.rawData;
			}
			
			closeBarcodeScanner();
			addProductWithUpc(barcodeValue);
		});
	}).catch(error => {
		// alert(error);
	});
}

function closeBarcodeScanner() {
	$('.section.scan').removeClass('active');
	if (barcodeDetectorInterval) {
		barcodeDetectorInterval = clearInterval(barcodeDetectorInterval);
	}
	stream.getTracks().forEach(function(track) {
		track.stop();
	});
	video.srcObject = null;
}

function getClipboardValue(event, element, callback) {
	if (navigator.clipboard && navigator.clipboard.readText) {
	  	// modern approach with Clipboard API
	  	navigator.clipboard.readText().then(callback);
	} else if (event.originalEvent && event.originalEvent.clipboardData) {
	  	// OriginalEvent is a property from jQuery, normalizing the event object
	  	callback(event.originalEvent.clipboardData.getData('text'));
	} else if (event.clipboardData) {
	  	// used in some browsers for clipboardData
	  	callback(event.clipboardData.getData('text/plain'));
	} else if (window.clipboardData) {
	  	// Older clipboardData version for Internet Explorer only
	  	callback(window.clipboardData.getData('Text'));
	} else {
	  	// Last resort fallback, using a timer
	  	setTimeout(function() {
			callback(element.value)
	  	}, 100);
	}
}

function addProductWithUpc(upc) {
	$barcodeScannerInputContainer.attr('upc', upc);
	$barcodeScannerInputContainer.loading({
		IsImageShown: false,
		IsLabelShown: false
	});
	$barcodeScannerAddButton.loading({
		IsLabelShown: false,
		BackgroundColor: 'transparent'
	});
	$barcodeScannerInput.val('');
	$barcodeScannerErrorContainer.html('');
	$barcodeScannerInput.attr('disabled', 'disabled');

	addProduct({
		payload: {
			upc: upc
		},
		success: function(response) {
			if (response && !response.IsProductInProductService) {
				$barcodeScannerErrorContainer.append('<p>Product not found in service!</p>');	
			} else if (response && !response.IsProductInCatalog) {
				$barcodeScannerErrorContainer.append('<p>Product not found in catalog!</p>');	
			}
		},
		complete: function(xhr) {
			$barcodeScannerInputContainer.attr('upc', '');
			$barcodeScannerInputContainer.loading('destroy');
			$barcodeScannerAddButton.loading('destroy');
			$barcodeScannerInput.removeAttr('disabled');
			renderCart();
		}
	});
}

function addProduct(parameters) {
	var payload = $.extend(parameters.payload || {}, {
		format: 'ajax',
		Quantity: 1,
		cartAction: 'add',
		csrf_token: options.Token,
		channel: 'Echo',
		bopis_store: '',
		bopis_quantity_available: '',
		bopis_shipping_option: ''
	});

	if (payload.pid || payload.upc) {
		$.ajax({
			type: 'POST',
			url: Urls.addProduct,
			data: payload,
			success: function(response) {
				if (parameters.success && typeof parameters.success == 'function') {
					parameters.success(response);
				}
			},
			complete: function(xhr) {
				if (parameters.complete && typeof parameters.complete == 'function') {
					parameters.complete(xhr);
				}
			}
		});
	}
}

function renderCart() {
	$.ajax({
		type: 'GET',
		url: Urls.Cart.GetCartBodyProductLineItems,
		success: function(response) {
			$('#wrapper > .cart > form').html(response);
		}
	});

	$.ajax({
		type: 'POST',
		url: Urls.getCartData,
		data: {
			isCart: true
		},
		success: function(response) {
			if (response && response.OrderTotal && response.OrderTotal.adjustedMerchandizeTotalPrice) {
				$('.order-subtotal .order-value').text(response.OrderTotal.adjustedMerchandizeTotalPriceDisplay);
				$('.order-totals-table').removeClass('hidden');
			} else {
				$('.order-totals-table').addClass('hidden');
			}
		}
	});

	updateCSRFToken();
}

function updateCSRFToken() {
    $.ajax({
    	method: 'get',
    	url: Urls.CSRFToken.Get,
		dataType: 'json',
    	success: function(response) {
    		options.Token = response.token;
    	}
    });
}

exports.renderCart = renderCart;
exports.init = function (optionsParameter) {
	options = optionsParameter;
	initialize(options);
};