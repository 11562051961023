'use strict';

var _ = require('lodash');
var loadingIndicator = require('../components/LoadingIndicator');
var GeolocationHelper = require('../../../../bc_sheplersbootbarn_core/cartridge/js/Geolocation/GeolocationHelper');
var shopYourStoreDialog = require('../bopis/components/ShopYourStoreDialog');
var TemplateConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');

var options;
var dataBuffer;
var inputSearchTimer;

var $container;
var $content;
var $useMyLocationButton;
var $searchButton;
var $locationSearchResults;
var locationSearchResultContainerTemplate;
var locationSearchResultItemTemplate;

function initializeEvents() {
	$(document).off('click.ShopYourStoreDialog').on('click.ShopYourStoreDialog', '.shop-store-inventory', function(event) {
		event.preventDefault();

		var $this = $(this);
		var $store = $this.closest('[store-id]');
		shopYourStoreDialog.initialize({
			StoreId: $store.attr('store-id'),
			StoreName: $store.attr('store-name')
		});
	});

	$('.pt_store-locator').off('click.UseMyLocation').on('click.UseMyLocation', '.use-my-location', function (event) {
		event.preventDefault();

		dataBuffer.limit = window.outerWidth >= 1024 ? 10 : 5;
		dataBuffer.offset = 0;
		dataBuffer.query = '';
		$('.store-filter-container [type="text"]').val('');

		$useMyLocationButton.loading();
		$locationSearchResults.html('');
		$locationSearchResults.loading();
		GeolocationHelper.GetPosition().then(function(position) {
			dataBuffer.position = position;
			getStoreList();
		}).catch(function() {
			if ($locationSearchResults.attr('loading')) {
				$locationSearchResults.loading('destroy');
			}
		});
	});

	$('.pt_store-locator').off('submit.Search').on('submit.Search', '.store-filter-container form', function (event) {
		event.preventDefault();
		search();
	});

	$('.pt_store-locator').off('click.Search').on('click.Search', '.store-filter-container .btn.btn-primary', function (event) {
		event.preventDefault();
		search();
	});

	$('.pt_store-locator').off('click.LoadMore').on('click.LoadMore', '.location-search-results .load-more', function (event) {
		event.preventDefault();

		var $this = $(this);
		dataBuffer.limit = window.outerWidth >= 1024 ? 10 : 5;
		dataBuffer.offset += dataBuffer.limit;
		$this.parent().loading();
		if (navigator && navigator.permissions) {
			navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
				if (PermissionStatus.state == 'granted') {
					GeolocationHelper.GetPosition().then(function(position) {
						dataBuffer.position = position;
						getStoreList();
					}).catch(function() {
						if ($locationSearchResults.attr('loading')) {
							$locationSearchResults.loading('destroy');
						}
					});
				}
			});
		} else {
			getStoreList();
		}
	});
}

function search() {
	dataBuffer.limit = window.outerWidth >= 1024 ? 10 : 5;
	dataBuffer.offset = 0;
	dataBuffer.query = $('.store-filter-container [type="text"]').val();

	$searchButton.loading();
	$locationSearchResults.html('');
	$locationSearchResults.loading();
	if (navigator && navigator.permissions) {
		navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
			if (PermissionStatus.state == 'granted') {
				GeolocationHelper.GetPosition().then(function(position) {
					dataBuffer.position = position;
					getStoreList();
				}).catch(function() {
					if ($locationSearchResults.attr('loading')) {
						$locationSearchResults.loading('destroy');
					}
				});
			} else {
				getStoreList();
			}
		});
	} else {
		getStoreList();
	}
}

function getStoreList() {
	var geolocationPosition;
	if (dataBuffer.position && dataBuffer.position.coords) {
		geolocationPosition = {
			coords: {
				accuracy: dataBuffer.position.coords.accuracy,
				latitude: dataBuffer.position.coords.latitude,
				longitude: dataBuffer.position.coords.longitude
			},
			timestamp: dataBuffer.position.timestamp
		};
	}

	var payload = {
		Source: 'StoreLocatorPage',
		GeolocationPosition: geolocationPosition
	};
	if (dataBuffer.query != undefined) {
		payload.Query = dataBuffer.query;
	}
	if (dataBuffer.limit != undefined) {
		payload.Limit = dataBuffer.limit;
	}
	if (dataBuffer.offset != undefined) {
		payload.Offset = dataBuffer.offset;
	}

	$.ajax({
		method: 'POST',
		url: Urls.Bopis.GetStoresForGeolocationPosition,
		contentType: 'application/json',
		data: JSON.stringify(payload),
		dataType: 'json',
		success: function(data) {
			renderStoreList(data);
		},
		error: function() {
			
		}
	});		
}

function renderStoreList(data) {
	var locationData;
	if (data && data.ServiceSuccessfullyCalled && data.InquiryResult && data.InquiryResult.data && data.InquiryResult.data.length > 0) {
		locationData = data.InquiryResult.data;
	}

	$container.attr('state', 'result');
	if (locationData && locationData.length > 0) {
		locationData = locationData.sort(function(left, right) {
			return left.distance - right.distance;
		});
		
		dataBuffer['StoresDisplayed'] = [];
		$container.attr('result-count', locationData.length);
		
		var storeListHtml = '';
		$locationSearchResults.find('.no-results').remove();
		for (var locationElement in locationData) {
			var location = locationData[locationElement];
			
			if (location && !dataBuffer['StoresDisplayed'][location.code] && location.StoreInfo.StateCodeParsed && location.StoreInfo.PostalCode) {
				var storePhone = '';
				var storeOpenUntil = '';
				if (location.StoreInfo) {
					if (location.StoreInfo.Phone) {
						storePhone = location.StoreInfo.Phone;
					}
					if (location.StoreInfo.OpenUntil) {
						storeOpenUntil = location.StoreInfo.OpenUntil;
					}
				}
				
				var storeInventoryLink = '';
				if (location.StoreInfo.StoreInventoryLink) {
					storeInventoryLink = '<a class="shop-store-inventory">' + Resources.Stores.ShopStoreInventory + '</a>';
				}

				var directionsLink = '';
				if (location.StoreInfo.DirectionsUrl) {
					directionsLink = '<a class="directions" href="' + location.StoreInfo.DirectionsUrl + '" target="_blank">Directions</a>';
				}

				storeListHtml += locationSearchResultItemTemplate({
					StoreId: location.code,
					StoreName: location.StoreInfo.Name,
					Distance: location.StoreInfo.Distance.toFixed(1) + ' mi',
					Html: '<a href="' + Urls.storeDetails + '?StoreID=' + location.code + '">' + location.StoreInfo.Name + '</a><br/>' +
							location.StoreInfo.Address1 + '<br/>' + 
							location.StoreInfo.City + ', ' + location.StoreInfo.StateCodeParsed + ' ' + location.StoreInfo.PostalCode.substr(0, 5) + '<br/>' +
							storePhone + '<br/>' +
							storeOpenUntil,
					DirectionsLink: directionsLink,
					StoreInventoryLink: storeInventoryLink
				});
			}
			
			dataBuffer['StoresDisplayed'][location.code] = true;
		}
		if (data.InquiryResult.meta && data.InquiryResult.meta.page < data.InquiryResult.meta.pages) {
			storeListHtml += '<li class="load-more-container"><a class="load-more">Load More</a></li>';
		}
		
		var resultHtml = locationSearchResultContainerTemplate({
			Html: storeListHtml
		});
		$locationSearchResults.find('.load-more-container').remove();
		$locationSearchResults.append(resultHtml);
	} else {
		$container.attr('result-count', '0');
		$locationSearchResults.html('<li class="no-results">No stores found! Please try a different search.</li>');
	}

	if ($useMyLocationButton.attr('loading')) {
		$useMyLocationButton.loading('destroy');
	}
	if ($searchButton.attr('loading')) {
		$searchButton.loading('destroy');
	}
	if ($locationSearchResults.attr('loading')) {
		$locationSearchResults.loading('destroy');
	}
}

exports.init = function (optionsParameter) {
	options = optionsParameter || {};
	dataBuffer = {
		offset: 0
	};

	$container = $('#stores_main');
	$content = $container.find('.content');
	$useMyLocationButton = $('.store-filter-container .use-my-location');
	$searchButton = $('.store-filter-container .btn.btn-primary');
	$locationSearchResults = $('.location-search-results');
	locationSearchResultContainerTemplate = _.template($('#template-bopis-result-container').html(), TemplateConstants.Lodash.Settings);
	locationSearchResultItemTemplate = _.template($('#template-bopis-result-item-store-locator').html(), TemplateConstants.Lodash.Settings);	

	initializeEvents();
};