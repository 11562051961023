'use strict';

var page = require('./page');
var DataLayerConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/DataLayerConstants');
var GoogleTagManagerClient = require('../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var SiteConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var isCheckout, isEchoCart, fnUpdateCart, fnRenderCart, $focusedElement, spinnerTimer;
var loadingIndicatorSettings = {
    IsLabelShown: false,
    CssClass: 'fullscreen',
    BackgroundColor: 'transparent'
};

function updateStepperQtyUI($this, increment) {
	var $quantityStepper = increment != undefined ? $this.parent('.quantity-stepper') : $this;
	var $input = $quantityStepper.find('input.input-text');
	var quantity = parseInt($input.val());
	// Only increment or decrement quantity if a boolean was passed into the function
	if (increment != undefined) {
		increment ? quantity++ : quantity--;
	}
	
	// Enable or Disable the plus/minus signs on the quantity stepper
	var $incButton = $quantityStepper.find('button.increase.plus');
	var $decButton = $quantityStepper.find('button.decrease.minus');

	if (quantity <= 1) {
		$decButton.addClass('disabled').attr('disabled', 'disabled');
		$incButton.removeClass('disabled').removeAttr('disabled');
	} else if (quantity > 1) {
		$decButton.removeClass('disabled').removeAttr('disabled');
		$incButton.removeClass('disabled').removeAttr('disabled');
	}

	// Update the value of the quantity stepper
	$input.attr('quantity-buffer', quantity);
	$input.val(quantity);
	
	return quantity;
}

function updateMiniCartUI(increment, $lineItem) {
	var $miniCartElm = $('.mini-cart .minicart-quantity').length > 0 ? $('.mini-cart .minicart-quantity') : $('.mini-cart .btn-minicart .btn-minicart-qty');
	var miniCartTotal;
	if ($miniCartElm.length > 0) {
		miniCartTotal = parseInt($miniCartElm.html().trim());
	}

	if ((increment != null && typeof increment === "boolean") && !$lineItem) {
		increment ? miniCartTotal++ : miniCartTotal--;
	} else if ((increment == null || typeof increment != "boolean") && $lineItem) {
		var itemQty = parseInt($lineItem.find('.opc-qty').val());
		miniCartTotal = itemQty && typeof itemQty == 'number' && itemQty > 0 ? miniCartTotal - itemQty : miniCartTotal;
		$lineItem.remove();
	}
	
	// Update the basket quantity on the main navigation bar > minicart icon
	miniCartTotal >= 1 ? $miniCartElm.html(miniCartTotal) : $miniCartElm.html(0);

	// Update the minicart modal, if it's currently open
	if ($('.add-to-cart-modal').length > 0) {
		var productRecommendationsHtml = $('.add-to-cart-modal  .main-content .product-recommendations-container').html();
		$.ajax({url : Urls.addToCartModalDisplay, dataType: 'html', success : function(modalContent) {
			if (modalContent.length > 0) {
				$('.add-to-cart-modal .ui-dialog-content').html(modalContent);
				$('.add-to-cart-modal-content .main-content .product-recommendations-container').append(productRecommendationsHtml);
				// Reference addToCart.js > addItemToCart() > line 314 (Move title from main content to top of the modal)
				if ($('.add-to-cart-modal-content').length > 0) {
					var $addMessage = $('.add-to-cart-modal-content .main-content .added-message');
					// Reference addToCart.js > addItemToCart() > line 377 (Inserts the word 'Item' into the minicart title)
					$('.add-to-cart-modal-content .added-message .count').text('Item');
					if ($addMessage.length > 0) {
						$('.add-to-cart-modal-content').prepend($addMessage);
					}
				}
			}
		}});
	}
}

function updateCartTotals() {
	if (isCheckout && fnUpdateCart) {
		//fnUpdateCart($('.update-cart-button').attr('name'));
		$(document).trigger('Cart.Update', {
			'EventTriggers': {
				'update-shipping': true,
				'update-payment-methods': true
			}
		});
	}
	if (isEchoCart && fnRenderCart) {
		fnRenderCart();
	}
}

function removeFromCartDataLayer($this) {
	var $item = $this.closest('[data-uuid]');
	if ($item) {
		var productDataForUniversalAnalytics = JSON.parse($item.find('[data-product-gtm-json]').attr('data-product-gtm-json'));
		var productDataForGoogleAnalytics4 = JSON.parse($item.find('[data-product-ga4-json]').attr('data-product-ga4-json'));

		var dataObject = {
			UniversalAnalytics: {
				ecommerce: {
					remove: {
						products: [productDataForUniversalAnalytics]
					}
				}
			},							
			GoogleAnalytics4: {
				value: productDataForGoogleAnalytics4.price,
				items: [productDataForGoogleAnalytics4]
			}
		}

		GoogleTagManagerClient.PushDataLayerForDataLayerType('RemoveFromCart', dataObject);
	}
}

function quantityDatalayerEvents($this, eventType, quantity) {
	var $item = $this.closest('[data-uuid]');

	var eventName;
	var ecommerceKey;
	if (eventType == DataLayerConstants.EventTypes.Minus) {
		eventName = DataLayerConstants.Events.Cart.RemoveFromCart.Name;
		ecommerceKey = 'remove';
	} else {
		eventName = DataLayerConstants.Events.Cart.AddToCart.Name;
		ecommerceKey = 'add';
	} 
	
	var productDataForUniversalAnalytics = JSON.parse($item.find('[data-product-gtm-json]').attr('data-product-gtm-json'));
	var productDataForGoogleAnalytics4 = JSON.parse($item.find('[data-product-ga4-json]').attr('data-product-ga4-json'));
	if (quantity) {
		productDataForUniversalAnalytics.quantity = quantity;
		productDataForGoogleAnalytics4.quantity = quantity;
	}

	var dataObject = {
		UniversalAnalytics: {
			ecommerce: {}
		},							
		GoogleAnalytics4: {
			value: productDataForGoogleAnalytics4.price,
			items: [productDataForGoogleAnalytics4]
		}
	}

	dataObject.UniversalAnalytics.ecommerce[ecommerceKey] = {
		products: [productDataForUniversalAnalytics]
	}

	GoogleTagManagerClient.PushDataLayerForDataLayerType(eventName, dataObject);
}

function quantityChangeEvents($this, quantity, increment) {
	var eventType = increment ? DataLayerConstants.EventTypes.Plus : DataLayerConstants.EventTypes.Minus;
	quantityDatalayerEvents($this, eventType, quantity);
}

function spinnerClear(isClear) {
	var timerIncrement = isCheckout || isEchoCart ? 5000 : 2000;
	if (!spinnerTimer) {
		spinnerTimer = setTimeout(function() {
			$('body').loading('destroy');
		}, timerIncrement);
	}
	if (isClear) {
		clearTimeout(spinnerTimer);
	}
}

function initialize(updateCart, renderCart) {
	isCheckout = (pageContext && pageContext.type == 'cart' || pageContext.type == 'onepcheckout' || pageContext.type == 'onepcheckout_groups') || (page.ns && page.ns == 'cart' || page.ns == 'onepcheckout' || page.ns == 'onepcheckout_groups');
	isEchoCart = (pageContext && 'type' in pageContext && pageContext.type == 'cart_echo') || (page.ns && page.ns == 'cart_echo');
	fnUpdateCart = updateCart && typeof updateCart === 'function' ? updateCart : null;
	fnRenderCart = renderCart && typeof renderCart === 'function' ? renderCart : null;

	// Remove Button
	$(document).off('click.QuantityStepper.ProductRemove').on('click.QuantityStepper.ProductRemove', '.quantity-stepper-remove .remove', function (event) {
		event.preventDefault();

		var $this = $(this);
		var $itemRow = $this.closest('[data-uuid]');
		var isMiniCart = $itemRow.attr('class').indexOf('mini-cart') > -1;
		$('body').loading(loadingIndicatorSettings);

		$.ajax({
			type: 'POST',
			url: Urls.Cart.RemoveProduct,
			contentType: 'application/json',
			data: JSON.stringify({
				ProductLineItemUUID: $itemRow.data('uuid'),
			}),
			dataType: 'json',
			complete: function(xhr) {
				try {
					updateCartTotals();
					removeFromCartDataLayer($this);
					if (isMiniCart) {
						updateMiniCartUI(null, $itemRow);
					}
					spinnerClear();
				} catch(e) {
                    console.log(e);
					$('body').loading('destroy');
                }
			}
		});
	});

	// Plus/Minus Qty Buttons
	$(document).off('click.QuantityStepper.QuantityChange', '.quantity-stepper button:not(".disabled")').on('click.QuantityStepper.QuantityChange', '.quantity-stepper button:not(".disabled")', function (event) {
		event.preventDefault();

		var $stepperButton = $(this);
		var isIncrement = $stepperButton.hasClass('increase');
		var cartQtyUrl = isIncrement ? Urls.Cart.AddProductQuantity : Urls.Cart.RemoveProductQuantity;

		$focusedElement = $stepperButton;
		$('body').loading(loadingIndicatorSettings);
		$.ajax({
			type: 'POST',
			url: cartQtyUrl,
			contentType: 'application/json',
			data: JSON.stringify({
				ProductLineItemUUID: $(this).closest('[data-uuid]').data('uuid'),
				Quantity: 1
			}),
			dataType: 'json',
			complete: function(xhr) {
				try {
                    updateStepperQtyUI($stepperButton, isIncrement);
					updateMiniCartUI(isIncrement);
					updateCartTotals();
					quantityChangeEvents($stepperButton, 1, isIncrement);
					spinnerClear();
                } catch(e) {
                    console.log(e);
					$('body').loading('destroy');
                }
			}
		});
	});

	// On change of product qty in Cart, check the UI value of Quantity Stepper, and make qty = 1 if value isn't valid
	$(document).off('change.QuantityStepper.QuantityChange').on('change.QuantityStepper.QuantityChange', '.quantity-stepper input[name$=_quantity]', function () {
		if (isNaN($(this).val()) || parseInt($(this).val(), 10) === 0) {
			$(this).val('1');
			$focusedElement = $(this);
		}
	});

	// Keyboard events for Quantity Stepper
	$(document).off('keydown.QuantityStepper.QuantityChange').on('keydown.QuantityStepper.QuantityChange', '.quantity-stepper input[name$=_quantity]', function (event) {
		var $this = $(this);

		if (event.which === SiteConstants.KeyCodes.Enter) {
			event.preventDefault();
			$focusedElement = $this;
			if ($(event.target).val() > 0) {
				var quantityDelta = parseInt($this.val(), 10) - parseInt($this.attr('quantity-buffer'), 10);
				updateStepperQtyUI($this, true);
				updateMiniCartUI(true);
				updateCartTotals();
				quantityChangeEvents($this, Math.abs(quantityDelta), quantityDelta > 0);
				$this.attr('quantity-buffer', $this.val());
			}
		}
	});

	$(document).off('QuantityStepper.TargetFocus').on('QuantityStepper.TargetFocus', function () {
		if ($focusedElement) {
			$focusedElement.focus();
		}
	});

	$(document).on('Checkout.Updated', function (event, data) {
		spinnerClear(true);
		$('body').loading('destroy');
    });
}

exports.init = initialize;