/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    //tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
  	TPromise = require('promise'),
  	ajax = require('./ajax'),
  	rotate3d = require('./productRotate3d.js'),
    count_down = require('./countdown'),
    lazyloadPageElements = require('./lazyloadPageElements'),
    cookieNotification = require('./components/CookieNotification'),
    navigation = require('./components/NavigationController'),
    emailForm = require('./components/EmailForm'),
	slickHelpers = require('./slickSliderHelpers'),
	quantityStepper = require('./quantityStepper'),
	wishlist = require('./wishlist'),
	loginDialog = require('./loginDialog'),
	login = require('./login'),
	register = require('./register'),
	alert = require('./alert'),
	Globale = require('./components/Globale'),
	searchbar = require('./searchbar'),
	sliders = require('./sliders');

var EmailSignup = require('./components/EmailSignup');
var giftCardBalanceInquiryHelper = require('./helpers/GiftCardBalanceInquiryHelper');
var GoogleTagManagerClient = require('../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var RequestHelper = require('../../../app_storefront_core/cartridge/js/helpers/RequestHelper');
var SiteConstants = require('../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('./watermark')();

var dataBuffer = {};
var siteName;

var $sliderMobile;
var $navSecondary;
var $navUtilities;
var $navUl;
var $topBannerBar;
var $topBannerBarInner;
var $navigation;
var $topBannerInnerLinks;
var $selectSort;
var $secondaryHead;
var $searchResultContainer;
var $productContainerImage;
var $productDetail;
var $sectionCheckout;
var $mainInnerAlt;
var $sectionErrorFigure;
var $sectionErrorBox;
var $footerMenuItems;
var $promoMessages;

function initializeEvents() {
	cookieNotification.initialize();
	var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
	var $win = $(window);
	var $doc = $(document);
	$doc.on('app.resize', function () {
		resizeDeviceHelper();
	});
	$win.on('load resize', function () {
		resizeDeviceHelper();
	});
	
	$(document).off('CategoryHeaderSliders.Initialize').on('CategoryHeaderSliders.Initialize', function(event) {
		sliders.initializeCategorySliders('.cat-header-content.cat-header-slider:not(.slick-initialized)');
	});

	$(document).off('PostAjaxAuthenticationActions.Initialize').on('PostAjaxAuthenticationActions.Initialize', function(event, options) {
		var data = options.results;
		var isAccountDialog = options.isAccountDialog;
		if (isAccountDialog) {
			var customerFirstName = data.customerFirstName ? ', ' + data.customerFirstName + '.' : '.';
			var alertMessage;
			if (options.displayMessage == 'login') {
				alertMessage = 'Welcome back' + customerFirstName;
			}
			if (options.displayMessage == 'register') {
				alertMessage = 'Thanks for creating an account' + customerFirstName;
			}
			
			if (SitePreferences.WISHLIST_ENABLED) { // Future-forward: in case we ever implement login/register dialogs other than with wishlist
				/**** Wishlist icon updates ****/
				var wishlistItems = data.controllerResults && data.controllerResults.allWishlistItems ? data.controllerResults.allWishlistItems : data.allWishlistItems ? data.allWishlistItems : null;
				var returnedResult = data.controllerResults && data.controllerResults.result ? data.controllerResults.result : data.result ? data.result : null;
				if (wishlistItems != null) {
					window.localStorage.WishlistProductIDs = JSON.stringify(wishlistItems);
					wishlist.renderAllWishlistButtons();
					if (returnedResult == 'complete') {
						alertMessage += ' This item is already saved to your Wishlist.';
					} else {
						alertMessage += ' An item has been added to your Wishlist.';
					}
				}
			}

			/**** Selected store updates ****/
			var storeFromProfile = data.storeFromProfile ? data.storeFromProfile : null;
			var addToCartOptionsStoreId = $('.bopis-add-to-cart-options-store-selector .selected-store').length > 0 ? $('.bopis-add-to-cart-options-store-selector .selected-store').attr('store-id') : null;
			var navStoresDropdownStoreId = $('.stores-dropdown .current-store .map.store').length > 0 ? $('.stores-dropdown .current-store .map.store').attr('data-id') : null;
			var storeID;

			var isProductPage = $('.pt_product-details').length;
			var isCategory = $('.pt_product-search-result').length;
			if (isProductPage) {
				storeID = addToCartOptionsStoreId ? addToCartOptionsStoreId : storeFromProfile ? storeFromProfile : null;
				if (storeID) {
					// Redraw the "My Store" piece on the PDP
					$(document).trigger('Bopis.StoreSearch.Select', storeID);
				}
			}
			if (isCategory) {
				storeID = navStoresDropdownStoreId ? navStoresDropdownStoreId : storeFromProfile ? storeFromProfile : null;
			}
			if (siteName == SiteConstants.SiteIds.BootBarnUS) {
				if (isCategory) {
					if (storeID) {
						// Make sure to update the store number in customer profile when on the PLP
						$(document).trigger('Bopis.AssignStoreToProfile', storeID);
					} 
				}
				if (storeID) {
					// Redraw the selected store pieces in nav, Shop Your Store on PLP, My Account store drop-down, and store search modal
					$(document).trigger('Bopis.SelectedStore.Refresh', storeID);
				}
			}

			/**** Minicart update ****/
			if (data.cartQuantity) {
				var $miniCartElm = $('.mini-cart .minicart-quantity').length > 0 ? $('.mini-cart .minicart-quantity') : $('.mini-cart .btn-minicart .btn-minicart-qty');
				$miniCartElm.html(data.cartQuantity);
			}

			/**** My Account navigation update ****/
			if (siteName == SiteConstants.SiteIds.BootBarnUS) {
				$('.login-dropdown .user-panel .form-login').addClass('d-none');
				$('.login-dropdown .login-dropdown-customer-menu').removeClass('d-none');
			} else {
				var profileName = data.customerFirstName ? ' ' + data.customerFirstName : '';
				$('.mobile-account-main.first-name a').first().find('span').html('Hello' + profileName);
			}

			/**** Client Window > Customer object update ****/
			Customer.authenticated = true;
			Customer.registered = true;
			
			/**** Close the account dialog ****/
			dialog.close();

			/**** Show the alert ****/
			alert.init({
				type: 'alert-primary',
				timer: 3000,
				content: alertMessage
			});
		} else {
			window.location.href = data.registerDialogContinueUrl ? data.registerDialogContinueUrl : data.loginDialogContinueUrl ? data.loginDialogContinueUrl : location.reload();
		}
	});

	// Dom-Ready Sliders
	sliders.init('.barn-slider', {dots: false, breakpoints: {0: {slidesToShow: 1}, 1024: {slidesToShow: 2}}});
	sliders.init('.collections-content.has-slider .curated', {breakpoints: {0: {slidesToShow: 1}, 768: {slidesToShow: 3}}});
	sliders.init('.section-offers.tiles .section-body', {dots: false, breakpoints: {0: {slidesToShow: 1}, 480: {slidesToShow: 3}, 768: {slidesToShow: 4}}});
	sliders.init('.section-offers.section-brands .brand-list', {dots: false, breakpoints: {0: {slidesToShow: 4}, 480: {slidesToShow: 3}, 768: {slidesToShow: 6}}});

	if ($(document).find('.jumbotron-mobile').length > 0 && window.innerWidth <= 767) {
		var jumbotronExpandedCookie = getCookie('jumbotronExpandedCookie');
		if (jumbotronExpandedCookie == 'true' && $('#jumbotron-current-offers-icon').hasClass('fa-angle-down')) {
			$('.jumbotron-mobile').toggleClass('active');
			$('#jumbotron-current-offers-icon').removeClass('fa-angle-down');
			$('#jumbotron-current-offers-icon').addClass('fa-angle-up');
		} else if(jumbotronExpandedCookie == 'false' && $('#jumbotron-current-offers-icon').hasClass('fa-angle-up')) {
			$('.jumbotron-mobile').toggleClass('active');
			$('#jumbotron-current-offers-icon').removeClass('fa-angle-up');
			$('#jumbotron-current-offers-icon').addClass('fa-angle-down');
		}
	}
		
	if(window.innerWidth > 767 ) {
		$('.mobile-only .guest-checkout-form').remove();
	} else {
		$('.mobile-hidden .guest-checkout-form').remove();
	}
	
	$('#guest_checkout_optin:checkbox').change(function() {
		if ($('#guest_checkout_optin').prop("checked")) {
			$('.optin-input').addClass('checked');
		} else {
			$('.optin-input').removeClass('checked');
		}
	});

	// selected states for account navigation
	if ($(document).find('.pt_account, .pt_order, .pt_customer-service, .pt_wish-list').length > 0) {
		var currentPage = window.location.origin + window.location.pathname;
		var isOnTopLevelPage = false;
		$('#secondary nav a')
			.removeClass('is-active')
			.each(function(index, value) {
				if (value.href === currentPage) {
					$(this).addClass('is-active');
					isOnTopLevelPage = true;
				}
		});
		if (!isOnTopLevelPage) {
			$('#secondary nav a').each(function(index, value) {
				if (value.href === document.referrer) {
					$(this).addClass('is-active');
				}
			});
		}
	}

	// --- Document.Ready(): ADA Compliance Fixes ---
	$(document).trigger('SlickSliderAccessibilityMods');
	// This work-around will have to do until we can contact the vendor to fix their tags.
	var adaTagIntervalCounter = 0;
	var adaTagInterval = setInterval(function(){ 
		if (!$('img[src*="arttrk.com"]').is('[alt]')) {
			$('img[src*="arttrk.com"]').attr('alt','Tracking pixel');
		}
		if (!$('#fileUploadForm input#fileSelector').is('[aria-label]')) {
			$('#fileUploadForm input#fileSelector').attr('aria-label', 'Select file to upload');
		}	
		if (adaTagIntervalCounter > 9 || ($('img[src*="arttrk.com"]').is('[alt]') && $('#fileUploadForm input#fileSelector').is('[aria-label]'))) {
			clearInterval(adaTagInterval);
		}
		adaTagIntervalCounter++;
	}, 1000); // 1000 ms = start after 1sec 
	// Skip link for product recommendations container
	$(document).off('click.Skip.Recommendations').on('click.Skip.Recommendations','.skip-product-recommendations-container', function(e) {
		$(this).siblings('.product-recommendations-container-dive').focus();
	});

	$(document).off('click.Zoom.Skip.Section').on('click.Zoom.Skip.Section','.product-image-container-mobile .skip-to-next', function(e) {
		e.preventDefault();
		var isSkipToWishlistButton = $(this).attr('href').indexOf('pdp-wishlist-button-container') > -1;
		if (isSkipToWishlistButton) {
			$('#pdp-wishlist-button-container .wishlist-item-status-button').addClass('focus-visible-indicator').focus();
		} else {
			window.location = $(this).attr('href');
		}
	});

	// --- Initialize Events: ADA Compliance Keyboard Tab Visual Indicator ---
	$(document).on('keyup', function (event) {
		if (event.which === SiteConstants.KeyCodes.Tab) {
			$(':focus').addClass('focus-visible-indicator');
			if ($(':focus').hasClass('visually-hidden')) {
				// Get the innards of the current focus element and copy them into the hidden-element-memo that is then prepended to the <body>
				var htmlText = $(':focus').html();
				var $hiddenElementMemo = $('<div/>', {"class": "hidden-element-memo"}).html(htmlText);
				$('body').prepend($hiddenElementMemo);

				// Clear memo after 3 seconds if it has not already been cleared
				setTimeout(function() { 
					$('.hidden-element-memo').remove();
				}, 3000); // 3000 ms = start after 1sec 
			}
		}
	}).on('keydown keypress mousemove', function (event) {
		$('.focus-visible-indicator').removeClass('focus-visible-indicator');
		$('.hidden-element-memo').remove();
	});

	$(document).on('init.Slider.Aria', '.slider', function(event, slick) {
		$(this).find('.slick-list').removeAttr('aria-live');
	}).on('click.Slider.Aria keyup.Slider.Aria', function(event) {
		if (event.target.matches('.slider, .slider *')) {
			if (!$(event.target).closest('.slider').find('.slick-list').attr('aria-live')) {
				$(event.target).closest('.slider').find('.slick-list').attr('aria-live', 'polite');
			}
		} else {
			$('.slider').find('.slick-list').removeAttr('aria-live');
		}
	});

    $.fn.hasAttr = function(name) {
		return this.attr(name) !== undefined;
	};
	
	function CheckloginCreate() {
		var loginCreateCount = $(".login-create-account").length;
		if(loginCreateCount == 0) {
		$(".order-confirmation-details").addClass("fullWidth");
		}else {
			$(".order-confirmation-details").removeClass("fullWidth");
		}
	}
	
	// set customer birthday
	$(document).on('click','#dwfrm_birthday button.confirm', function(event){
		event.preventDefault();
		var email = $('.credential-list--email').text().trim();
		var birthdayField = $('#dwfrm_birthday');
		var birthmonth = birthdayField.find('.birthmonth select');
		var birthday = birthdayField.find('.birthday select');
		var birthyear = birthdayField.find('.birthyear select');
		var month, day, year, birthdate;
		
		if (!birthmonth.val()) {
			birthmonth.addClass('error-red');
		} else {
			birthmonth.removeClass('error-red');
			month = birthmonth.find("option:selected").text();
		}
		
		if (!birthday.val()) {
			birthday.addClass('error-red');
		} else {
			birthday.removeClass('error-red');
			day = birthday.find("option:selected").text();
		}

		if (!birthyear.val()) {
			birthyear.addClass('error-red');
		} else {
			birthyear.removeClass('error-red');
			year = birthyear.find("option:selected").text();
		}
		
		if (birthdayField.find('.error-red').length > 0){
			return;
		}
		
		birthdate = month + '/' + day + '/' + year;
		
		$.ajax({
			type: 'post',
			url: Urls.setBirthday,
			contentType: 'application/json',
			data: JSON.stringify({
				email: email,
				month: month,
				day: day,
				year: year
			}),
			success: function (data) {
				$('#dwfrm_birthday').remove();
				$('.credential-list--birthdate').text(birthdate);
				
				if(SitePreferences.CORDIAL_ENABLED){				
					var auth_data = {
						email: email
					}
					var contact_data = {
					    'channels': {
					        'email': {
					            'address': email
					        }
					    },
					    'birthday': birthdate
					};
	
					if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
						crdl('contact', auth_data, contact_data);
					}
				}
			}
		});
	});
	
	if(siteName == SiteConstants.SiteIds.BootBarnUS && SitePreferences.PINTEREST_ENABLED){	
		if ($(document).find('.pt_product-details').length > 0) {
			setTimeout(function(){   	
				var thisItem = JSON.parse($('.productCardMetadata.pdp-browse').attr('data-product-gtm-json'));
				var productID = thisItem["id"];
				var dateTime = Math.floor(Date.now() / 1000);
				var eventID = 'pdp-' + productID + '-' + dateTime;

			  	pintrk('track', 'pagevisit', {
			  		event_id: eventID,
			  		line_items: [
			  			{
					  		product_name: thisItem["name"].replace(/\\/g, ""),
					  		product_id: productID,
					  		product_category: thisItem["category"].replace(/\\/g, ""),
					  		product_price: thisItem["price"],
					  		product_brand: thisItem["brand"]
			  			}
			  		]
			  	});
			}, 3000);					
		}
		
		// checkout event created on confirmation	
		if ($(document).find('.order-confirmation-details').length > 0) {
			var orderID = $('.orderdetails .sectionheader h3').text().replace('Order Number:','').trim();
			var dateTime = Math.floor(Date.now() / 1000);
			var eventID = 'checkout-' + orderID + '-' + dateTime;
			var currency = 'USD';
			
			var items = [];
			$('.order-item').each(function(index, el) {
				var _this = $(this);
				items[index] = {};
				_this.find('.productCardMetadata').each(function(key, v) {
					var thisItem = JSON.parse($(this).attr('data-product-gtm-json'));
					var currency = thisItem["currency"];
					var product = {};	
					product.product_name = thisItem["id"];
					product.product_variant_id = thisItem["skuid"];
					product.product_name = thisItem["name"].replace(/\\/g, "");
					product.product_brand = thisItem["brand"];
					product.product_quantity = thisItem["quantity"];
					product.product_price = thisItem["price"];
					items[index]= product;
				});
			});

			pintrk('track', 'checkout', {
				event_id: eventID,
				value: $('.orderdetails .total .order-total').text(),
				order_quantity: Math.round($('.orderdetails span.item-count').data('count')),
				currency: currency,
				order_id: orderID,
				line_items: items
			});
		}
	}
	
	// CORDIAL ACCOUNT SIGN-UP, BROWSE, CART, ORDER
	if(SitePreferences.CORDIAL_ENABLED){		
		// plp browse
		if ($(document).find('.pt_product-search-result').length > 0) {
			var maxTiles = 10;
			var products = [];
			$('.product-tile').each(function(i) {
				if (i < maxTiles) {
					var product = $(this).attr('data-itemid');
					products.push(product);
				}
			});
			
			var properties = {
			    "category": $('#categoryTitle').text().replace(/\\/g, "").trim(),
			    "url": $(location).attr("href").split("?utm")[0].split("#utm")[0].split("&utm")[0],
			    "products": products
			};
			
			if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
				crdl('event', 'category_browse', properties);
			}		
		}
		
		// pdp browse
		if ($(document).find('.pt_product-details').length > 0) {
			setTimeout(function(){   	
				var thisItem = JSON.parse($('.productCardMetadata.pdp-browse').attr('data-product-gtm-json'));
			  	var images = [];
				$('.product-primary-image [class*="-slide"]').each(function () {
					 var image = $(this).find('a').attr('href');
					 if (images.indexOf(image) === -1) {
						 images.push(image);
					 }
				});
				
			  	var properties = {
					"productID": thisItem["id"],
					"productName": thisItem["name"].replace(/\\/g, ""),
					"category": thisItem["category"].replace(/\\/g, ""),
					"price": thisItem["price"],
					"images": images,
			  		"url": $(location).attr("href").split("?utm")[0].split("#utm")[0].split("&utm")[0],
					"attr": {
			            "manufacturer": thisItem["brand"]
			        },
				};
				
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
			  		crdl('event', 'product_browse', properties); 
				}
			}, 3000);					
		}
		
		// add Cordial contact from checkout
		$('#submitBilling').on('click', function(e){
	    	e.preventDefault();
	    	if ($('.bill-address-fields .field.error').length == 0) {
				var email = $('#dwfrm_billingaddress_email').val().trim();
				var firstName = $('#dwfrm_billingaddress_firstName').val();
				var lastName = $('#dwfrm_billingaddress_lastName').val();
				var phone = $('#dwfrm_billingaddress_phone').val();
				var zip = $('#dwfrm_billingaddress_postal').val();
				var country = $('#dwfrm_billingaddress_country').val();
				var state = $('#dwfrm_billingaddress_states_state').val();
				var city = $('#dwfrm_billingaddress_city').val();
						
				if ($('input#newslettersignup').is(':checked')){
					var auth_data = {
						email: email
					}
					var contact_data = {
					    'channels': {
					        'email': {
					            'address': email,
					            'subscribeStatus': 'subscribed'
					        }
					    },
						'first_name': firstName,
						'last_name': lastName,
						'phone': phone,
						'postal_code': zip,
						'signup_source': 'checkout',
						'welcome_signup': true,
						'geo_location': {
						    'postal_code': zip,
						    'country': country,
						    'state': state,
						    'city': city
						}
					};
					
					var properties = {
					    "signup_source": 'checkout',
					    "welcome_signup": true
					};
		
					RequestHelper.Validate('Cordial-EmailSignup').then(function() {
						if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
							crdl([
								['contact', auth_data, contact_data],
								['event', 'email_signup', properties]
							]);
						}
					}).catch();
				}
	    	}
		});
		
		// update Cart when added on pdp and for qty change or removal in cart
		function cordialUpdateCart() {
			setTimeout(function(){
				var items = [];
				$('.order-item').each(function(index, el) {
					var _this = $(this);
					items[index] = {};
					_this.find('.productCardMetadata').each(function(key, v) {
						var thisItem = JSON.parse($(this).attr('data-product-gtm-json'));
						var product = {};	
						product.productID = thisItem["id"];
						product.sku = thisItem["skuid"];
						product.category = thisItem["category"].replace(/\\/g, "");
						product.name = thisItem["name"].replace(/\\/g, "");
						product.qty = thisItem["quantity"];
						product.itemPrice = thisItem["price"];
						product.images = [_this.find('img').attr('src')];
						product.url = window.location.origin + _this.find('.name').find('a').attr('href').split("?utm")[0].split("#utm")[0].split("&utm")[0];
						product.attr = {'manufacturer': thisItem["brand"], 'color': thisItem["color"], 'size': thisItem["size"] };
						items[index]= product;
					});
				});
				
				var cart_data = items;
		
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
					crdl([
						['cart', 'clear'],
						['cartitem', 'add' , cart_data], 
						['event', 'cart']
					]); 
				}
			}, 3000);
		}
		
		function cordialClearCart() {
			setTimeout(function(){
				var properties = {
				    'status': 'empty'
				};
				
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
					crdl([
						['cart', 'clear'],
						['event', 'cart', properties]
					]); 
				}
			}, 3000);
		}
		
		if ($(document).find('.pt_cart').length > 0 || $(document).find('.pt_onepcheckout').length > 0) {
			if($('.order-item').length > 0){
				cordialUpdateCart();
		    } else {		    	
		    	cordialClearCart();
		    }
		}
		
		$('body').on('change', 'input.opc-qty', function(event) {
			cordialUpdateCart();
		});
		
		$('body').on('click', '.cart-change', function(event) {
			setTimeout(function(){
				if($('.order-item').length == 0){
					cordialClearCart();
			    } else {
			    	cordialUpdateCart();
			    }	
	    	}, 2000);
		});
		
		// order event created on confirmation; myaccount signup	
		if ($(document).find('.order-confirmation-details').length > 0) {
			var orderID = $('.order-history-info span.value').first().text().trim();
			if (siteName == SiteConstants.SiteIds.BootBarnUS) {
				var orderID = $('.orderdetails .sectionheader h3').text().replace('Order Number:','').trim();
			}
			var email = $('.order-billing-email').text().trim();
			var shippingName = $('.order-shipping-name').text().trim();
			var shippingAddress2 = $('.order-shipping-address2').text().trim();
			var shippingAddress3 = $('.order-shipping-address3').text().trim();
			var shippingAddress = $('.order-shipping-address1').text().trim();
			if(shippingAddress2) { 
				shippingAddress += ", " + shippingAddress2;
			}
			if(shippingAddress3) { 
				shippingAddress += ", " + shippingAddress3;
			}
			var shippingCity = $('.order-shipping-geo .city').text().trim().replace(',','');
			var shippingState = $('.order-shipping-geo .state').text().trim();
			var shippingZip = $('.order-shipping-geo .zip').text().trim();
			var shippingCountry = $('.order-shipping-country').text().trim();
			var billingName = $('.order-billing-name').text().trim();
			var billingAddress2 = $('.order-billing-address2').text().trim();
			var billingAddress3 = $('.order-billing-address3').text().trim();
			var billingAddress = $('.order-billing-address1').text().trim();
			if(billingAddress2) { 
				billingAddress += ", " + billingAddress2;
			}
			if(billingAddress3) { 
				billingAddress += ", " + billingAddress3;
			}
			var billingCity = $('.order-billing-geo .city').text().trim().replace(',','');
			var billingState = $('.order-billing-geo .state').text().trim();
			var billingZip = $('.order-billing-geo .zip').text().trim();
			var billingCountry = $('.order-billing-country').text().trim();
			var billingPhone = $('.order-billing-phone').text().replace('Phone:','').trim();
			
			var name = billingName.split(' ');
			var firstName = name[0];
			var lastName = name[name.length-1];
			
			var items = [];
			$('.order-item').each(function(index, el) {
				var _this = $(this);
				items[index] = {};
				_this.find('.productCardMetadata').each(function(key, v) {
					var thisItem = JSON.parse($(this).attr('data-product-gtm-json'));
					var product = {};	
					product.productID = thisItem["id"];
					product.sku = thisItem["skuid"];
					product.category = thisItem["category"].replace(/\\/g, "");
					product.name = thisItem["name"].replace(/\\/g, "");
					product.qty = thisItem["quantity"];
					product.itemPrice = thisItem["price"];
					product.images = [_this.find('img').attr('src')];
					product.url = window.location.origin + _this.find('.name').find('a').attr('href');
					product.attr = {'manufacturer': thisItem["brand"], 'color': thisItem["color"], 'size': thisItem["size"] };
					items[index]= product;
				});
			});
			
			var order_data = {
			  "orderID": orderID,
			  "shippingAddress": {
			    "name": shippingName,
			    "address": shippingAddress,
			    "city": shippingCity,
			    "state": shippingState,
			    "postalCode": shippingZip,
			    "country": shippingCountry
			  },
			  "billingAddress": {
				"name": billingName,
				"address": billingAddress,
				"city": billingCity,
				"state": billingState,
				"postalCode": billingZip,
				"country": billingCountry
			  },
			  "items": items
			};
			
			var properties = {
			    "orderID": orderID
			};
						
			var auth_data = {
				email: email
			}
			var contact_data = {
			    'channels': {
			        'email': {
			            'address': email
			        }
			    },
			    'first_name': firstName,
				'last_name': lastName,
				'phone': billingPhone,
				'postal_code': billingZip,
				'source_create': 'order',
				'geo_location': {
				    'postal_code': billingZip,
				    'country': billingCountry,
				    'state': billingState,
				    'city': billingCity
				}
			};				
			if (typeof crdl !== 'undefined' && typeof crdl === 'function') {	
				crdl([
					['contact', auth_data, contact_data],
					['cart', 'clear'],
					['order', 'add', order_data],
					['event', 'order', properties]
				]);
			}
			
			// create Cordial contact on confirmation page
			$(document).on('click','.largebutton[name="dwfrm_profile_confirm"]', function(){
				var password = $('.password-holder .password').val();
				var passwordConfirm = $('.password-holder .password-confirm').val();
				var formValidation = true;
				var signupEvent = "";

				var regexPassword = /^(?=.*?[A-z])(?=.*?[0-9])(?=.*?[#?!\=\/\,\.\~\`\(\)\{\}\[\]\\@_$%^&*-]).{6,15}$/g;
				if ( String(password).match(regexPassword) == null ) {
					formValidation = false;
				}
				
				if (password !== passwordConfirm) {
					formValidation = false;
				}
				
				if (!password || !passwordConfirm) {
					formValidation = false;
				}
				
				if (formValidation && $('#RegistrationForm .field.error').length == 0){					
					var auth_data = {
						email: email
					}
					
					var contact_data = {
					    'channels': {
					        'email': {
					            'address': email
					        }
					    },
						'first_name': firstName,
						'last_name': lastName,
						'phone': billingPhone,
						'postal_code': billingZip,
						'source_create': 'myaccount',
						'geo_location': {
						    'postal_code': billingZip,
						    'country': billingCountry,
						    'state': billingState,
						    'city': billingCity
						}
					};
					
					var loginProperties = {
					    "email": email
					};
					
					if ($('input#newslettersignup').is(':checked')){					
						var contact_data = {
						    'channels': {
						        'email': {
						            'address': email,
						            'subscribeStatus': 'subscribed'
						        }
						    },
							'first_name': firstName,
							'last_name': lastName,
							'phone': billingPhone,
							'postal_code': billingZip,
							'signup_source': 'myaccount',
							'source_create': 'myaccount',
							'welcome_signup': true,
							'geo_location': {
							    'postal_code': billingZip,
							    'country': billingCountry,
							    'state': billingState,
							    'city': billingCity
							}
						};
						
						var properties = {
						    "signup_source": 'myaccount',
						    "page": 'confirmation',
						    "welcome_signup": true
						};
						
						signupEvent = ['event', 'email_signup', properties];
					}
							
					RequestHelper.Validate('Cordial-EmailSignup').then(function() {
						if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
							crdl([				
								['contact', auth_data, contact_data],
								['event', 'account_create'],
								['event', 'login', loginProperties],
								signupEvent
							]);
						}
					}).catch();
				}
			});
		}
		
		// Password Reset - No Account
		if ($(document).find('.reset-password .no-account').length > 0) {
			var email = $('.no-account').text();
	
			var auth_data = {
				email: email
			}
			var contact_data = {
			    'channels': {
			        'email': {
			            'address': email
			        }
			    }
			};
			
			var properties = {
			    "email": email
			};

			if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
				crdl([
					['contact', auth_data, contact_data],
					['event', 'reset-password-no-account', properties]
				]);
			}
		}
		
		// Password Reset Confirmation
		if ($(document).find('#password-reset-confirm').length > 0) {
			var email = $('#password-reset-confirm').attr('data-email');
			var passwordEvent = "reset-password";
			
			if ($(document).find('.noAccount').length > 0) {
				var passwordEvent = "reset-password-no-account";
			}
			
			var auth_data = {
				email: email
			}
			var contact_data = {
			    'channels': {
			        'email': {
			            'address': email
			        }
			    }
			};
			
			var properties = {
			    "email": email
			};

			if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
				crdl([
					['contact', auth_data, contact_data],
					['event', passwordEvent, properties]
				]);
			}
		}
	}
	//END CORDIAL

	
	// Reloading login page after unsuccessful attempt w/ error messaging,
	// and retaining username if 'Remember me' was checked
	if (window.location.href.indexOf('retry') >= 0) {
        $('.login-dialog-error .invalid-login').removeClass('d-none');
		if (window.location.href.indexOf('=') >= 0) {
			var username = window.location.href.split('=')[1];
			$('input[name*="dwfrm_login_username"]').val(username);
			$('input[name*="dwfrm_login_rememberme"]').prop('checked', true);
		}
	}
	 
	// Make this my store click delegation
	$(document).on('click','.assign-store-container .assign-store', function(e){
		e.preventDefault();
		var storeID = $(this).parents('.store').data('id');
		$.ajax({
			url : Urls.assignStoreId,
			data : { store_id : storeID },
			method : "POST",
			dataType : "JSON",
			success : function(data) {
				if ( 'success' in data && data['success'] == true ) {
					location.reload();
				}
			}
		});
	});
	
	$(document).on('click', '.review', function(e){
		if (typeof $BV !== 'undefined') {
			var productID = $(this).data('product-id');
			var campaignID = $(this).data('campaign-id');
			if (typeof productID !== 'undefined') {
				productID = productID.toString();
				if (productID.length > 0) {
					e.preventDefault();
					
					if (typeof $BV !== 'undefined') {
						$BV.ui("rr", "submit_review", {productId: productID, campaignId: campaignID});
					}
				}
			}
		}
	});
	
	//BazaarVoice - click on pdp inline rating scrolls down to reviews tab
	$(document).off('click.ScrollToReviews').on('click.ScrollToReviews', '.pdp-main .rating-container', '.bv-content-pagination-buttons-item .bv-content-btn', function(e) {
		e.preventDefault();
		var scrollTop = $('.product-reviews-container').offset().top - 10;
		
		$('html, body').animate({
	        scrollTop: scrollTop
	    }, 250);
	});

	//BazaarVoice syndication message
	setInterval(function() {
		if(siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
			if($('#BVRRContainer').length){
				$('.bv-content-item').each(function(){
					var originPost = $(this).find('.bv-content-reference-data .bv-origin-post');
					if(originPost.length < 1){
				    	var referenceMsg = $(this).find('.bv-badge-syndicated').text();
				    	$(this).find('.bv-content-reference-data').append('<span class="bv-origin-post">' + referenceMsg + '</span>');
					}
				});
			}
		}
	}, 500);
	
	//BazaarVoice - hide empty inline ratings and reviews
	if (pageContext && 'type' in pageContext && pageContext.type == 'product') {  
		setTimeout(function() {
	    	var reviewCount = $('.bv_numReviews_component_container .bv_text').first().text().replace(/[()]/g,"");
	    	if(parseInt(reviewCount) == 0 || $('.rating-container .img-separator').length){
	    		$('.rating-container').hide();
	    		$('.product-reviews-container').hide();
	    	}
	    }, 2500);
	}
	
	//BazaarVoice - injecting stylesheet that overrides v2 shadow dom elements
	function addStyle(styles) {
		var css = document.createElement('style');
		css.type = 'text/css';
	  
		if (css.styleSheet){
			css.styleSheet.cssText = styles;
		} else {
			css.appendChild(document.createTextNode(styles));
		}
		
		var bvElement = document.querySelector('div[data-bv-show="reviews"]');
		if (bvElement && bvElement.shadowRoot) {
			bvElement.shadowRoot.appendChild(css);
		}
	}
     
    var styles = '.jsQeHX.WwxUI, .hRrcFz.WwxUI, .iFcEcv, .cXVdAC, .jsBsQs h3, .jqkdD, .gPsONF, .dVMqmv, .edZJjR, .hkyTvk, .dYLjyG, .fyeCcH, .fFNqWB, .lpnWEu, .dmcWUN { display: none !important }';
    styles += '.bv_rating_content2, .bv_rating_content3, div[role="presentation"] { display: none; flex: none }';
	styles += '.cAtgnP { height: 100%; margin: 0 10px; padding-top: 10px }';
	styles += '.jsQeHX.jsBsQs { margin: -20px 0 5px }';
	styles += '.lTfDK, .eUaoSK, .jdEYYu, .gpRfFu { padding: 0px 16px; border-radius: unset }';
	styles += '.jYCRe { border-radius: unset }';
	styles += '.eUaoSK:focus { border-color: #393939 }';
	styles += '.jTsSWZ { top: 2px }';  
	styles += '.grdlqX.jHUsCh { color: #393939 }';
	styles += '.ewPPY { border-bottom: none }';
	styles += '.jOJVrV, .hiOSWf, .hiOSWf:hover { color: #ffffff; border-radius: unset }';
	styles += 'div[itemprop="reviewRating"], .gaVmkF, .isujDp { display: inline-flex; vertical-align: top; padding: 6px 12px 0 0 }';
	styles += '.dpKFPg .gaVmkF { padding: 3px 12px 0 0 }';
	styles += '@media (max-width: 767px) { div[itemprop="reviewRating"], .gaVmkF, .isujDp { display: block; overflow: hidden }}';
	styles += '.eGdSeJ, .eDeQCM, .bmAjQc { border-radius: unset }';
	styles += '.gCFpGN { border-color: #7a7a7a; border-radius: unset }';
	styles += '#reviews_container { margin-bottom: 25px }';
	styles += 'div[data-bv-v="contentItem"], .eYSWxm, .iDMzsn, .kuAUQn { border-top: 1px solid #d2d2d0; border-bottom: none; padding: 5px }';
	styles += '.isujDp, .ijcDoa { display: inline; width: auto }';
	styles += '.ljNKOA, .eDZdDN { padding: 5px 0 0 }';
	styles += '.kiucBp { width: 14px; height: 14px }';
	styles += 'div[data-bv-v="contentHeader"], .hXbnlk, .kMYzbs, .hvfaWI, .hsSPAB, .hjojyB { width: 100%; padding: 0px }';
	styles += 'h3.fXHeKf, h3.fCOyrM, h3.iCmYvO, h3.kCKagG, h3.beXXjZ, h3.eLoCMw { display: inline; font-size: 15px; font-weight: bold; margin: 0}';
	styles += 'h3.kCKagG { line-height: 30px }';
	styles += '@media (max-width: 767px) { h3.fXHeKf, h3.fCOyrM { line-height: 20px }}';
	styles += '@media (max-width: 767px) { h3.iCmYvO { line-height: 20px; display: block; padding: 4px 0 }}';	
	styles += '.jPLiFm { margin: 10px 0px }';
	styles += '.fktUhc { margin-top: 0px }';
	styles += '.bSBrrQ { display: flex; align-items: center; margin-bottom: 18px }';
	styles += '.bSBrrQ .DGfVS, .bSBrrQ .eDQqsO, .bSBrrQ .fqoJXd, .bSBrrQ button { display: inline; font-style: normal !important }';
	styles += '.bSBrrQ .DGfVS h3, .bSBrrQ .eDQqsO h3, .bSBrrQ .fqoJX h3 { display: inline; margin-right: 10px; font-weight: normal }';
	styles += '.hYfEUK { width: auto; margin-right: 10px }';
	styles += '.bSBrrQ button { display: inline-flex; width: auto; font-weight: unset !important; margin-right: 10px }';
	styles += '.bSBrrQ button meta { display: inline; }';
	styles += '.bSBrrQ .ftAYOi, .bSBrrQ .dxQtDU, .bSBrrQ .bNKApu, .bSBrrQ .dxQtDU { display: inline; min-width: unset; max-width: unset }';
	styles += '.bSBrrQ .dxQtDU, .bSBrrQ .jWGhqr { font-style: italic }';
	styles += '@media (max-width: 767px) { .bSBrrQ button { width: auto; font-style: unset !important; margin-right: 10px }}';
	styles += '.BdbdY, .hcnLga, .ktOlkc { margin: 15px 0px 10px }';
	styles += '.BdbdY div, .hcnLga div, .ktOlkc div { padding: 5px 10px; border-radius: unset }';
	styles += 'li[role="presentation"] { display: inline-flex }';
	styles += 'li[role="presentation"] button { height: 40px; width: 40px; border-radius: unset }';
	styles += 'li[role="presentation"] a { height: 40px; width: 40px; border-radius: unset; padding-top: 10px }';
	styles += 'li[role="presentation"] a:hover { background-color: #efefef }';
	styles += '.gocwuF { border-radius: unset; margin-bottom: 0 }';
	styles += '.kxUynv, .kndPnx { border-radius: unset; margin-bottom: 5px }';
	styles += 'div[data-bv-v="contentSummary"], .bBuIFZ, .EFCyH, .kyNJgB { margin: 0; padding: 0 }';
	styles += '@media (max-width: 767px) { .EFCyH { padding: 2px 2px 0px; }}';
	styles += '.kssGoV { background: none }';
	styles += '.jruHEc { margin: 0px }';
	styles += '.PNCvp { color: #ffffff }';
	styles += '.hQzHbm { height: 500px }';
	styles += '.hQzHbm .cAtgnP { margin: 0; padding-top: 0 }';
	styles += '.hQzHbm .hquDrt { height: calc(95vh) }';
	styles += '.hQzHbm div[data-bv-v="contentItem"] { border: none; padding: 5px }';
	styles += '.hQzHbm .icytl { font-size: 20px }';
	styles += '.hQzHbm .icytl svg { width: 20px; height: 20px }';
	styles += '.hQzHbm h3.kCKagG, .hQzHbm h3.beXXjZ, .hQzHbm h3.eLoCMw { font-size: 20px; line-height: 25px }';

	if (pageContext && 'type' in pageContext && pageContext.type == 'product') {  
		setTimeout(function() {
			addStyle(styles)
		}, 2500);
	}
    
    /*//BazaarVoice add Review/Reviews text to inline ratings count 
	setTimeout(function() {
    	var reviewCount = $('.bv_numReviews_component_container .bv_text').first().text().replace(/[()]/g,"");
    	var reviewText = parseInt(reviewCount) > 1 ? Resources.REVIEWS : Resources.REVIEW_TEXT;
    	$('.bv_numReviews_component_container .bv_text').text('(' + reviewCount + ' ' + reviewText + ')');
    }, 3000);

	setInterval(function() {
		CheckloginCreate();
	}, 2000);*/
	
	$('.section-group .section-body:last').append($('.section-reviews'));

	$(".pt_customer-service #secondary .nav-title").click(function() {
		$(this).toggleClass('active');
		$(".pt_customer-service #secondary #customer-service-nav").slideToggle();
	});	

	searchbar.init();
		
	if (siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
		var btn = $('#back-to-top'),
		scroll_position = 2100;
		
		if (window.innerWidth <= 767) {
			scroll_position = 2771;
		}
		
		if (window.innerWidth > 767) {
    		$('#main').prepend('<div class="menu-hover-overlay" style="display:none;"></div>');
	    	
	    	$('.has-dropdown.nav-menu').hover(
				function() {
					$('#wrapper').addClass('menu-active');
				},
				function() {
					$('#wrapper').removeClass('menu-active');
				}
    	    );
    	}
		
		$(window).scroll(function() {
		  if ($(window).scrollTop() > scroll_position) {
		    btn.addClass('show');
		  } else {
		    btn.removeClass('show');
		  }
		});

		btn.on('click', function(e) {
		  e.preventDefault();
		  $('html, body').animate({scrollTop:0}, scroll_position);
		});

		if($('.homepage-03 .content-asset-body').length >= 1) {
			var slot_position = $('.homepage-03 .content-asset-body').attr('slot-position').trim();
			if(slot_position != '3'){
				var promo_slot = $('.shell.homepage-03').html(),
				before_this_slot = '.homepage-0' + parseInt(slot_position, 10);
				$('.shell.homepage-03').remove();
				
				if(slot_position > 3 && slot_position < 8){
					before_this_slot = '.homepage-0' + (parseInt(slot_position, 10) + 1);									
				}
				if(slot_position > 7){
					$('.shell' + before_this_slot).after('<div class="shell homepage-03">' + promo_slot + '</div>');
				} else {
					$('.shell' + before_this_slot).before('<div class="shell homepage-03">' + promo_slot + '</div>');
				}
			}
		}
	}

	var brands_count = $('.brand').length;
	var styles_count = $('.style').length;
	var toestyle_count = $('.toe-style').length;

	if(brands_count < 16) {
		$('.pt_content .viewallbrands').hide();
	}

	if(styles_count < 8) {
		$('.pt_content .viewallstyles').hide();
	}

	if(toestyle_count < 8) {
		$('.pt_content .viewalltoes').hide();
	}

	$(document).on('click','.ca-header-contact-info',function(event) {
		if(window.innerWidth > 767) {
			event.preventDefault();
		}
	});
	
	/*if(window.innerWidth < 767 ) {
		if(siteName == SiteConstants.SiteIds.CountryOutfittersUS) {
			$('.product-listing-1x4 .grid-tile').each(function(){
				$(this).find('.product-content').before('<div class="savings-bar"></div>');
			    if($(this).find('.product-sales-price').length !== 0){
			    	var $promoMsg = $(this).find('.product-sales-price').text().trim().replace('You Save:', 'Save');
			    	$(this).find('.savings-bar').css('background-color','#E3ECEE').text($promoMsg).show();
			    }	
			});
		}
	}*/

	if(window.innerWidth < 767 ) {
		if(brands_count > 8) {
			$('.pt_content .viewallbrands').show();
		}

		$('.pt_content .style:nth-child(n+9)').css({'display':'none'});
		$('.pt_content .toe-style:nth-child(n+9)').css({'display':'none'});

		if ($('.top-banner-bar').attr('data-header-for') != 'bootbarn_us') {
			$('.pt_storefront .list-review-products li .offer-list').slideUp();
			$('.pt_storefront .offer-head h2').addClass('close');
		}
	} else {
		$('.pt_content .style:nth-child(n+9)').css({'display':'block'});
		$('.pt_content .toe-style:nth-child(n+9)').css({'display':'block'});
	}

	$(document).on('click','.pt_content .viewallbrands',function(event) {
		event.preventDefault();
		if($('.pt_content .viewallbrands').hasClass("expanded")) {
			$('.pt_content .viewallbrands').removeClass("expanded");
			$('.pt_content .brand:nth-child(n+9)').css({'display':'none'});
			$('.pt_content .viewallbrands').html(Resources.SHOWMOREBRANDS);
		} else {
			$('.pt_content .viewallbrands').addClass("expanded");
			$('.pt_content .brand:nth-child(n+9)').css({'display':'block'});
			$('.pt_content .viewallbrands').html(Resources.CLOSE);
		}
	});

	$(document).on('click','.pt_content .viewallstyles',function(event) {
		event.preventDefault();
		if($('.pt_content .viewallstyles').hasClass("expanded")) {
			$('.pt_content .viewallstyles').removeClass("expanded");
			$('.pt_content .style:nth-child(n+9)').css({'display':'none'});
			$('.pt_content .viewallstyles').html(Resources.SHOWMORESTYLE);
		} else {
			$('.pt_content .viewallstyles').addClass("expanded");
			$('.pt_content .style:nth-child(n+9)').css({'display':'block'});
			$('.pt_content .viewallstyles').html(Resources.CLOSE);
		}
	});

	$(document).on('click','.pt_content .viewalltoes',function(event) {
		event.preventDefault();
		if($('.pt_content .viewalltoes').hasClass("expanded")) {
			$('.pt_content .viewalltoes').removeClass("expanded");
			$('.pt_content .toe-style:nth-child(n+9)').css({'display':'none'});
			$('.pt_content .viewalltoes').html(Resources.SHOWMORETOE);
		} else {
			$('.pt_content .viewalltoes').addClass("expanded");
			$('.pt_content .toe-style:nth-child(n+9)').css({'display':'block'});
			$('.pt_content .viewalltoes').html(Resources.CLOSE);
		}
	});

	$(document).on('click', '.swatch-list .swatch:not(.selected) [class*="swatch-im"]', function() {
		var $parentBlock = $(this).closest('.slide, .swatch-list');
		var $mainImg = $parentBlock.find('.product-image img:first');
		var swatchData = $(this).data('thumb');
		
		if (swatchData) {
			$mainImg.attr('alt', swatchData.alt);
			$mainImg.attr('title', swatchData.title);
			$mainImg.attr('src', swatchData.src);
			
			$parentBlock.find('.swatch').removeClass('selected');
			$(this).closest('.swatch').addClass('selected');
		} else {
			window.location.href = $(this).closest('.swatch').attr('href');
		}
	});
	
	$(document).on('click','.yes-alts button.altButton', function(e){
		e.preventDefault();
		var altHiddenCount = $(this).closest('.yes-alts ul.swatch-list').find('.hidden-content').length;
		var altHiddenToggle = '+ ' + altHiddenCount;
		$(this).text( $(this).text().indexOf('+') >= 0 ? "- Less" : altHiddenToggle);
		$(this).closest('.yes-alts ul.swatch-list').find('.hidden-content').toggle();
	});

	//Terms and Conditions Popup on Checkout
	$(document).off('click', '[dialog-content]').on('click', '[dialog-content]', function(event) {
        event.preventDefault();
        
        var $this = $(event.currentTarget);
        var $contentContainer = $($this.attr('dialog-content'));
        
        dialog.open({
            html: $contentContainer.html(),
            options: {
                title: $contentContainer.attr('title'),
                dialogClass: 'fixed checkout-terms' + ($contentContainer.attr('dialog-class') ? ' ' + $contentContainer.attr('dialog-class') : ''),
                draggable: false,
                open: function(event, ui) {
                	var $this = $(this);
                	
	            	$('body').addClass('dialog-open');
	            	$this.scrollTop(0);
	            	emailForm.initialize($this);
	            },
	            close: function(event, ui) {
	            	$('body').removeClass('dialog-open');
	            }
            }
        });
	});

	//Page Top functionality for popup content in terms and conditions
	$(document).off('click', '.ui-dialog [href="#pgTop"]').on('click', '.ui-dialog [href="#pgTop"]', function(event) {
    	event.preventDefault();
    	
    	var $this = $(event.currentTarget);
    	$this.closest('.ui-dialog-content').scrollTop(0);
    });

	$('.video-play-btn')
	.on('click', function(e) {
		var $this = $(this);
		var $video = $this.closest('.video');
		var $iframe = $video.find('iframe');
		var originalSRC = $video.find('iframe').attr('src').replace('?autoplay=1','');
		var videoHtml = $(this).next('iframe').prop('outerHTML');
		videoHtml = videoHtml.replace(/src=".+?"/, 'src="' + originalSRC + '?autoplay=1"');
		dialog.open({
            html:(videoHtml),
            options: {
            	width: 560,
                dialogClass: 'videopopup',
                title: $video.find('.video-caption').text(),
                close: function() {
                	$('.ui-dialog').find('iframe').attr('src', '');
                }
            },
		});

		e.preventDefault();
	})

	
	//Prepare for touch/menu
	if (util.isTouchDevice()) {
		$('body').addClass('touchDevice');
	}
	if(window.navigator.platform.match("i(Win)")){
		$('body').addClass('isWin')
	}
	
	if (siteName != SiteConstants.SiteIds.BootBarnUS && siteName != SiteConstants.SiteIds.SheplersUS) {
		if(window.innerWidth < 767) {
			if ($('.header-banner').children().length == 0) {
			$('.top-banner').addClass('no-top');
			}
		}
	}
	
    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        })
        .on('click','.pt_storefront .offer-head h2',function(event) {
        	event.preventDefault();
        	if(window.innerWidth < 767 && $('.top-banner-bar').attr('data-header-for') != 'bootbarn_us') {
	        	$(this)
	        		.toggleClass('close')
	        		.closest('li')
	        		.find('.offer-list')
	        		.slideToggle(200);
			} else if ($('.top-banner-bar').attr('data-header-for') == 'bootbarn_us') {
				$(this)
					.toggleClass('active')
					.closest('li')
					.toggleClass('active')
					.find('.offer-list')
					.toggleClass('active')
					.slideToggle(200);
			}
		})
		.on('click', '.footer-item .content-asset h3', function(event) {
			event.preventDefault();
			
			if(window.innerWidth < 1025) {
				$(this)
					.toggleClass('active')
					.closest('.footer-item')
					.siblings()
					.find('.active')
					.removeClass('active');

				$(this)
					.next()
					.stop()
					.slideToggle()
					.closest('.footer-item')
					.siblings()
					.find('.menu-footer')
					.slideUp();
			}
		})
		.on('click', '.btn-expand', function (event) {
			event.preventDefault();

			var element = $(this).attr('href');
			
			if($('.sticky-menu').length > 0) {
				var element = element  +  ".search-mobile";
			}
			
			$(element).toggleClass('show');
			
            if($('.sticky-menu').length > 0) { 
                $('#main').toggleClass('search-open');
            }

			if($('.menu-toggle').hasClass('active')) {
				navigation.CloseNavigation();
			}

			if($('#mini-cart').hasClass('active')) {
				$('#mini-cart .mini-cart-link').removeClass('active');
				$('#mini-cart').removeClass('active');
			}
			
			if($('.second-mobnav').hasClass('active')) {				
				$('.second-mobnav').removeClass('active')
				$('#second-mobnav').slideUp();
			}
			
			if (element == '#search' || element == '#search.search-mobile') {
				$(document).trigger('SearchBar.Expand');
            }
		})
		.on('click', '.btn-location-redirect', function(event){
			event.preventDefault();
			var linkRedirectStore = $(this).find('span').attr('loc-link');
			if (linkRedirectStore != null) {
				location.href = linkRedirectStore;
			}
		})
		.on('click', '.btn-location', function(event) {
			event.preventDefault();

			if($('#mini-cart').hasClass('active')) {
				$('#mini-cart .mini-cart-link').removeClass('active');
				$('#mini-cart').removeClass('active');
			}

			$('#navigation')
				.stop()
				.slideToggle();

			navigation.CloseNavigation();
			
			var element = $(this).attr('href');

			$(element)
				.find('.nav-secondary-dropdown')
				.stop()
				.slideToggle()
				.parent()
				.siblings()
				.find('.nav-secondary-dropdown')
				.slideUp();
		})
		.on('click', '.jumbotron-current-offers', function (event) {
			$('.jumbotron-mobile').toggleClass('active');
			if($('#jumbotron-current-offers-icon').hasClass('fa-angle-up')) {
				$('#jumbotron-current-offers-icon').removeClass('fa-angle-up');
				$('#jumbotron-current-offers-icon').addClass('fa-angle-down');
				setCookie('jumbotronExpandedCookie', false, 2);
			} else {
				$('#jumbotron-current-offers-icon').removeClass('fa-angle-down');
				$('#jumbotron-current-offers-icon').addClass('fa-angle-up');
				setCookie('jumbotronExpandedCookie', true, 2);
			}
		})
		.on('click', '.second-mobnav', function (event) {
			event.preventDefault();
			
			$(this).toggleClass('active');
			
			$('#second-mobnav')
			.stop()
			.slideToggle(150);
			
			if($('.menu-toggle').hasClass('active')) {
				$('.menu-toggle').removeClass('active');
				$('html').removeClass('menu-active');
			}
			 
			if($('#mini-cart').hasClass('active')) {
				$('#mini-cart .mini-cart-link').removeClass('active');
				$('#mini-cart').removeClass('active');
			}
			$(document).trigger('SearchBar.Hide');
		})
		
		.on('click', '.accordion > a', function (event) {
			var $container = $(this).closest('.accordion');
			$container.find('ul').slideToggle('fast');
		})
/*
		.on('touchstart', function(event)  {
    		if (!$(event.target).closest('#navigation').length && !$(event.target).is('#navigation') && $win.width() > 767 )  {
    		    $('.level-2').stop().slideUp(400);
    		}  
    	})
*/
		
		.on('mouseover', '#mini-cart', function () {
			if(window.innerWidth > 1024) {
				$(this)
					.find('.mini-cart-link')
					.addClass('active');

				$(this)
					.addClass('active');
			}

			if($('#navigation').hasAttr('style')) {
				$('.menu-toggle').removeClass('active');
			}
			$(document).trigger('SearchBar.Hide');
		})
		.on('mouseleave', '#mini-cart', function () {
			var $this = $(this);

			if(window.innerWidth > 1024) {
				setTimeout(function () {
					$this
						.find('.mini-cart-link')
						.removeClass('active');

					$this
						.removeClass('active');
				}, 1000);
			}
		})
		.on('click', '.edit-search-query', function () {
			$('.category-popup-list-stores').dialog('close');
			$('.grid-page-storeselector-popup').dialog('open');
		})
		.on('click', '.popup-list-stores input[name="apply"]', function () {
			if (!$('input[name="store"]:checked').val()) {
				alert('Select one of stores');

				return;
			}

			$.ajax({
				type: 'get',
				url: Urls.setDefaultStore,
				data: {
					storeId: $('input[name="store"]:checked').val()
				},

				success: function (data) {
					$('.category-popup-list-stores').dialog('close');
					document.location.reload();
				}
			});
		})
		
		.on('click', '.pt_product-search-result .category-slot a', function(event) {
			var refinementValue = $(event.target).closest('[data-link-attribute-value]').data('link-attribute-value');
			var isRefinementLink = refinementValue != undefined;

			if (isRefinementLink) {	
				var $sidebarRefinement = $('#refinements-inner').find('#field-' + refinementValue);
				var urlRefinementData = getUrlRefinementData();

				if ($sidebarRefinement.length > 0) {
					event.preventDefault();
					$sidebarRefinement.trigger('click');
				} else if (urlRefinementData.hasRefinements) {
					event.preventDefault();
					var refinementId = $(event.target).closest('[data-link-attribute-id]').data('link-attribute-id');
					var refinementIsAlreadySelected = false;
					var redirectUrl;

					for (var refinement in urlRefinementData.parameters) {
						if (urlRefinementData.parameters[refinement] === refinementValue) {
							refinementIsAlreadySelected = true;
						}
					}

					if (!refinementIsAlreadySelected) {
						redirectUrl = window.location.href + '&prefn' + urlRefinementData.newRefinementStartNumber + '=' + refinementId + '&prefv' + urlRefinementData.newRefinementStartNumber + '=' + refinementValue;
						window.location.href = redirectUrl;
					}
				}
			}
		});

		$(document).on('click', '.thumb-link, .name-link', function(event) {
			var $this = $(this);
			var $container = $this.closest('.accordion, .section-offers, .tiles, .cat-header-content.cat-header-slider').first();
			if ($container.length > 0) {
				event.preventDefault();

				if ($container.find('.product-tile').length > 0) {
					var containerId = $container.attr('content-slot-id') || $container.attr('id');
	
					var dataObject = {
						promotion_name: containerId
					}

					var pageType;
					var $wrapper = $this.closest('.add-to-cart-modal');
					if ($wrapper.length > 0) {
						pageType = 'addtocartmodal';
					}

					if (!pageType) {
						$wrapper = $('#wrapper');
						if ($wrapper.length > 0) {
							if (pageContext && pageContext.type) {
								switch (pageContext.type) {
									case 'storefront':
									pageType = 'Homepage';
									break;
	
									case 'search':
									pageType = 'PLP';
									break;
	
									case 'product':
									pageType = 'PDP';
									break;
	
									case 'cart':
									pageType = 'Cart';
									break;
	
									case 'onepcheckout':
									case 'onepcheckout_groups':
									pageType = 'Checkout';
									break;
	
									case 'orderconfirmation':
									pageType = 'Order Confirmation';
									break;
	
									case 'customerservice':
									pageType = 'Customer Service';
									break;
	
									case 'account':
									pageType = 'Account';
									break;
	
									case 'content':
									pageType = 'Content Page';
									break;
	
									case 'error':
									pageType = 'Error Page';
									break;									
								}
							}
						}
					}

					if (pageType) {
						dataObject.promotion_id = pageType;
					}

					var redirectHandler = function() {
						location.href = $this.attr('href');
					};
	
					GoogleTagManagerClient.PushDataLayerForDataLayerType('SelectPromotion', dataObject, { Container: $this.closest('.product-tile') }).then(redirectHandler).catch(redirectHandler);
				}
			}
		});

		$(document).on('click', '[content-asset-id] a, [content-asset-id] button', function(event) {
			var $this = $(this);
			var isLink = $this.attr('href') && $this.attr('href').length > 0 && $this.attr('href').indexOf('//') >= 0;
			var isClose = $this.attr('class') && $this.attr('class').length > 0 && $this.attr('class').indexOf('close') >= 0;

			if ($this.closest('.product-tile').length == 0 && !isClose) {
				var $container = $this.closest('[content-asset-id]').first();
				if ($container.length > 0) {
					if (isLink) {
						event.preventDefault();
					}

					var dataObject = {
						content_type: 'content',
						item_id: $container.attr('content-asset-id')
					}

					var redirectHandler = function() {
						if (isLink) {
							location.href = $this.attr('href');
						}
					};
		
					GoogleTagManagerClient.PushDataLayerForDataLayerType('SelectContent', dataObject).then(redirectHandler).catch(redirectHandler);
				}
			}
		});

		addPromotionDataLayers();
		$(document).off('GoogleTagManager.AddPromotionDataLayers').on('GoogleTagManager.AddPromotionDataLayers', function(event) {
			addPromotionDataLayers();
		});
		
		/**
		 * Add class to content slot refinements if they're currently active
		 * and remove any static refinements if they're not found in the sidebar
		 * to prevent empty search results.
		 */
		if ($('body').find('.pt_product-search-result .category-slot a').length > 0) {
			var $slotLink = $('body').find('.pt_product-search-result .category-slot a');
			var urlRefinementData = getUrlRefinementData();
			var sidebarRefinements = getSidebarRefinements();
			$slotLink.each(function(index, value) {
				var linkRefinementType = $(value).data('link-attribute-value');
				for (var refinement in urlRefinementData.parameters) {
					if (refinement.match(/^\prefv/)) {
						var refinementValues = urlRefinementData.parameters[refinement].split('%7C');
						for (var index = 0; index < refinementValues.length; index++) {
							if (refinementValues[index] === linkRefinementType) {
								$(value).addClass('refinement-active');
							}
						}
					}
				}
				if (linkRefinementType != undefined) {
					var foundInSidebar = false;
					for (var index = 0; index < sidebarRefinements.length; index++) {
						if (sidebarRefinements[index] === linkRefinementType) {
							var foundInSidebar = true;
						}
					}
					if (!foundInSidebar) {
						$(value).closest('li').remove();
					}
				}
			});
			$slotLink.closest('.section-block').removeClass('hidden');
		}

		/**
		 * helper function to get a list of existing refinements from the URL
		 */
		function getUrlRefinementData() {
			var refinements = location.search.substr(1).split('&');
			var urlRefinementData = {
				newRefinementStartNumber: 1,
				parameters: {},
				hasRefinements: false
			};
			if (refinements.length > 0) {
				for (var index = 0; index < refinements.length; index++) {
					var parameter = refinements[index].split('=');
					if (parameter[0].match(/^\prefn/)) {
						urlRefinementData.newRefinementStartNumber++;
						urlRefinementData.hasRefinements = true;
						urlRefinementData.parameters[parameter[0]] = parameter[1];
					}
					if (parameter[0].match(/^\prefv/)) {
						urlRefinementData.parameters[parameter[0]] = parameter[1];
					}
				}
			}
			return urlRefinementData;
		}

		/**
		 * helper function to get a list of refinement names present in refinement sidebar
		 */
		function getSidebarRefinements() {
			var sidebarRefinementList = [];
			$('#secondary.refinements .checkbox input').each(function(index, value) {
				if ($(value).attr('id').match(/^field/)) {
					var refinementName = $(value).attr('id').split('-')[1];
					sidebarRefinementList.push(refinementName);
				}
			});
			return sidebarRefinementList;
		}
 
    	//ready #navigation for interaction (arrests odd initial interaction on touch devices)
    	$("#navigation").addClass("hoverReady");

		// Switch show text
		$('.switch input').change(function () {
			if($(this).prop('checked')) {
				$(this)
					.closest('.result-options')
					.addClass('show-info');
			} else {
				$(this)
					.closest('.result-options')
					.removeClass('show-info');
			}
		});

		if($('body').find('.result-options .switch input[name="field-true"]').prop('checked')) {
			var toggleBtn = $('body').find('.result-options .switch input[name="field-switch"]');

			if(!toggleBtn.prop('checked')) {
				toggleBtn.closest('.result-options').addClass('show-info');
				toggleBtn.closest('.result-options').find('input[name="field-switch"]').attr('checked', 'checked');
			} else {
				toggleBtn.closest('.result-options').removeClass('show-info');
				toggleBtn.closest('.result-options').find('input[name="field-switch"]').removeAttr('checked');
			}
		}

		$('.swatches > li > ul').on('click', 'a', function(event) {
			event.preventDefault();

			$(this)
				.toggleClass('current')
				.parent()
				.siblings()
				.find('.current')
				.removeClass('current');
		});

		// Generic slider init
		sliders.init('.slider .slides');
		
		$('.service-toggle-item').on('click', function (event) {
			event.preventDefault();

			$(this).toggleClass('active');

			$(this).next('.customer-service-inner').toggleClass('open');
			$(this).next('.customer-service-inner').slideToggle();
		});

		$('.top-brands .category-head').on('click', function (event) {
			event.preventDefault();

			$(this).toggleClass('active');

			$(this).next('.category-body').toggleClass('open');
			$(this).next('.category-body').slideToggle();
		});

		$(document).on('update', '.slider-products-primary', function() {
    		sliders.init('.slider-products-primary .slides', {breakpoints: {0: {slidesToShow: 1, dots: true, autoplay: 3000}, 768: {slidesToShow: 3}, 1024: {slidesToShow: 4}}});
		});

		// make sure slider gets initialized for product recommendations when dynamically added
		if ($('.pt_product-details').length > 0 || $('.no-hits-banner').length > 0) {
			sliders.initializeRecommendationSlider();
		}

		$(document).off('EinsteinProductRecommendations.Initialize').on('EinsteinProductRecommendations.Initialize', function() {
			sliders.initializeRecommendationSlider();
		});

		$(document).on('init-slider', '.slider-products-primary', function() {
			if (pageContext.site !== SiteConstants.SiteIds.BootBarnUS) {
				sliders.init('.slider-products-primary .slides:not(.slick-slider):has(li)', {breakpoints: {0: {slidesToShow: 1, dots: true, arrows: true, autoplay: 3000}, 768: {slidesToShow: 3, dots: false, arrows: true}, 1024: {slidesToShow: 4, arrows: true}}}, true);
			} else {
				sliders.init('.product-recommendations .slider-products-primary .slides:not(.slick-slider):has(li)', {breakpoints: {0: {slidesToShow: 1, dots: true, arrows: true, autoplay: 3000}, 768: {slidesToShow: 3, dots: false, arrows: true}}}, true);
				sliders.init('.last-visited .slider-products-primary .slides:not(.slick-slider):has(li)', {breakpoints: {0: {slidesToShow: 1, dots: true, arrows: true, autoplay: 3000}, 768: {slidesToShow: 4, dots: false, arrows: true}}});
			}
		});

		sliders.init('.slider-filmstrip .slides', {autoplay: 3000});

		sliders.initializeCategorySliders('.cat-header-content.cat-header-slider:not(.slick-initialized)');

		// Waypoints
		$('.waypoint').on('click', function(event) {
			event.preventDefault();

			var element = $(this).attr('href');

			$('html, body').animate({
				scrollTop: $(element).offset().top
			}, 1000);
		});

		// Btn Expand Alt
		$('.btn-expand-alt').on('click', function(event) {
			event.preventDefault();
			event.stopPropagation();

			var element = $(this).attr('href');


			if ($(element).hasClass('grid-page-storeselector-popup')) {
				$(element).dialog('open');

				return;
			}

			$(element)
				.toggleClass('show');
		});

		$('.grid-page-storeselector-popup').dialog({
			width: 450,
			autoOpen: false,
			modal: true,
			resizable: false
		});

		// Sidebar Close
		$('#secondary .close, #sort-by .close, .popup-close').on('click', function (event) {
			event.preventDefault();

			$(this)
				.closest('.show')
				.removeClass('show');
		});

		$('.message .close').on('click', function (event) {
			event.preventDefault();

			$(this)
				.parent()
				.slideUp();
		});

		if ($('#GlobaleFreeShippingBannerContainer').length > 0) {
			var globaleFreeShippingBannerObserver = new MutationObserver(function(mutations, observer) {
				for (var mutationKey in mutations) {
					var mutation = mutations[mutationKey];
					if (mutation.type === 'childList') {
						resizeDeviceHelper();
					}
				}
			});
			globaleFreeShippingBannerObserver.observe(document.getElementById('GlobaleFreeShippingBannerContainer'), {attributes: false, childList: true, subtree: true });
		}

		// Back to Top button
		function topButtonToggle() {
			var $scrollTop = $win.scrollTop();
			if ($scrollTop >  100 ) {
				$('body').addClass('btn-go-top-active');
				$( '.btn-go-top' ).addClass('active');
			} else {
				$('body').removeClass('btn-go-top-active');
				$( '.btn-go-top' ).removeClass('active');
			}
		}
		
		$win.on('scroll', function () {
			topButtonToggle();
			$(document).trigger('SearchBar.ScrollTop');
		});

		$win.on('load', function() {
			accordionMobile();
			accordionMobileAlt();

			// Disabled per BOOT-3517
			// $( '<span class="btn-go-top"><i class="fa fa-angle-up" aria-hidden="true"></i><span>Top</span></span>' ).insertBefore($('.footer:not(.footer-mobile)'));
			// topButtonToggle();
		});

		$doc.on('click', '.btn-go-top', function(e){
			$('html, body').animate({scrollTop: 0},800);
			e.preventDefault();
		})

		// Read More / Less
		$doc.on('click', '.section-offers-text .read-more', function(e) {
			e.preventDefault();
			$(this).parents('.section-body').first().addClass('expand');
			return false;
		});

		$doc.on('click', '.section-offers-text .read-less', function(e) {
			e.preventDefault();
			$(this).parents('.section-body').first().removeClass('expand');
			return false;
		});
		
		
		// Accordion
		function accordionMobile() {
			$('.offer .offer-head h6').on('click', function (e) {
				if(event.target.nodeName == "H6"){
					e.preventDefault();
					e.stopImmediatePropagation();
					$(this).find('.chevron-collapse').click();
				}
			});
			
			$('.offer .offer-head h6 .chevron-collapse').on('click', function (event) {
				if (window.innerWidth > 1024) {
					return;
				}
				event.stopImmediatePropagation();
				event.preventDefault();
				var $this = $(this)
				var $parent = $this.closest('.offer')

				if ($parent.hasClass("active")) {
					$parent.removeClass('active').find('.offer-body').slideUp();

				}
				else {
					$parent.addClass('active').find('.offer-body').slideDown();
				}

			});

			$win.resize(function() {
				if(window.innerWidth > 1024) {
					$('.offer .offer-body').show();
				} else {
					$('.offer .offer-body').hide()
				}
			});
		};

		function accordionMobileAlt() {
			$('.accordion-mobile .accordion-mobile-head').on('click', function (event) {
				if (window.innerWidth > 1024) {
					return;
				}

				event.preventDefault();
				var $this = $(this),
					$parent = $this.closest('.accordion-mobile');

				$this
				.toggleClass('active')
				.next()
				.stop()
				.slideToggle();
			});
		};

		// Accordion
		$(document).on('click', '.accordion-section .accordion-head', function(event) {
			event.preventDefault();
			var $this = $(this);
			var $container = $this.closest('.accordion');
			$this.toggleClass('active');
			$this.next('.accordion-body').stop().slideToggle(400);

			if ($this.hasClass('active') && $container.find('.slides').length > 0 && $container.find('.slick-initialized').length > 0) {
				$container.find('.slides').slick('refresh');
			}
		});

		$(document).on('click', '.section-offers.content-columns .section-column h6.column-title', function(event) {
			if (pageContext.site === SiteConstants.SiteIds.SheplersUS && window.innerWidth < 768) {
				event.preventDefault();
				$(this).toggleClass('active');
				$(this).next('.column-content').stop().slideToggle();
			}
		});

		// Rotate Text
		function rotateText($element, duration) {
			var lengthSlider = $element.find('.rotate-element').length;
			var $rotateElement = $element.find('.rotate-element');
			
			if (lengthSlider > 0) {
				var interval = setInterval(function () {
					var $rotateElementActive 	= $element.find('.active');
					var $nextElement = $rotateElementActive.next();
	
					if($nextElement.index() === -1) {
						$nextElement = $element.find('.rotate-element:first-child');
	
						$nextElement
							.addClass('active')
							.siblings()
							.removeClass('active');
					} else {
						$nextElement
							.addClass('active')
							.siblings()
							.removeClass('active');
					}
				}, duration);
			}
		}
		rotateText($('.top-banner-message'), $('.top-banner-message').data('duration'));

   	if (window.innerWidth <= 767) {
   		$('.refinement h3.toggle.expanded:not(.selected), .refinement .refinement-body.toggle-content.expanded:not(.selected)').removeClass('expanded');
   	}
   
    // add generic toggle functionality
    $('.toggle:not(.disabled)').not('.expanded').next('.toggle-content').hide();
    $('.toggle:not(.disabled)').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

	$("#Mapmobile area").click(function () {
		$("#store-name").removeClass('storeheading');
		var value = $(this).attr("href");
		var valueName = value.replace('#','');
		$('html,body').animate({
			scrollTop: $("a[name="+ valueName +"]").offset().top - 200
		})
	});
    
    $( ".user-info" ).hover(
    	  function() {
    		 $(this).addClass('active');
    	  },
    	  function() {
    		 $(this).removeClass('active');
    	  }

    );

    var storesMediaQuery = matchMedia('(max-width: 1024px)');
	storesMediaQuery.addListener(mobileStores);
	$(mobileStores);

    $(document).on('click', '.detail-toggle-item', function(e) {
        e.preventDefault();
        
        $(this).toggleClass('active');
        $('.discount-details-entry').toggleClass('open').animate({ 'height': 'toggle', 'opacity': 'toggle' }, 250, null, function() {
        	
        });
    });

	// add show/hide password functionality
	$(document).on('click', '[data-action="toggle-password-visibility"]', function(e) {
		e.preventDefault();
		var $button = $(e.target);
		var $passwordInput = $button.closest('.password-holder').find('input');
		var passwordInputType = $passwordInput.attr('type');
		if (passwordInputType === 'password') {
			$button.addClass('password-visibility--visible');
			$button.removeClass('password-visibility--invisible');
			$passwordInput.attr('type', 'text').focus();
		} else {
			$button.addClass('password-visibility--invisible');
			$button.removeClass('password-visibility--visible');
			$passwordInput.attr('type', 'password').focus();
		}
	});
	
	if (siteName == SiteConstants.SiteIds.BootBarnUS) {
		if ($('.cs-rewards-program').length >= 1) {
			if ($('.customer-rewards-number').length == 0) {	
				BreadPayments.on("CARD:RECEIVE_APPLICATION_RESULT", function(cardResult){
					// Handle Application Result
					switch(cardResult.result){
						case 'APPROVED':
						case 'PENDING':					
																
							$.ajax({
								type: 'post',
								url: Urls.createRewardsAccount,
								contentType: 'application/json',
								data: JSON.stringify({
									emailAddress: cardResult.emailAddress,
									phoneNumber: cardResult.mobilePhone
								}),
								success: function (data) {
									console.log('Rewards account created.');
								}
							});
						
						break;
					}
				});
			}
		}
	}

	// BOOT-5363: show/hide total savings message based on promotion callout messages
	$('.product-tile').each(function() {
		var $tile = $(this);
		if ($tile.find('.hide-total-savings').length > 0) {
			$tile.find('.product-total-savings').addClass('d-none');
		}
	});
	if($('.product-detail').find('.hide-total-savings').length > 0) {
		$('.product-total-savings').addClass('d-none');
	}
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    // load js specific styles
    util.limitCharacters();

    // DOM EVENT FOR HEADER STORE LOCATOR
	$('.stores-near-content .list-stores').delegate( 'a.make-this-mystore', 'click',function(e){
		e.preventDefault();
		var postCode = $(this).attr('data-postalcode');
		$('a.ico-crosshairs-use-my-location').data('postalcode',postCode);
		headerNavStoreSearch();
	});

}

function usemylocation(storeid) {
	var t_url = Urls.usecurrentlocation;
	if (storeid === undefined) {
		storeid = 'getall';
	}

	$.ajax({
	   url: t_url,
	   type: 'POST',
	   data: { 'source' : 'ajax' },
	   dataType: 'json',
	   error: function() {
		   console.log('error');
	   },
	   success: function(dataJson) {
	      if( 'stores' in dataJson && dataJson['stores'].length > 0 ) {
	    	  	if(storeid == 'getall') {
	    	  		$('.stores-near-content .stores-near-body ul.list-stores').html('');
	    	  	}
				$('div.stores-near-actions a.go-to-findstore').html(Resources.FIND_MORE_STORES);
				if ( dataJson['stores'].length == 1 ) {
					$('.stores-near-content').slideUp();
				}
				dataJson['stores'].forEach(function(element, index){
					var store = element;
					var htmlDisplay = '';
					var storePhoneNumber = ('' + element['phone']).replace(/\D/g,'');
					var postalCodeVal = ( typeof element['postalCode'] != 'undefined' ? element['postalCode']  : '' );

					if(storeid == 'getall') {
						if(index == 0) {
							htmlDisplay += '<span class="red store-name-value">'+element['name']+'</span>';
							htmlDisplay += element['address1'];
							htmlDisplay += '<br/>'+ ( typeof element['city'] != 'undefined' ? element['city'] +', ' : '' );
							htmlDisplay += ( typeof element['stateCode'] != 'undefined' ? element['stateCode'] +' ' : '' );
							htmlDisplay += postalCodeVal;
							htmlDisplay += '<br /><a href="tel:'+storePhoneNumber+'">'+store['phone']+'</a>';
							htmlDisplay += '<span>'+unescape(store['storeHours'])+'</span>';
							$('.stores-dropdown .current-store address').html(htmlDisplay);
							$('.stores-dropdown .current-store').slideDown();
						} else {
							htmlDisplay += '<li><h5><span>'+element['name']+'</span><a href="#" class="make-this-mystore" id="' + element['ID'] + '" data-postalcode="'+postalCodeVal+'">'+Resources.MAKE_THIS_MYSTORE+'</a></h5>';
							htmlDisplay += '<address>'+element['address1'];
							htmlDisplay += '<br/>'+ ( typeof element['city'] != 'undefined' ? element['city'] +', ' : '' );
							htmlDisplay += ( typeof element['stateCode'] != 'undefined' ? element['stateCode'] +' ' : '' );
							htmlDisplay += postalCodeVal;
							htmlDisplay += '<br /><a href="tel:'+storePhoneNumber+'">'+store['phone']+'</a>';
							htmlDisplay += '/<address>' + unescape(store['storeHours']);
							htmlDisplay += '</li>';
							$('.stores-near-content .stores-near-body ul.list-stores').append($(htmlDisplay));
							$('.stores-near-content').slideDown();
						}
					} else {
						if(storeid == element['ID']) {
							htmlDisplay += '<span class="red store-name-value">'+element['name']+'</span>';
							htmlDisplay += element['address1'];
							htmlDisplay += '<br/>'+ ( typeof element['city'] != 'undefined' ? element['city'] +', ' : '' );
							htmlDisplay += ( typeof element['stateCode'] != 'undefined' ? element['stateCode'] +' ' : '' );
							htmlDisplay += postalCodeVal;
							htmlDisplay += '<br /><a href="tel:'+storePhoneNumber+'">'+store['phone']+'</a>';
							htmlDisplay += '<span>'+unescape(store['storeHours'])+'</span>';
							$('.stores-dropdown .current-store address').html(htmlDisplay);
							$('.stores-dropdown .current-store').slideDown();
						}
					}

				});
			} else {
				$('.stores-near-content').slideUp();
				$('div.stores-near-actions a.go-to-findstore').html(Resources.FIND_STORES);
				$('.stores-dropdown .current-store').slideUp();
				if ( $('.stores-dropdown h3.no_results').length == 0 ) {
					$('.stores-dropdown').prepend('<h3 class="no_results">'+Resources.NO_STORE_RESULTS+'</h3>');
				} else {
					$('.stores-dropdown h3.no_results').fadeIn();
				}
				$('.stores-dropdown h3.no_results').show().delay(2000).fadeOut();
		  }
	   }
	});
}


/** 
 *
 * Moves customer specific stores info in main header to mobile utility header.
 * This is needed because the store info is part of a remote include in another spot on desktop. 
 *
 */
function mobileStores() {	
	var $mobileStoreMetadata = $('#mobile-storeMetadata');
	var $mobileStoreBannerText = $('#mobile-banner-store-text');
	
	if ($mobileStoreMetadata !== undefined && $mobileStoreMetadata != null && $mobileStoreMetadata.length  > 0){
		if ($mobileStoreBannerText !== undefined && $mobileStoreBannerText != null && $mobileStoreBannerText.length  > 0){
			var isOpen = $mobileStoreMetadata.data('is-open');
			if (isOpen){
				var storeName = $mobileStoreMetadata.data('store-name');
				var openUntil =  $mobileStoreMetadata.data('open-until');
				var closingTime =  $mobileStoreMetadata.data('closing-time');
				var bannerText = storeName + ' &#8226; ' + openUntil + ' ' + closingTime;
				$mobileStoreBannerText.html(bannerText);	
			} else {
				var closedText =  $mobileStoreMetadata.data('closed-text');
				$mobileStoreBannerText.html(closedText);
			}
		}
	}
}

function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires="+d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i = 0; i < ca.length; i++) {
	  var c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return "";
  }



function addPromotionDataLayers() {
	var $containers = $('.accordion:visible, .section-offers:visible, .cat-header-content.cat-header-slider:visible');
	if ($containers.length > 0) {
		$containers.each(function() {
			var $container = $(this);
			if ($container.attr('data-layer-added') != 'true' && $container.find('.product-tile').length > 0) {
				var containerId = $container.attr('content-slot-id') || $container.attr('id');

				var dataObject = {
					promotion_name: containerId
				}

				var pageType;
				var $wrapper = $container.closest('.add-to-cart-modal');
				if ($wrapper.length > 0) {
					pageType = 'addtocartmodal';
				}

				if (!pageType) {
					$wrapper = $('#wrapper');
					if ($wrapper.length > 0) {
						if (pageContext && pageContext.type) {
							switch (pageContext.type) {
								case 'storefront':
								pageType = 'Homepage';
								break;

								case 'search':
								pageType = 'PLP';
								break;

								case 'product':
								pageType = 'PDP';
								break;

								case 'cart':
								pageType = 'Cart';
								break;

								case 'onepcheckout':
								case 'onepcheckout_groups':
								pageType = 'Checkout';
								break;

								case 'orderconfirmation':
								pageType = 'Order Confirmation';
								break;

								case 'customerservice':
								pageType = 'Customer Service';
								break;

								case 'account':
								pageType = 'Account';
								break;

								case 'content':
								pageType = 'Content Page';
								break;

								case 'error':
								pageType = 'Error Page';
								break;									
							}
						}
					}
				}

				if (pageType) {
					dataObject.promotion_id = pageType;
				}

				GoogleTagManagerClient.PushDataLayerForDataLayerType('ViewPromotion', dataObject, { Container: $container });
				$container.attr('data-layer-added', true);
			}
		});
	}
}
function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	let expires = "expires="+d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  function getCookie(cname) {
	let name = cname + "=";
	let ca = document.cookie.split(';');
	for(let i = 0; i < ca.length; i++) {
	  let c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return "";
  }

var pages = {
	storefront: require('./pages/storefront'),
	account: require('./pages/account'),
	bootbarnexpress: require('./pages/bootbarnexpress'),
	bootfinder: require('./pages/bootfinder'),
    cart: require('./pages/cart'),
	cart_echo: require('./pages/cart_echo'),
    checkout: require('./pages/checkout'),
	checkout_echo: require('../../../app_storefront_core/cartridge/js/pages/checkout_echo'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storelocator: require('./pages/storelocator'),
    onepcheckout: require('./pages/onepcheckout'),
    onepcheckout_groups: require('./pages/onepcheckout_groups'),
	orderconfirmation: require('./pages/orderconfirmation'),
    categorylanding: require('./pages/categorylanding'),
    content: require('./pages/content'),
    authentication_EmailAddressCapture: require('./pages/Authentication/EmailAddressCapture') 
};

var lastScrollTop = 0; 
var scrollDelta = 100;
var fixedHeaderLimit = 800;
var headerWrapper = $('.header-wrapper');

// Resize Helper
function resizeDeviceHelper() {
	var countryOutfitters = 'country_outfitters_us';
	var bootBarn = 'bootbarn_us';
	var sliderMobileSwiper;

	$('html').removeClass('size-mobile size-tablet size-desktop');
	if (window.innerWidth <= 767) {
		$('html').addClass('size-mobile');
	} else if (window.innerWidth <= 1024) {
		$('html').addClass('size-tablet');
	} else {
		$('html').addClass('size-desktop');
	}
	
	var $navigationContainer = $('#navigation');
	
	if (!$('.second-mobnav').hasClass('active')) {
		$('.nav-utilities').hide();
	}
	
	$('.nav-utilities, #navigation').removeClass('preload');
	$('#nav-utils').removeClass('loadhide');
	
	if ((siteName != bootBarn && window.innerWidth <= 1024) || window.innerWidth <= 767) {
		if (siteName != SiteConstants.SiteIds.BootBarnUS) {
			$navSecondary.insertAfter($navUl);
		}

		$sectionErrorFigure.insertBefore($sectionErrorBox);
		$navUtilities.insertAfter($topBannerInnerLinks);
		if ($sliderMobile.length > 0) {
			sliderMobileSwiper = sliders.init('.slider-mobile', {dots: true, loop: true, autoplay: 5000});
		}

		var image = $(".slider-single-image img");
		$.removeData(image, 'elevateZoom');
		$('.zoomContainer').remove();		
		if($('.section-offers.all-on-sale').length){
			setTimeout(function(){
				$('.section-offers.all-on-sale .chevron-collapse').each(function(){
					$(this).click();
				});
			}, 13);			
		}
		$('.accordion-mobile').each(function () {
			var accMobile = $(this);

			if (!accMobile.hasClass('active')) {
				accMobile.find('.accordion-mobile-body').hide();
			}
		});
	} else {
		$navUl.find('.has-dropdown.active').removeClass('active');

		$navUtilities.insertBefore($topBannerBarInner);
		if (siteName != SiteConstants.SiteIds.BootBarnUS) {
			$navSecondary.prependTo($topBannerBar);
		}

		$sectionErrorFigure.insertAfter($sectionErrorBox);
		$navigation.removeAttr('style');

		if ($sliderMobile.length > 0) {
			if ($sliderMobile.find('.swiper-initialized').length > 0) {
				sliderMobileSwiper.destroy();
			}
			if ($sliderMobile.find('.slick-initialized').length > 0) {
				$sliderMobile.slick('unslick');
			}
		}
		$('.accordion-mobile-body').show();
		
		$('html').removeClass('menu-active');
		$('.menu-toggle').removeClass('active');
		$('#navigation .level-2').hide();
	}
	
	resizeLayoutHelper();
}

function resizeLayoutHelper() {
	var newMarginTop = 0;
	var innerWidthThreshold;	
	if (siteName == SiteConstants.SiteIds.BootBarnUS) {
		innerWidthThreshold = 1023;
	} else {
		innerWidthThreshold = 767;
	}
	if (window.innerWidth <= innerWidthThreshold) {
		newMarginTop = parseFloat($('.header-wrapper').outerHeight());
	}
	if (siteName == SiteConstants.SiteIds.BootBarnUS) {
		if (!$('html').hasClass('menu-active')) {
			$('#main').css('margin-top', newMarginTop);
		}
	} else if (siteName != SiteConstants.SiteIds.BootBarnRspUS) {
		if (!$('html').hasClass('menu-active')) {
			$('#wrapper').css('margin-top', newMarginTop);
		}
	}
	if (!$('html').hasClass('initialized')) {
		$('html').addClass('initialized');
	}
}

var app = {
    init: function () {
		$.extend(page, window.pageContext);

    	$sliderMobile = $('.slider-mobile');
		$navSecondary 			= $('.nav-secondary');
		$navUtilities 			= $('.nav-utilities');
		$navUl 					= $('#navigation > ul');
		$topBannerBar 			= $('.top-banner-bar');
		$topBannerBarInner		= $('.top-banner-bar-inner');
		$navigation 			= $('#navigation');
		$topBannerInnerLinks 	= $('.top-banner-inner-links');
		$selectSort 			= $('#sort-by');
		$secondaryHead 			= $('#secondary .refinements-head');
		$searchResultContainer 	= $('#search-result');
		$productContainerImage	= $('#pdpMain .product-image-container');
		$productDetail			= $('#pdpMain .product-detail');
		$sectionCheckout 		= $('.section-checkout');
		$mainInnerAlt			= $('.main-inner-alt');
		$sectionErrorFigure 	= $('.section-error figure');
		$sectionErrorBox 		= $('.section-error .box');
		$footerMenuItems		= $('.footer-item .content-asset');
		$promoMessages		= $productContainerImage.find('.promo-messages');
    	siteName = pageContext.site || '';
    	window.sgDialog = require('./dialog');
    	window.sgUtil = require('./util');
    	
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert cookies').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
            $('.browser-compatibility-alert.cookies').prepend('<i class="fa fa-exclamation-triangle fa-3x pull-left"></i>');
        }
        initializeDom();
        initializeEvents();
        
		login.init(page.options);
		register.init();
        navigation.Initialize();
        countries.init();
        //tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        searchplaceholder.init();
		rotate3d.init();
        count_down.init();
        lazyloadPageElements.init();
        giftCardBalanceInquiryHelper.initializeAllFoundComponents();
		slickHelpers.init();
		if (pageContext && 'type' in pageContext && pageContext.type != 'cart' && pageContext.type != 'onepcheckout' && pageContext.type != 'onepcheckout_groups') {
            quantityStepper.init();
        }
		wishlist.init();
		Globale.initialize();
		
        // execute page specific initializations
        
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init(page.options || {});
        }

        GoogleTagManagerClient.PushDataLayerForPage(page);
        EmailSignup.Initialize(page);
        
        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
		}

        // Initialize Sticky header events
        $(window).scroll(function(event){
			if ((siteName == SiteConstants.SiteIds.BootBarnUS || siteName == SiteConstants.SiteIds.SheplersUS) && window.innerWidth <= 1023) {
				if ($(window).scrollTop() > fixedHeaderLimit) {
					headerWrapper.addClass('header-fixed');
				} else {
					headerWrapper.removeClass('header-fixed');
				}

				var nowScrollTop = $(this).scrollTop();
				if(Math.abs(lastScrollTop - nowScrollTop) >= scrollDelta){
					if (nowScrollTop > lastScrollTop){
						if (headerWrapper.hasClass('header-fixed-active')) {
							headerWrapper.removeClass('header-fixed-active');
						}
					} else {
						if ($(window).scrollTop() > fixedHeaderLimit) {
							if (!headerWrapper.hasClass('header-fixed-active')) {
								headerWrapper.addClass('header-fixed-active');
							}
						} else {
							headerWrapper.removeClass('header-fixed-active');
						}
					}
					lastScrollTop = nowScrollTop;
		 		}
			}

			if (siteName == SiteConstants.SiteIds.BootBarnUS && $('html').hasClass('size-tablet') && window.innerWidth == 1024) {
				var nowScrollTop = $(this).scrollTop();
				if(Math.abs(nowScrollTop) > 300) {
					if ($('#navigation .has-dropdown').hasClass('active')) {
						$('#navigation .has-dropdown').removeClass('active');
						$('html').removeClass('menu-active');
						$('.header-wrapper .top-banner').removeClass('nav-active');
						$('html').removeClass('desktop-nav-active');
					}
					
				}
			}

			if ( $(".top-banner-inner .primary-logo a img.mobile-logo[src*=sheplers_us]").length ) {
	            if ( $(".second-mobnav").length > 0 && $(".second-mobnav").is(":visible") ) {
	                var st = $(this).scrollTop();
	                
	                if (st > 0) {
	                    $(".top-banner-bar .shell").hide();
	                    $(".top-banner div.top-banner-inner").css("margin-top","0px");
	                    $(".top-banner div.top-banner-bar .header-search").attr("style","top:55px !important");
	                } else {
	                    $(".top-banner-bar .shell").show();
	                    $(".top-banner div.top-banner-inner").css("margin-top","25px");
	                    $(".top-banner div.top-banner-bar .header-search").attr("style","top:80px !important");
	                }
	            } else {
	            		$(".top-banner-bar .shell").show();
	            		$(".top-banner div.top-banner-inner").css("margin-top","0px");
	            		$(".top-banner div.top-banner-bar .header-search").attr("style","top:80px !important");
	            		if ( $(".top-banner-bar-aside #second-mobnav").length ) {
	            			$(".top-banner-bar-aside #second-mobnav").show();
	            		}
	            }
			}
        });

		resizeDeviceHelper();
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});

(function () {
 String.format = function () {
     var s = arguments[0];
     var i, len = arguments.length - 1;
     for (i = 0; i < len; i++) {
         var reg = new RegExp('\\{' + i + '\\}', 'gm');
         s = s.replace(reg, arguments[i + 1]);
     }
     return s;
 };
})();
