'use strict';

var _ = require('lodash');
var loadingIndicator = require('../../components/LoadingIndicator');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;

exports.initializeSelectors = initializeSelectors;
exports.initialize = initialize;

var paymentContainerSelector;
var $paymentContainer;
var $paymentForm;
var $paymentVoucherNumber;
var $paymentMessage;
var $paymentErrorMessage;
var $paymentsApplied;
var $paymentApplyButton;
var validator;
var recaptcha;
var options = {};
var loaded = {};

function renderRecaptcha() {
	if (loaded.Recaptcha && $paymentApplyButton.length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForPaymentVouchers && options.Recaptcha.GoogleSiteKey) {
		recaptcha = grecaptcha.render('payment-voucher-apply-button', {
	        sitekey: options.Recaptcha.GoogleSiteKey,
	        callback: submitPaymentVoucher,
	        badge: 'bottomleft'
	    });
	}
}

function initializeSelectors() {
    paymentContainerSelector = '[payment-method="B2B_Payment_Voucher"]';
    $paymentContainer = $(paymentContainerSelector);
    $paymentForm = $paymentContainer.find('form');
    $paymentVoucherNumber = $paymentContainer.find('input[name="paymentvouchercode"]');
    $paymentMessage = $paymentContainer.find('.messages');
    $paymentErrorMessage = $paymentContainer.find('span.error');
    $paymentsApplied = $paymentContainer.find('.form-payments-applied');
    $paymentApplyButton = $paymentContainer.find('.apply');
}

function initialize(optionsParameter) {
    initializeSelectors();
	options = optionsParameter || {};

	renderRecaptcha();
	$(document).on('Recaptcha.Loaded', function(event) {
		if (!loaded.Recaptcha) {
			loaded.Recaptcha = true;
	        renderRecaptcha();
	    }
	});
	
    $(document).on('click', paymentContainerSelector + ' .apply:not([loading])', function (event) {
		event.preventDefault();
		
        if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForPaymentVouchers || !options.Recaptcha.GoogleSiteKey) {
            $(document).trigger('PaymentVoucher.Apply');
        }
	});
	
	$(document).on('PaymentVoucher.Apply', function(event) {		
        event.preventDefault();

        if (parseFloat($('[balance-due]').first().attr('balance-due')) <= 0) {
            $paymentErrorMessage.html(Resources.PaymentVoucher.NoBalanceDue).show();
            
            if (recaptcha != undefined) {
				grecaptcha.reset(recaptcha);
			}
        } else {
            $paymentErrorMessage.html('').hide();
            validator = $paymentForm.validate();
            $paymentVoucherNumber.rules('add', ValidationRules.PaymentVoucher.Number);
            validator.checkForm();

            if (Urls && Urls.PaymentVoucher.Apply && validator.valid()) {
                var rowContainerHtml = _.template($('#template-paymentvoucher-row-container').html(), TemplateConstants.Lodash.Settings)();
                var $paymentApplied = $(rowContainerHtml).appendTo($paymentsApplied);

                $paymentApplyButton.loading({
                    IsImageShown: true,
					IsLabelShown: false
                });
                $paymentApplied.loading({
                    Label: Resources.Loading.PaymentVoucher.Applying
                });

                $.ajax({
                    method: 'POST',
                    url: Urls.PaymentVoucher.Apply,
                    contentType: 'application/json',
                    data: JSON.stringify({
                        number: $paymentVoucherNumber.val(),
                        amount: null
                    }),
                    dataType: 'json',
                    success: function (data) {
                        $paymentApplyButton.loading('destroy');
                        $paymentApplied.loading('destroy');
                        
                        if (data.success) {
                            $paymentVoucherNumber.val('');
                            
                            if (data.amountApplied && data.amountApplied.value > 0) {
                            	$paymentsApplied.find('[data-id="' + data.id + '"]').remove();                            	
                                $paymentApplied.attr('data-id', data.id);

                                var rowContentHtml = _.template($('#template-paymentvoucher-row-content').html(), TemplateConstants.Lodash.Settings)({
                                    paymentNumber: data.number,
                                    amountApplied: data.amountApplied.displayValue
                                });

                                $paymentApplied.html(rowContentHtml);
                            }
                            
                            if (data.message) {
                            	$paymentErrorMessage.html(data.message).show();
                            }

                            $(document).trigger('Cart.Update');
                        } else {
                        	if (data.message == 'Invalid Message') {
                        		data.message = Resources.Error.Generic;	
                        	}
                        	
                            $paymentApplied.remove();
                            $paymentErrorMessage.html(data.message || Resources.Error.Generic).show();
                        }
                        
						if (recaptcha != undefined) {
							grecaptcha.reset(recaptcha);
						}
                    },
                    error: function() {
						$paymentApplyButton.loading('destroy');
						$paymentApplied.remove();
						$paymentErrorMessage.html(Resources.Error.Generic).show();
						
						if (recaptcha != undefined) {
							grecaptcha.reset(recaptcha);
						}
					}
                });
            } else {
            	if (recaptcha != undefined) {
					grecaptcha.reset(recaptcha);
				}	
            }
        }
    });

    $(document).off('mousedown.paymentPanel').on('mousedown.paymentPanel', function (event) {
        if (validator && $(event.target).closest($paymentContainer).length == 0) {
            validator.resetForm();
            $paymentErrorMessage.html('').hide();
        }
    });

    $(document).on('CartData.Update', function (event, data) {
    	$('[has-gift-cards]').attr('has-gift-cards', data.HasGiftCards || false);
	    $('[has-gift-cards-only]').attr('has-gift-cards-only', data.HasGiftCardsOnly || false);
	    
	    if (data.HasGiftCards && !data.AllowpurchaseWithGiftCard) {
	    	$paymentMessage.html(Resources.GiftCard.purchaseWithGiftCardNotAllowed);
	    	$paymentContainer.attr('status', 'locked');
	    } else {
	    	$paymentMessage.html('');
	    	$paymentContainer.attr('status', 'active');
	    }
    });
    
    $(document).on('GiftCard.Clear', function () {
        $paymentsApplied.html('');
        $('.order-balance-due').html('');
    });

    $(document).on('click', paymentContainerSelector + ' .remove:not([loading])', function (event) {
        event.preventDefault();

        var $row = $(this).closest('.form-payment-applied');
        $row.loading({
            Label: Resources.Loading.GiftCard.Removing
        });
        $paymentErrorMessage.html('').hide();

        $.ajax({
            url : Urls.PaymentVoucher.Remove,
            type : 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
                id: $row.data('id')
            }),
            dataType : 'json',
            success : function(data) {
            	$row.loading('destroy');
            	
                if (data.success) {
                    $row.remove();

                    $(document).trigger('Cart.Update');
                } else {
                	$paymentErrorMessage.html(data.error || Resources.Error.Generic).show();
                }
            },
            error: function() {
				$row.loading('destroy');
				$paymentErrorMessage.html(Resources.Error.Generic).show();
			}
        });
    });
}
