'use strict';

var dialog = require('../../dialog');
var productStoreInventory = require('../../storeinventory/product');
var tooltip = require('../../tooltip');
var util = require('../../util');
var addToCart = require('./addToCart');
var availability = require('./availability');
var image = require('./image');
var video = require('./video');
var productSet = require('./productSet');
var variant = require('./variant');
var requestGift = require('./requestGift');
var recaptcha = require('../../components/Recaptcha');
var recentlyViewedProducts = require('../../components/RecentlyViewedProducts');
var relevantProductRecommendations = require('../../components/RelevantProductRecommendations');
var sameDayShippingCountdown = require('../../components/SameDayShippingCountdown');
var ShopPay = require('../../../../../int_shoppay_sfra/cartridge/client/default/js/cart/initShopPayCart');
var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var options = {};
var loaded = {};
var dataBuffer = {};

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom(options) {
	tooltip.init();

	var $sameDayShippingCountdownContainer = $('.same-day-shipping-countdown');
	if ($sameDayShippingCountdownContainer.length > 0 && (!options || options.Channel != 'BootBarnExpress')) {
		sameDayShippingCountdown.initialize($sameDayShippingCountdownContainer, options.SameDayShipping);
	}

	if (pageContext.site == SiteConstants.SiteIds.BootBarnUS && window.innerWidth > 768) {
		$(document).find('.accordion-head').addClass('active');
		$(document).find('.accordion-body').css('display', 'block');
	}
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents(options) {
    var $pdpMain = $('#pdpMain');

    addToCart();
    availability();
    image();
    video();
    productSet();
    requestGift({ Recaptcha: options.Recaptcha });

    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

	$(document).on('click', '.product-swatches-accordion-button', function () {
        var $accordion = $(this).closest('.accordion');

        if ($accordion.length > 0) {
            $accordion.toggleClass('active');
        }
    });

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $pdpMain.on('click', 'a.size-chart-link', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    // Technology Features Tooltip
    // $('.list-product-info figure img').each(function() {
    // 	var $img = $(this),
    // 		$target = $img.parents('li').first().find('.tooltip-content img');

    // 	$target.attr("src", $img.data('hover')).attr("alt", $img.attr("alt")).attr("width", "236px");
    // });

    // $('.list-product-info').tooltip({
    //     items: 'li',
    //     tooltipClass: 'techfeature-tooltip',
    //     track: true,
    //     content: function () {
    //         return $(this).find('.tooltip-content').html();
    //     }
    // });


	$(document).on('click','.searchagain-btn',function() {
		$('#dialog-container .findStore-result').hide();
		$('#dialog-container .ui-dialog-footer').show();
		$('.ui-dialog').css({'height' : '230px !important','top':'260px !important'});
	});

	$(document).on('click','.find-store-btn',function() {
		dialog.open({
			html:($('.findStoreDialog').html()).replace(/select-holder/g,''),
			callback: function() {
				$(document).on('click','.findstore-formContainer .findStore-btn',function() {
					var zipcode    = $('#dialog-container input.zipcode').val(),
						radius     = $('#dialog-container select.radius').val(),
						postalcode = $('#dialog-container .postalcode select').val(),
						statecode = $('#dialog-container .statecode select').val(),
						pid        = $('#dialog-container #pid').val(),
						values 	   = {
										pid : pid,
										zipCode : zipcode,
										radius : radius,
										statecode : statecode,
										postalcode : postalcode
										};

					$.ajax({
						url: Urls.storeavailability,
						type: "GET",
						data: values ,
						success: function (response) {
							$('#dialog-container .findStore-result').show();
							$('#dialog-container .ui-dialog-footer').hide();
							$('#dialog-container .findStore-result').html(response).text();

						},
						error: function(jqXHR, textStatus, errorThrown) {
							console.log(textStatus, errorThrown);
						}
					});
				});
			},
			options: {
				title: Resources.FINDSTORE_TITLE
			},
		});
		return false;
	});

	$(document).off('RecentlyViewedProducts.Done').on('RecentlyViewedProducts.Done', function(event) {
		loaded.RecentlyViewedProducts = true;
		addPromotionDataLayers();
	});

	$(document).off('EinsteinProductRecommendations.Done').on('EinsteinProductRecommendations.Done', function(event) {
		loaded.EinsteinProductRecommendations = true;
		addPromotionDataLayers();
	});

	$(document).off('RelevantProductRecommendations.Done').on('RelevantProductRecommendations.Done', function(event) {
		loaded.RelevantProductRecommendations += 1;
		addPromotionDataLayers();
	});
}

function addPromotionDataLayers() {
	if (loaded.RecentlyViewedProducts && loaded.EinsteinProductRecommendations && loaded.RelevantProductRecommendations == dataBuffer['RelevantProductRecommendationsCount']) {
		$(document).trigger('GoogleTagManager.AddPromotionDataLayers');
	}
}

var product = {
    initializeEvents: initializeEvents,
    init: function (optionsParameter) {


console.log('product initialize');

    	options = optionsParameter;
		loaded = {
			RecentlyViewedProducts: false,
			EinsteinProductRecommendations: false,
			RelevantProductRecommendations: 0
		};
		dataBuffer = {
			RelevantProductRecommendationsCount: 0
		};

    	if (options && options.Recaptcha && options.Recaptcha.EnabledForMerryMail) {
        	recaptcha.initialize();
    	}

		if (options && options.RecentlyViewedProducts) {
    		recentlyViewedProducts.initialize(options.RecentlyViewedProducts);
    	} else {
			loaded.RecentlyViewedProducts = true;
		}

		if (!options || !options.EinsteinProductRecommendations) {
			loaded.EinsteinProductRecommendations = true;
		}

		if (options && options.RelevantProductRecommendations) {
			dataBuffer['RelevantProductRecommendationsCount'] = options.RelevantProductRecommendations.Containers.length;
			relevantProductRecommendations.initialize(options.RelevantProductRecommendations);
		}

		variant.initialize(options);

		initializeDom(options);
		initializeEvents(options);
	}
};

module.exports = product;
