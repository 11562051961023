'use strict';

var LocationSearch = require('../../../../app_storefront_core/cartridge/js/bopis/components/LocationSearch');

exports.init = function (options) {
	if ($('.pt_storefront .search-stores').length > 0) {
		var locationSearch = new LocationSearch();
		$(document).off('click.Storefront.StoreSearch').on('click.Storefront.StoreSearch', '.pt_storefront .search-stores:not(.disabled)', function(event) {
			event.preventDefault();

			locationSearch.initialize($('.location-search-dialog'), options);
			// $(document).trigger('LocationSearch.GeolocationSearch');
		});
	}
};
