'use strict';

var _ = require('lodash');
var dialog = require('../../dialog');
var ajax = require('../../ajax');
var page = require('../../page');
var util = require('../../util');
var TPromise = require('promise');
var validator = require('../../validator');

var options;

/**
 * @description Get the product information
 * @param {event} e The click event from the request gift button
 * @returns {object} productInfo
 */
var getProductInfo = function(e) {
	var productInfo;
	var sizeSelected = $('div.product-variations .size.size-options li.selected');
	var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
	var widthSelected = $('div.product-variations .width.width-options li.selected').length;
	var isSizeSelected = sizeSelected.length || (lengthSelected && widthSelected);

	if (!isSizeSelected) {
		alert('Please select a size');
		return false;
	}
	if (!$('div.product-variations .color.color-options li.selected').length) {
		alert('Please select a color');
		return false;
	}

	// get product info
	if (typeof dataLayer !== 'undefined') {
		var variationAttribute = $('.product-variations').data('attributes');
		var sizeAttributeValue = variationAttribute.size.displayValue;
		if ( sizeAttributeValue == '' || sizeAttributeValue == null ) {
			if (sizeSelected.hasClass("tempselection") && sizeSelected.find('a.swatchanchor').data() != null ) {
				sizeSelected.addClass('enterSelected');
				var sizeObjectReference = sizeSelected.find('a.swatchanchor').data();
				sizeAttributeValue = sizeObjectReference.sizeName;
				sizeSelected.find('a.swatchanchor').trigger('click');
			}
		}

		for(var i=0;i<dataLayer.length;i++) {
			if(dataLayer[i].event == 'pdpDisplay') {
				var selectedItem = dataLayer[i].ecommerce.detail.products[0];
				productInfo = {
					"id": selectedItem.id,
					"name": selectedItem.name,
					"imageUrl": $(e.target).closest('#pdpMain').find('.product-primary-image .slick-active .zoomedImage').attr('href'),
					"size": sizeAttributeValue,
					"color": variationAttribute.color.displayValue,
					"colorCode": variationAttribute.color.value
				}
			}
		}	
	}
	return productInfo;
};

/**
 * @description Formats a URL with preset product attributes
 * @param {object} productInfo An object containing the product's attributes
 * @returns {string} url
 */
var createProductUrl = function(productInfo) {
	var url = window.location.hostname + window.location.pathname;
	if (productInfo.id && productInfo.id.length > 0) {
		url = url + '?dwvar_' + productInfo.id;
		if (productInfo.colorCode && productInfo.colorCode.length > 0) {
			url = url + '_color=' + productInfo.colorCode;
		}
		if (productInfo.size && productInfo.size.length > 0) {
			url = url + '#prefn1=size&prefv1=' + productInfo.size;
		}
	}
	return url;
};

/**
 * @description Open the request gift dialog
 * @param {event} e The click event from the request gift button
 */
var requestGift = function(e) {
	e.preventDefault();
	
	var windowWidth = $(window).width();
	
	if (windowWidth <= 480) {
		var dialogWidth = windowWidth - 20;
	} else if (windowWidth <= 840) {
		var dialogWidth = windowWidth - 40;
	} else {
		var dialogWidth = 800;
	}

	var productInfo = getProductInfo(e);
	var dialogClass = 'gift-dialog';
	var templateSettings = {interpolate: /{{([\s\S]+?)}}/g};
	var formTemplate = _.template($('#template-popup-form').html(), templateSettings);
	var confirmationTemplate = _.template($('#template-popup-confirmation').html(), templateSettings);

	if (!productInfo) {
		return false;
	}

	dialog.open({
		html: formTemplate(productInfo),
		options: {
			title: 'Send a hint',
			width: dialogWidth,
			dialogClass: dialogClass,
			buttons: [],
			modal: true,
			open: function() {
				var $form = $(this).find('#gift-form');
				// jquery validate doesn't require an email extension, so adding it manually
				$.validator.addMethod('MerryMailValidateEmail',
					function(value, element) {
						return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);
					},
					'Please enter a valid email address.'
				);
				$.validator.addMethod('MerryMailValidateName',
					function(value, element) {
						return !(/:\/\//.test(value)) && !(/\w+([-+.'\s]\w)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value));
					},
					'Please enter a valid name.'
				);
						
				$form.validate({
					rules: {
						customMessage: {
							required: {
								depends: function(element) {
									return $form.find('input[name="message"][value="custom"]').prop('checked');
								}
							}
						},
						senderName: {
							MerryMailValidateName: true
						},
						senderEmail: {
							MerryMailValidateEmail: true
						},
						recipientName: {
							MerryMailValidateName: true
						},
						recipientEmail: {
							MerryMailValidateEmail: true
						}
					}
				});

				if ($('#merryMailSubmitButton').length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForMerryMail && options.Recaptcha.GoogleSiteKey) {
					grecaptcha.render('merryMailSubmitButton', {
			        	sitekey: options.Recaptcha.GoogleSiteKey,
			        	callback: submitMerryMail,
			        	badge: 'bottomleft'
					});
				}
				
				// trigger radio button selection if user clicks into custom message input
				$(this).find('input[name="customMessage"]').off('focus').on('focus', function(e) {
					$form.find('input[name="message"][value="custom"]').prop('checked', true).change();
				});

				$(document).off('MerryMail.Send').on('MerryMail.Send', function() {
			        if (!$form.valid()) {
						return false;
					}
			        
					var formData = {
						recipientName: $form.find('input[name="recipientName"]').val(),
						recipientNameCaps: $form.find('input[name="recipientName"]').val().toUpperCase(),
						recipientEmail: $form.find('input[name="recipientEmail"]').val(),
						senderName: $form.find('input[name="senderName"]').val(),
						senderEmail: $form.find('input[name="senderEmail"]').val(),
						fromEmail: $('#merry-mail-from-email').text().length > 0 ? $('#merry-mail-from-email').text() : 'no-reply@demandware.net',
						subject: $('#merry-mail-email-subject').text().replace('[sender_name]',$form.find('input[name="senderName"]').val()),
						baseUrl: $('#merry-mail-base-url').text(),
						unsubscribeUrl: $('#merry-mail-unsubscribe-url').text(),
						message: $form.find('input[name="message"]:checked').val() === 'custom' ? $form.find('input[name="customMessage"]').val() : $form.find('input[name="message"]:checked').val(),
						productId: productInfo.id,
						productImage: window.location.hostname + productInfo.imageUrl,
						productUrl: createProductUrl(productInfo),
						productName: productInfo.name.replace(/\\(.)/mg, "$1"), // handle backslash escaping
						size: productInfo.size,
						color: productInfo.color
					}
					
					$.ajax({
						type: 'POST',
						url: Urls.sendGiftRequestEmail,
						data: formData,
						success: function() {
							var confirmMessage = $('#merry-mail-success-message').html().replace('[recipient_name]',formData.recipientName);
							
							dialog.open({
								html: confirmationTemplate({confirmMessage: confirmMessage}),
								options: {
									title: 'Success!',
									width: dialogWidth,
									dialogClass: dialogClass,
									buttons: [{
										text: 'OK',
										click: function() {
											dialog.close();
										}
									}]
								}
							});
							
							if (SitePreferences.GTM_ENABLED) {
								dataLayer.push({
									'event': SitePreferences.MERRYMAIL_GTM_EVENT_NAME,
									'merryMailProduct': {
										'name' : formData.productName,
										'url' : formData.productUrl
									}
								});
							}
						}
					});
			    });
				
				// handle click events on submit button
				$form.find('input[type="submit"]').off('click').on('click', function(e) {
					e.preventDefault();
					
					if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForMerryMail || !options.Recaptcha.GoogleSiteKey) {
						$(document).trigger('MerryMail.Send');
					}
				});
			}
		}
	});
	
	populateModalProductContent(productInfo);
};

/**
 * @description Populates the modal with applicable variant/product data
 */
var populateModalProductContent = function(productInfo) {
	var productImageUrl = productInfo.imageUrl;
	var currentProductUrl = createProductUrl(productInfo);
	
	$('.gift-dialog__sample-message .product-image').replaceWith('<img class="product-image" src="' + productImageUrl + '?sw=500" alt="" />');
	
	var hintText = $('strong.hint-item').html();
	$('strong.hint-item').replaceWith('<strong class="hint-item"><a href="' + currentProductUrl + '">' + hintText + '</a></strong>');
};

/**
 * @description updates the sender and recipient names in the sample message as the user enters that information into the form
 */
var updateSenderName = function() {
	var currentSenderName = $('.gift-form__input[name="senderName"]').val();
	
	$('.merry-mail-message-from').replaceWith('<span class="merry-mail-message-from replaced">' + currentSenderName + '</span>');
};

var updateRecipientName = function() {
	var currentRecipientName = $('.gift-form__input[name=recipientName]').val();
	
	$('.merry-mail-message-to').replaceWith('<span class="merry-mail-message-to replaced">' + currentRecipientName + '</span>');
};


/**
 * @function
 * @description Binds the click event to the button for the request gift handling
 */
module.exports = function (optionsParameter) {
	options = optionsParameter;
	
	$('.product-detail').on('click', '[data-action="requestGift"]', requestGift);
	$(document).on('change', 'input.sender-name', updateSenderName);
	$(document).on('change', 'input.recipient-name', updateRecipientName);
};
